import React from 'react';

const DarkModeDarkIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.78687 13.3818C5.08334 13.3818 3.44291 12.7509 2.18104 11.489C-0.3427 8.96526 -0.3427 4.99037 2.11795 2.52972C3.00126 1.58332 4.20003 0.952383 5.525 0.763103C5.77737 0.700009 6.02974 0.826196 6.15593 1.07857C6.28212 1.33094 6.28212 1.58332 6.09284 1.7726C4.70478 3.34994 4.83097 5.81058 6.34521 7.32483C7.85946 8.83907 10.257 8.96526 11.8343 7.5772C12.0236 7.38792 12.3391 7.38792 12.5284 7.51411C12.7807 7.64029 12.9069 7.89267 12.8438 8.14504C12.6546 9.47001 12.0236 10.6057 11.1403 11.5521C9.94154 12.7509 8.3642 13.3818 6.78687 13.3818Z"
        fill="#252733"
      />
    </svg>
  );
};

export default DarkModeDarkIcon;
