import React from 'react';

const LightModeDarkIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_45_15914)">
        <rect width="15.1424" height="15.1424" transform="translate(0.329102 0.232712)" fill="white" />
        <path
          d="M7.90186 3.51358C5.63049 3.51358 3.80078 5.34329 3.80078 7.61466C3.80078 9.88602 5.63049 11.7157 7.90186 11.7157C10.1732 11.7157 12.0029 9.88602 12.0029 7.61466C12.0029 5.34329 10.1732 3.51358 7.90186 3.51358Z"
          fill="#252733"
        />
        <path
          d="M7.90144 3.13503C8.28 3.13503 8.53238 2.88265 8.53238 2.50409V1.4946C8.53238 1.11604 8.28 0.863663 7.90144 0.863663C7.52288 0.863663 7.27051 1.11604 7.27051 1.4946V2.50409C7.27051 2.81956 7.52288 3.13503 7.90144 3.13503Z"
          fill="#252733"
        />
        <path
          d="M3.67432 4.46008C3.80051 4.58627 3.98979 4.64936 4.11598 4.64936C4.24217 4.64936 4.43145 4.58627 4.55763 4.46008C4.81001 4.20771 4.81001 3.82915 4.55763 3.57678L3.86361 2.88275C3.61123 2.63037 3.23267 2.63037 2.9803 2.88275C2.72792 3.13512 2.72792 3.51368 2.9803 3.76606L3.67432 4.46008Z"
          fill="#252733"
        />
        <path
          d="M3.2323 7.80388C3.2323 7.42532 2.97993 7.17294 2.60137 7.17294H1.59187C1.21331 7.17294 0.960938 7.42532 0.960938 7.80388C0.960938 8.18244 1.21331 8.43481 1.59187 8.43481H2.60137C2.91684 8.43481 3.2323 8.18244 3.2323 7.80388Z"
          fill="#252733"
        />
        <path
          d="M3.67432 11.1478L2.9803 11.8418C2.72792 12.0942 2.72792 12.4728 2.9803 12.7251C3.10648 12.8513 3.29576 12.9144 3.42195 12.9144C3.54814 12.9144 3.73742 12.8513 3.86361 12.7251L4.55763 12.0311C4.81001 11.7787 4.81001 11.4002 4.55763 11.1478C4.30526 10.8954 3.9267 10.8954 3.67432 11.1478Z"
          fill="#252733"
        />
        <path
          d="M7.90144 12.4728C7.52288 12.4728 7.27051 12.7252 7.27051 13.1037V14.1132C7.27051 14.4918 7.52288 14.7441 7.90144 14.7441C8.28 14.7441 8.53238 14.4918 8.53238 14.1132V13.1037C8.53238 12.7882 8.28 12.4728 7.90144 12.4728Z"
          fill="#252733"
        />
        <path
          d="M12.1292 11.1478C11.8769 10.8954 11.4983 10.8954 11.2459 11.1478C10.9935 11.4002 10.9935 11.7787 11.2459 12.0311L11.9399 12.7251C12.0661 12.8513 12.2554 12.9144 12.3816 12.9144C12.5078 12.9144 12.6971 12.8513 12.8233 12.7251C13.0756 12.4728 13.0756 12.0942 12.8233 11.8418L12.1292 11.1478Z"
          fill="#252733"
        />
        <path
          d="M14.2117 7.17294H13.2022C12.8237 7.17294 12.5713 7.42532 12.5713 7.80388C12.5713 8.18244 12.8237 8.43481 13.2022 8.43481H14.2117C14.5903 8.43481 14.8427 8.18244 14.8427 7.80388C14.8427 7.42532 14.5903 7.17294 14.2117 7.17294Z"
          fill="#252733"
        />
        <path
          d="M11.6876 4.64936C11.8769 4.64936 12.003 4.58627 12.1292 4.46008L12.8233 3.76606C13.0756 3.51368 13.0756 3.13512 12.8233 2.88275C12.5709 2.63037 12.1923 2.63037 11.9399 2.88275L11.2459 3.57678C10.9935 3.82915 10.9935 4.20771 11.2459 4.46008C11.3721 4.58627 11.4983 4.64936 11.6876 4.64936Z"
          fill="#252733"
        />
      </g>
      <defs>
        <clipPath id="clip0_45_15914">
          <rect width="15.1424" height="15.1424" fill="white" transform="translate(0.329102 0.232712)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightModeDarkIcon;
