import React from 'react';

const ArrowTop = ({ modeSwitcher }: { modeSwitcher: boolean }) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 -0.00130268L10 4.9987L9.10417 5.89453L5 1.79036L0.895834 5.89453L7.83162e-08 4.9987L5 -0.00130268Z"
        fill={modeSwitcher ? '#252733' : '#FFFFFF'}
      />
    </svg>
  );
};

export default ArrowTop;
