import React from 'react';

const NotFoundIcon = () => {
  return (
    <svg width="191" height="90" viewBox="0 0 191 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62.5144 57.7912V72.6312H53.6944V89.2912H34.7944V72.6312H0.494385V55.2712L30.8744 0.251221H51.0344L18.4144 57.7912H34.7944V40.2912H53.6944V57.7912H62.5144Z"
        fill="var(--black)"
      />
      <path
        d="M101.035 71.0912V18.4512C101.035 16.1179 99.7752 14.9512 97.2552 14.9512H92.0752C89.5552 14.9512 88.2952 16.1179 88.2952 18.4512V71.0912C88.2952 73.4246 89.5552 74.5912 92.0752 74.5912H97.2552C99.7752 74.5912 101.035 73.4246 101.035 71.0912ZM121.055 15.6512V73.7512C121.055 84.1112 115.175 89.2912 103.415 89.2912H86.0552C74.2018 89.2912 68.2752 84.1112 68.2752 73.7512V15.6512C68.2752 5.38455 74.2018 0.251221 86.0552 0.251221H103.415C115.175 0.251221 121.055 5.38455 121.055 15.6512Z"
        fill="var(--black)"
      />
      <path
        d="M190.073 57.7912V72.6312H181.253V89.2912H162.353V72.6312H128.053V55.2712L158.433 0.251221H178.593L145.973 57.7912H162.353V40.2912H181.253V57.7912H190.073Z"
        fill="var(--black)"
      />
    </svg>
  );
};

export default NotFoundIcon;
