import { useState } from 'react';
import { Controller, PathValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ClientRequestTypes, findTitleById, formatPhone, onlyNumbersFormatter, TruckTypeClient } from 'helpers';
import Checkbox from 'ui/checkbox/Checkbox';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import CustomInput from 'ui/input/Input';
import SimpleFlatSelect from 'ui/simpleFlatSelect/simpleFlatSelect';
import StateSelect from 'ui/state-select/StateSelect';
import CustomTextArea from 'ui/textarea/Textarea';
import Typography from 'ui/typography/Typography';

import { schema } from './validationSchema';

import styles from './styles.module.scss';

const Shipper = ({ onClose, statesData }) => {
  const { api }: any = window.global;
  const [isQuoteOpened, setIsQuoteOpened] = useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    resetField,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      truckType: [],
    },
    mode: 'onBlur',
  });

  const onSubmit = data => {
    axios
      .post(`${api}/auth/client/request`, {
        type: ClientRequestTypes.CLIENT_REQUEST_TYPE_SHIPPER,
        companyName: data.companyName,
        contactName: data.contactName,
        phone: data.phoneNumber,
        email: data.email,
        ...(data.address ? { address: data.address } : {}),
        ...(data.zipCode ? { zip: data.zipCode } : {}),
        ...(data.city ? { city: data.city } : {}),
        ...(data.state ? { state: findTitleById(statesData, data?.state) } : {}),
        ...(data.comments ? { tellUsMore: data.comments } : {}),

        ...(data.pickUpCity ? { pickUpCity: data.pickUpCity } : {}),
        ...(data.pickUpState ? { pickUpState: findTitleById(statesData, data?.pickUpState) } : {}),
        ...(data.deliveryCity ? { deliveryCity: data.deliveryCity } : {}),
        ...(data.deliveryState ? { deliveryState: findTitleById(statesData, data?.deliveryState) } : {}),
        ...(data.commodity ? { commodity: data.commodity } : {}),
        ...(data.loadType ? { loadType: data.loadType === 'FTL' ? 1 : 2 } : {}),
        ...(data.weight ? { weight: data.weight } : {}),
        ...(data.temperature ? { temperature: data.temperature } : {}),
        ...(data.palletCount ? { palletCount: data.palletCount } : {}),
        ...(data.truckType.length ? { truckType: data.truckType } : {}),
      })
      .then(() => {
        reset();
        onClose();
        window.location.href = 'https://app.freightmax.com/shipper/thank-you/';
      })
      .catch(() => {
        setError('email', { type: 'validate', message: 'Invalid email format' });
      });
  };

  const onCheckBoxCheck = (ctx: number) => () => {
    const newValue = getValues('truckType') || [];
    if (newValue.includes(ctx)) {
      setValue(
        'truckType',
        newValue.filter((id: number) => id !== ctx)
      );
    } else {
      setValue('truckType', [...newValue, ctx]);
    }
  };

  const scrollByPixels = (id: string) => {
    const modal = document.getElementsByClassName('modal_largeContainer__8YoOC')[0];
    if (modal) {
      const element = modal.querySelector(id);
      if (element) {
        const { top } = element.getBoundingClientRect();
        modal.scrollTop = modal.scrollTop + top - 200;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      <div className={styles.row}>
        <CustomInput
          name="companyName"
          control={control}
          rules={{ required: true }}
          placeholder="Company name"
          getValues={getValues}
          errors={errors}
          required
        />
        <CustomInput
          name="contactName"
          control={control}
          rules={{ required: true }}
          placeholder="Contact name"
          getValues={getValues}
          errors={errors}
          required
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="phoneNumber"
          control={control}
          rules={{ required: true }}
          placeholder="Phone number"
          onChange={(e: any) =>
            setValue('phoneNumber', formatPhone(e.target.value), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
          getValues={getValues}
          errors={errors}
          required
        />
        <CustomInput
          name="email"
          control={control}
          rules={{ required: true }}
          placeholder="Email"
          getValues={getValues}
          errors={errors}
          required
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="address"
          control={control}
          rules={{ required: false }}
          placeholder="Address"
          getValues={getValues}
          errors={errors}
        />
        <CustomInput
          name="zipCode"
          control={control}
          rules={{ required: false }}
          placeholder="Zip Code"
          getValues={getValues}
          onChange={(e: any) =>
            setValue('zipCode', onlyNumbersFormatter(e.target.value), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
          errors={errors}
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="city"
          control={control}
          rules={{ required: false }}
          placeholder="City"
          getValues={getValues}
          errors={errors}
        />
        <Controller
          name="state"
          control={control}
          rules={{ required: false }}
          render={({ field }) => {
            return (
              <StateSelect
                allowClear
                options={statesData}
                placeholder="State"
                name={field.name}
                errors={errors}
                onBlur={() => trigger('state')}
                onChange={(value: string) =>
                  setValue('state', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={field.value}
              />
            );
          }}
        />
        <ErrorMessage fieldError={errors?.state} />
      </div>
      <div>
        <CustomTextArea
          autoSize
          label="Tell us more..."
          isFilled={!!watch('comments')}
          name="comments"
          control={control}
          rules={{ required: false }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue('comments', e.target.value as PathValue<string, string>, { shouldDirty: false })
          }
        />
      </div>
      {!!isQuoteOpened && (
        <>
          <div className={styles.row}>
            <CustomInput
              name="pickUpCity"
              control={control}
              rules={{ required: false }}
              placeholder="Pick up City"
              getValues={getValues}
              errors={errors}
            />
            <Controller
              name="pickUpState"
              control={control}
              rules={{ required: false }}
              render={({ field }) => {
                return (
                  <StateSelect
                    allowClear
                    onClick={() => scrollByPixels('#select_pickUpState')}
                    options={statesData}
                    placeholder="Pick up State"
                    name={field.name}
                    errors={errors}
                    onBlur={() => trigger('pickUpState')}
                    onChange={(value: string) =>
                      setValue('pickUpState', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={field.value}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.pickUpState} />
          </div>
          <div className={styles.row}>
            <CustomInput
              name="deliveryCity"
              control={control}
              rules={{ required: false }}
              placeholder="Delivery City"
              getValues={getValues}
              errors={errors}
            />
            <Controller
              name="deliveryState"
              control={control}
              rules={{ required: false }}
              render={({ field }) => {
                return (
                  <StateSelect
                    allowClear
                    options={statesData}
                    placeholder="Delivery State"
                    name={field.name}
                    errors={errors}
                    onBlur={() => trigger('deliveryState')}
                    onClick={() => scrollByPixels('#select_deliveryState')}
                    onChange={(value: string) =>
                      setValue('deliveryState', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={field.value}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.deliveryState} />
          </div>
          <div className={styles.row}>
            <CustomInput
              name="commodity"
              control={control}
              rules={{ required: false }}
              placeholder="Commodity"
              getValues={getValues}
              errors={errors}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="loadType"
              control={control}
              rules={{ required: false }}
              render={({ field }) => {
                return (
                  <SimpleFlatSelect
                    options={[
                      { value: 'FTL', title: 'FTL' },
                      { value: 'LTL', title: 'LTL' },
                    ]}
                    placeholder="Select Load Type"
                    name={field.name}
                    onClick={() => scrollByPixels('#simpleFlatSelect_loadType')}
                    onBlur={() => trigger('loadType')}
                    onChange={(value: string | string[]) => {
                      field.onChange(value);
                    }}
                    errors={errors}
                    value={field.value}
                  />
                );
              }}
            />
            <CustomInput
              name="weight"
              control={control}
              rules={{ required: false }}
              placeholder="Weight"
              getValues={getValues}
              errors={errors}
            />
          </div>
          <div className={styles.row}>
            <CustomInput
              name="temperature"
              control={control}
              rules={{ required: false }}
              placeholder="Temperature"
              getValues={getValues}
              errors={errors}
            />
            <CustomInput
              name="palletCount"
              control={control}
              rules={{ required: false }}
              placeholder="Pallet count"
              getValues={getValues}
              errors={errors}
            />
          </div>
          <div className={styles.truckTypes}>
            <div>
              <label className={styles.truckType}>
                Dry Van
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_DRY_VAN)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_DRY_VAN)}
                />
              </label>
              <label className={styles.truckType}>
                Reefer
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_REEFER)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_REEFER)}
                />
              </label>
              <label className={styles.truckType}>
                Flat bed
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_FLAT_BED)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_FLAT_BED)}
                />
              </label>
              <label className={styles.truckType}>
                Box truck
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_BOX_TRUCK)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_BOX_TRUCK)}
                />
              </label>
            </div>
            <div>
              <label className={styles.truckType}>
                Conestoga
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_CONESTOGA)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_CONESTOGA)}
                />
              </label>
              <label className={styles.truckType}>
                Step deck
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_STEP_DECK)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_STEP_DECK)}
                />
              </label>
              <label className={styles.truckType}>
                Sprinter van
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_SPRINTER_VAN)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_SPRINTER_VAN)}
                />
              </label>
            </div>
            <div>
              <label className={styles.truckType}>
                Curtain Van
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_CURTAIN_VAN)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_CURTAIN_VAN)}
                />
              </label>
              <label className={styles.truckType}>
                Low boy
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_LOW_BOY)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_LOW_BOY)}
                />
              </label>
              <label className={styles.truckType}>
                Hot shot
                <Checkbox
                  size="default"
                  checked={getValues('truckType')?.includes(TruckTypeClient.TRUCK_TYPE_CLIENT_HOT_SHOT)}
                  onChange={onCheckBoxCheck(TruckTypeClient.TRUCK_TYPE_CLIENT_HOT_SHOT)}
                />
              </label>
            </div>
          </div>
        </>
      )}
      <div className={styles.buttonBlock}>
        <div className={styles.quoteText}>
          {isQuoteOpened ? (
            <Typography
              children="Close Quote"
              variant="paragraph"
              onClick={() => {
                resetField('pickUpCity');
                resetField('pickUpState');
                resetField('deliveryCity');
                resetField('deliveryState');
                resetField('commodity');
                resetField('loadType');
                resetField('weight');
                resetField('temperature');
                resetField('palletCount');
                setValue('truckType', []);
                setIsQuoteOpened(false);
              }}
            />
          ) : (
            <Typography
              children="Request a Free Shipment Quote"
              variant="paragraph"
              onClick={() => setIsQuoteOpened(true)}
            />
          )}
        </div>

        <button onClick={onClose} className={styles.cancel}>
          Cancel
        </button>
        <button onClick={handleSubmit(onSubmit)} className={styles.send}>
          Send
        </button>
      </div>
    </form>
  );
};

export default Shipper;
