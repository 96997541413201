import React from 'react';

const DarkModeIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.78687 13.3818C5.08334 13.3818 3.44291 12.7509 2.18104 11.489C-0.3427 8.9653 -0.3427 4.99041 2.11795 2.52977C3.00126 1.58336 4.20003 0.952429 5.525 0.763149C5.77737 0.700055 6.02974 0.826242 6.15593 1.07862C6.28212 1.33099 6.28212 1.58336 6.09284 1.77264C4.70478 3.34998 4.83097 5.81063 6.34521 7.32487C7.85946 8.83912 10.257 8.9653 11.8343 7.57725C12.0236 7.38797 12.3391 7.38797 12.5284 7.51415C12.7807 7.64034 12.9069 7.89271 12.8438 8.14509C12.6546 9.47005 12.0236 10.6057 11.1403 11.5521C9.94154 12.7509 8.3642 13.3818 6.78687 13.3818Z"
        fill="#F0F2F5"
      />
    </svg>
  );
};

export default DarkModeIcon;
