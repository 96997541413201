import Typography from 'ui/typography/Typography';

import { FieldError } from './constants/types';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ fieldError }: Partial<FieldError | any>) =>
  fieldError && (
    <Typography variant="paragraph" className={styles.error}>
      {fieldError['message']}
    </Typography>
  );

export default ErrorMessage;
