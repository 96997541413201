import React, { useEffect, useMemo, useState } from 'react';
import useMobile from 'hooks/useMobile';

import Car from './components/carAnimation/CarAnimation';
import Header from './components/header/Header';
import Map from './components/map/Map';
import MobileVersion from './components/MobileVersion/MobileVersion';
import NotFound from './components/not-found/NotFound';

function App() {
  const [modeSwitcher, setModeSwitcher] = useState<boolean>(false);
  const [switchToMap, setSwitchToMap] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const currentURL = window.location.href;
  const pathName = new URL(currentURL).pathname;

  useEffect(() => {
    if (pathName)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
  }, []);

  const { isMobile } = useMobile();

  const RenderApp = useMemo(() => {
    return isMobile ? (
      <div>
        <Header
          switchToMap={!openMap}
          modeSwitcher={modeSwitcher}
          setModeSwitcher={setModeSwitcher}
          isMobile={isMobile}
        />
        <MobileVersion modeSwitcher={modeSwitcher} />
      </div>
    ) : (
      <div className={`parent map-mode`} id="wrapper">
        {pathName === '/' ? (
          <div className={'animations-overlay'}>
            {!openMap ? (
              <div>
                <Header switchToMap={!openMap} modeSwitcher={modeSwitcher} setModeSwitcher={setModeSwitcher} />
                <Car switchToMap={switchToMap} modeSwitcher={modeSwitcher} setOpenMap={setOpenMap} />
              </div>
            ) : (
              <Map switchToMap={switchToMap} setSwitchToMap={setSwitchToMap} />
            )}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    );
  }, [isMobile, pathName, openMap, switchToMap, modeSwitcher]);

  return RenderApp;
}

export default App;
