import React from 'react';

const LightModeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_38_92)">
        <path
          d="M8.27002 3.51367C5.99866 3.51367 4.16895 5.34338 4.16895 7.61475C4.16895 9.88612 5.99866 11.7158 8.27002 11.7158C10.5414 11.7158 12.3711 9.88612 12.3711 7.61475C12.3711 5.34338 10.5414 3.51367 8.27002 3.51367Z"
          fill="#F0F2F5"
        />
        <path
          d="M8.26961 3.13514C8.64817 3.13514 8.90054 2.88276 8.90054 2.5042V1.4947C8.90054 1.11614 8.64817 0.86377 8.26961 0.86377C7.89105 0.86377 7.63867 1.11614 7.63867 1.4947V2.5042C7.63867 2.81967 7.89105 3.13514 8.26961 3.13514Z"
          fill="#F0F2F5"
        />
        <path
          d="M4.04249 4.46022C4.16868 4.58641 4.35796 4.6495 4.48414 4.6495C4.61033 4.6495 4.79961 4.58641 4.9258 4.46022C5.17817 4.20785 5.17817 3.82929 4.9258 3.57691L4.23177 2.88288C3.9794 2.63051 3.60083 2.63051 3.34846 2.88288C3.09609 3.13526 3.09609 3.51382 3.34846 3.76619L4.04249 4.46022Z"
          fill="#F0F2F5"
        />
        <path
          d="M3.60047 7.80391C3.60047 7.42535 3.34809 7.17297 2.96953 7.17297H1.96004C1.58148 7.17297 1.3291 7.42535 1.3291 7.80391C1.3291 8.18247 1.58148 8.43484 1.96004 8.43484H2.96953C3.285 8.43484 3.60047 8.18247 3.60047 7.80391Z"
          fill="#F0F2F5"
        />
        <path
          d="M4.04249 11.1479L3.34846 11.8419C3.09609 12.0943 3.09609 12.4729 3.34846 12.7252C3.47465 12.8514 3.66393 12.9145 3.79011 12.9145C3.9163 12.9145 4.10558 12.8514 4.23177 12.7252L4.9258 12.0312C5.17817 11.7788 5.17817 11.4003 4.9258 11.1479C4.67342 10.8955 4.29486 10.8955 4.04249 11.1479Z"
          fill="#F0F2F5"
        />
        <path
          d="M8.26961 12.4729C7.89105 12.4729 7.63867 12.7253 7.63867 13.1038V14.1133C7.63867 14.4919 7.89105 14.7443 8.26961 14.7443C8.64817 14.7443 8.90054 14.4919 8.90054 14.1133V13.1038C8.90054 12.7884 8.64817 12.4729 8.26961 12.4729Z"
          fill="#F0F2F5"
        />
        <path
          d="M12.4974 11.1479C12.245 10.8955 11.8665 10.8955 11.6141 11.1479C11.3617 11.4003 11.3617 11.7788 11.6141 12.0312L12.3081 12.7252C12.4343 12.8514 12.6236 12.9145 12.7498 12.9145C12.876 12.9145 13.0652 12.8514 13.1914 12.7252C13.4438 12.4729 13.4438 12.0943 13.1914 11.8419L12.4974 11.1479Z"
          fill="#F0F2F5"
        />
        <path
          d="M14.5799 7.17297H13.5704C13.1918 7.17297 12.9395 7.42535 12.9395 7.80391C12.9395 8.18247 13.1918 8.43484 13.5704 8.43484H14.5799C14.9584 8.43484 15.2108 8.18247 15.2108 7.80391C15.2108 7.42535 14.9584 7.17297 14.5799 7.17297Z"
          fill="#F0F2F5"
        />
        <path
          d="M12.0557 4.6495C12.245 4.6495 12.3712 4.58641 12.4974 4.46022L13.1914 3.76619C13.4438 3.51382 13.4438 3.13526 13.1914 2.88288C12.939 2.63051 12.5605 2.63051 12.3081 2.88288L11.6141 3.57691C11.3617 3.82929 11.3617 4.20785 11.6141 4.46022C11.7403 4.58641 11.8665 4.6495 12.0557 4.6495Z"
          fill="#F0F2F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_38_92">
          <rect width="15.1424" height="15.1424" fill="white" transform="translate(0.697266 0.232788)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightModeIcon;
