import React from 'react';

const ArrowBottom = ({ modeSwitcher }: { modeSwitcher?: boolean }) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 5.89583L0 0.895833L0.895833 0L5 4.10417L9.10417 0L10 0.895833L5 5.89583Z"
        fill={modeSwitcher ? '#252733' : '#FFFFFF'}
      />
    </svg>
  );
};

export default ArrowBottom;
