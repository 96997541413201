import React, { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react';
import { RefSelectProps, TreeSelect } from 'antd';
import clsx from 'clsx';
import { getHighlightedOptions } from 'helpers';
import CloseIconForSelect from 'svgs/CloseIconForSelect';
import SelectArrowIcon from 'svgs/SelectArrowIcon';
import Typography from 'ui/typography/Typography';

import { IRegionOptions, IState } from './constants/types';

import styles from './StateSelect.module.scss';

const StateSelect = forwardRef(
  (
    {
      options,
      placeholder,
      label,
      allowClear,
      name,
      isDisabled,
      onChange,
      value,
      required,
      onBlur,
      errors,
      ...props
    }: any,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const selectRef = useRef<RefSelectProps>(null);

    const currentElement = document.getElementById(`current_state_searchable${label}${placeholder}`);

    const handleSelect = () => {
      currentElement?.blur();
      setIsSearching(false);
      setSearchValue('');
    };

    const expandedKeys = useMemo(() => {
      return options?.map((el: any) => el?.children && el.key);
    }, [options]);

    const { TreeNode } = TreeSelect;

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.selectMainWrapper}>
        {!!label && <label>{label}</label>}
        <div
          id={`select_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value,
            [styles.required]: required,
            [styles.error]: errors?.[name],
            [styles.withSubChild]: true,
          })}
          ref={dropdownRef}
        >
          <TreeSelect
            id={`current_state_searchable${label}${placeholder}`}
            showSearch
            onDropdownVisibleChange={(e: any) => {
              if (!e && dropdownRef) {
                dropdownRef?.current?.scrollTo(0, 0);
              }
            }}
            ref={selectRef || ref}
            style={{ width: '100%' }}
            getPopupContainer={() => document.getElementById(`select_${name}`)!}
            placeholder={label ? placeholder : null}
            allowClear={value || searchValue}
            value={value}
            suffixIcon={(allowClear && value) || searchValue ? <CloseIconForSelect /> : <SelectArrowIcon />}
            disabled={isDisabled || false}
            className={clsx(styles.select)}
            treeDefaultExpandAll
            treeExpandedKeys={expandedKeys}
            onChange={onChange}
            treeNodeFilterProp="title"
            onSelect={handleSelect}
            onBlur={onBlur}
            onSearch={value => {
              setSearchValue(value);
              setIsSearching(true);
            }}
            filterTreeNode={(search, item: any) => {
              return item.innerValue?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            }}
            {...props}
          >
            {options?.map((region: IRegionOptions) => {
              return (
                <TreeNode value={region?.value} title={region?.title} key={region?.key} selectable={false}>
                  {region.children?.map((state: IState) => {
                    const highlightedValues = getHighlightedOptions(String(searchValue || ''), String(state.title));
                    return (
                      <TreeNode
                        value={state?.value}
                        title={
                          <div className={styles.optionsContainer}>
                            {highlightedValues?.length && isSearching ? (
                              <>
                                {highlightedValues[0]}
                                <span className={styles.searchResultKeyword}>{highlightedValues[1]}</span>
                                {highlightedValues[2]}
                              </>
                            ) : (
                              <span>{state?.title}</span>
                            )}
                          </div>
                        }
                        key={state?.key}
                        innerValue={state?.title}
                        selectable={true}
                      />
                    );
                  })}
                </TreeNode>
              );
            })}
          </TreeSelect>
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default StateSelect;
