import React, { forwardRef } from 'react';

type PropsType = {
  carVersion: number;
};
const CarVersionLightMode = forwardRef<SVGSVGElement, PropsType>(({ carVersion }, ref) => {
  return (
    <svg
      id="carVersion"
      className="car"
      ref={ref}
      width="906"
      height="254"
      viewBox="0 0 906 254"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {carVersion === 0 ? (
        <>
          <path
            d="M796.1 217.754C796.1 224.56 798.13 231.213 801.933 236.87C805.735 242.528 811.14 246.936 817.462 249.537C823.785 252.137 830.741 252.814 837.45 251.48C844.159 250.146 850.319 246.863 855.151 242.045C859.983 237.227 863.27 231.091 864.595 224.414C865.919 217.737 865.223 210.82 862.594 204.537C859.964 198.254 855.52 192.888 849.824 189.118C844.127 185.349 837.435 183.346 830.593 183.362C821.438 183.383 812.664 187.016 806.198 193.464C799.732 199.911 796.1 208.647 796.1 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M806.893 217.754C806.893 222.37 808.27 226.882 810.849 230.718C813.429 234.555 817.095 237.544 821.383 239.306C825.672 241.069 830.389 241.526 834.939 240.62C839.489 239.713 843.666 237.484 846.941 234.215C850.216 230.945 852.442 226.783 853.338 222.254C854.233 217.725 853.757 213.034 851.97 208.774C850.184 204.514 847.166 200.878 843.3 198.325C839.435 195.773 834.894 194.419 830.254 194.435C824.05 194.453 818.106 196.918 813.726 201.289C809.347 205.661 806.889 211.582 806.893 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M811.592 217.754C811.592 221.474 812.702 225.109 814.781 228.201C816.86 231.292 819.814 233.7 823.27 235.12C826.726 236.539 830.528 236.906 834.194 236.175C837.86 235.443 841.225 233.646 843.863 231.01C846.501 228.375 848.294 225.02 849.013 221.37C849.733 217.72 849.347 213.94 847.905 210.508C846.463 207.077 844.03 204.148 840.913 202.093C837.796 200.039 834.137 198.95 830.398 198.966C825.403 198.988 820.62 200.977 817.095 204.498C813.571 208.019 811.592 212.785 811.592 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M819.73 217.754C819.744 219.885 820.391 221.965 821.591 223.73C822.791 225.496 824.49 226.868 826.473 227.675C828.456 228.481 830.635 228.685 832.735 228.261C834.834 227.837 836.761 226.804 838.271 225.293C839.781 223.781 840.807 221.858 841.22 219.767C841.633 217.676 841.415 215.51 840.592 213.542C839.769 211.575 838.379 209.893 836.596 208.711C834.814 207.528 832.72 206.897 830.577 206.897C827.695 206.918 824.939 208.071 822.907 210.105C820.876 212.138 819.734 214.887 819.73 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M901.429 187.974C901.425 189.375 900.864 190.718 899.868 191.709C898.872 192.7 897.522 193.258 896.114 193.261H893.877V175.593H896.114C896.811 175.591 897.502 175.726 898.147 175.991C898.791 176.255 899.377 176.643 899.871 177.133C900.365 177.623 900.757 178.205 901.024 178.845C901.291 179.486 901.429 180.173 901.429 180.867V187.974Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M889.76 217.013C889.76 217.728 890.045 218.414 890.554 218.92C891.062 219.426 891.752 219.71 892.471 219.71H902.057C902.441 219.71 902.82 219.634 903.174 219.488C903.528 219.341 903.849 219.126 904.119 218.855C904.388 218.584 904.602 218.263 904.746 217.91C904.89 217.556 904.962 217.178 904.959 216.797V198.211C904.959 197.446 904.653 196.712 904.109 196.17C903.565 195.629 902.827 195.325 902.057 195.325H892.417C891.698 195.325 891.008 195.609 890.5 196.115C889.991 196.621 889.705 197.307 889.705 198.022L889.76 217.013Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M486.877 164.83H669.349V175.93H486.877V164.83Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M66.4445 164.115H334.023V175.93H66.4445V164.115Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M455.799 192.627H447.501C446.091 192.627 444.739 193.184 443.743 194.176C442.746 195.167 442.186 196.512 442.186 197.914V198.993C442.186 200.396 442.746 201.74 443.743 202.732C444.739 203.723 446.091 204.28 447.501 204.28H455.799V192.627Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M453.995 174.986H741.709V218.307H646.582V218.145C646.585 212.87 645.544 207.645 643.517 202.77C641.491 197.894 638.518 193.464 634.77 189.732C631.022 185.999 626.571 183.038 621.672 181.017C616.773 178.996 611.521 177.955 606.218 177.953C585.636 177.953 566.532 193.518 565.84 218.307H552.797C552.797 218.24 552.797 218.186 552.797 218.118C552.768 207.475 548.505 197.275 540.939 189.749C533.373 182.223 523.119 177.982 512.419 177.953C491.878 177.953 472.747 193.45 472.042 218.415H453.968C453.269 218.415 452.577 218.278 451.931 218.011C451.286 217.745 450.699 217.354 450.205 216.862C449.712 216.369 449.32 215.785 449.054 215.142C448.788 214.499 448.652 213.81 448.653 213.115V180.273C448.653 179.576 448.792 178.887 449.061 178.244C449.329 177.6 449.723 177.016 450.22 176.525C450.716 176.034 451.305 175.645 451.953 175.381C452.601 175.117 453.295 174.982 453.995 174.986Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M822.892 75.1801H748.807V45.0495C748.807 44.3752 748.947 43.7081 749.219 43.0904C749.491 42.4726 749.888 41.9175 750.386 41.4601C750.884 41.0026 751.472 40.6528 752.113 40.4326C752.753 40.2124 753.433 40.1266 754.109 40.1806L783.938 44.0245C787.346 44.2901 790.528 45.8269 792.846 48.3269L822.892 75.1801Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M791.893 206.911H792.124C791.045 210.581 790.515 214.389 790.551 218.213H741.74V80.5751C741.744 79.174 742.305 77.8313 743.301 76.8405C744.297 75.8498 745.646 75.2916 747.055 75.2881H866.221C867.777 75.2888 869.294 75.769 870.564 76.6627C871.834 77.5564 872.794 78.8195 873.313 80.2784L890.302 128.077L894.369 138.152V219.265H872.038C872.017 214.87 871.562 210.489 870.682 206.182C866.696 190.321 849.26 177.859 830.942 177.859C822.185 177.852 813.663 180.682 806.667 185.922C799.671 191.161 794.58 198.524 792.164 206.897L791.893 206.911Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M798.49 97.2453C798.49 95.8431 799.05 94.4983 800.047 93.5068C801.043 92.5153 802.395 91.9583 803.805 91.9583H857.09C858.647 91.9565 860.168 92.432 861.444 93.32C862.72 94.2081 863.69 95.4656 864.222 96.9216L874.716 125.461C874.931 126.026 875.003 126.635 874.927 127.234C874.851 127.833 874.629 128.405 874.28 128.9C873.931 129.394 873.466 129.796 872.925 130.071C872.385 130.346 871.785 130.486 871.177 130.478H803.805C802.396 130.474 801.046 129.916 800.05 128.925C799.055 127.935 798.493 126.592 798.49 125.191V97.2453Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M798.316 139.676C798.316 140.832 798.778 141.94 799.599 142.757C800.42 143.574 801.534 144.033 802.696 144.033H811.455C812.616 144.033 813.73 143.574 814.551 142.757C815.373 141.94 815.834 140.832 815.834 139.676C815.834 138.521 815.373 137.413 814.551 136.596C813.73 135.779 812.616 135.32 811.455 135.32H802.696C801.534 135.32 800.42 135.779 799.599 136.596C798.778 137.413 798.316 138.521 798.316 139.676Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M878.646 200.882H880.178C880.999 200.882 881.812 200.721 882.57 200.408C883.329 200.096 884.018 199.638 884.598 199.061C885.178 198.483 885.639 197.798 885.953 197.043C886.267 196.289 886.429 195.481 886.429 194.664V136.372L870.701 91.5267C870.114 89.8988 869.035 88.4911 867.613 87.4964C866.191 86.5016 864.494 85.9685 862.755 85.97H796.996C795.337 85.9735 793.747 86.6316 792.576 87.7998C791.404 88.968 790.746 90.5508 790.746 92.2011V164.682C790.753 166.336 791.419 167.919 792.599 169.085C793.778 170.25 795.374 170.903 797.037 170.899H832.045C848.776 170.899 865.128 181.231 870.931 195.473C871.533 197.034 872.588 198.382 873.962 199.345C875.335 200.308 876.966 200.843 878.646 200.882Z"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M755.414 91.9583H776.986C779.921 91.9583 782.301 94.3253 782.301 97.2453V125.15C782.301 128.07 779.921 130.437 776.986 130.437H755.414C752.479 130.437 750.099 128.07 750.099 125.15V97.2453C750.099 94.3253 752.479 91.9583 755.414 91.9583Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M6.65393 1H723.701C726.823 1 729.354 3.51804 729.354 6.6242V159.934C729.354 163.04 726.823 165.558 723.701 165.558H6.65393C3.53131 165.558 1 163.04 1 159.934V6.6242C1 3.51804 3.53131 1 6.65393 1Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M14.4003 8.66077H717.17C720 8.66077 722.295 10.9433 722.295 13.759V152.799C722.295 155.615 720 157.898 717.17 157.898H14.4003C11.5698 157.898 9.27515 155.615 9.27515 152.799V13.759C9.27515 10.9433 11.5698 8.66077 14.4003 8.66077Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M684.242 135.091C684.311 136.455 684.904 137.742 685.9 138.683C686.896 139.625 688.217 140.15 689.591 140.15C690.964 140.15 692.286 139.625 693.281 138.683C694.277 137.742 694.87 136.455 694.939 135.091V30.5372C694.87 29.1724 694.277 27.8861 693.281 26.9445C692.286 26.0028 690.964 25.4777 689.591 25.4777C688.217 25.4777 686.896 26.0028 685.9 26.9445C684.904 27.8861 684.311 29.1724 684.242 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M100.953 135.091C101.022 136.455 101.615 137.742 102.611 138.683C103.606 139.625 104.928 140.15 106.302 140.15C107.675 140.15 108.997 139.625 109.992 138.683C110.988 137.742 111.581 136.455 111.65 135.091V30.5372C111.581 29.1724 110.988 27.8861 109.992 26.9445C108.997 26.0028 107.675 25.4777 106.302 25.4777C104.928 25.4777 103.606 26.0028 102.611 26.9445C101.615 27.8861 101.022 29.1724 100.953 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M42.7115 135.091C42.7806 136.455 43.3743 137.742 44.3698 138.683C45.3654 139.625 46.6865 140.15 48.0603 140.15C49.4341 140.15 50.7553 139.625 51.7508 138.683C52.7464 137.742 53.3401 136.455 53.4092 135.091V30.5372C53.3401 29.1724 52.7464 27.8861 51.7508 26.9445C50.7553 26.0028 49.4341 25.4777 48.0603 25.4777C46.6865 25.4777 45.3654 26.0028 44.3698 26.9445C43.3743 27.8861 42.7806 29.1724 42.7115 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M159.193 135.091C159.262 136.455 159.856 137.742 160.851 138.683C161.847 139.625 163.168 140.15 164.542 140.15C165.916 140.15 167.237 139.625 168.232 138.683C169.228 137.742 169.822 136.455 169.891 135.091V30.5372C169.822 29.1724 169.228 27.8861 168.232 26.9445C167.237 26.0028 165.916 25.4777 164.542 25.4777C163.168 25.4777 161.847 26.0028 160.851 26.9445C159.856 27.8861 159.262 29.1724 159.193 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M217.433 135.091C217.502 136.455 218.096 137.742 219.091 138.683C220.087 139.625 221.408 140.15 222.782 140.15C224.156 140.15 225.477 139.625 226.473 138.683C227.468 137.742 228.062 136.455 228.131 135.091V30.5372C228.062 29.1724 227.468 27.8861 226.473 26.9445C225.477 26.0028 224.156 25.4777 222.782 25.4777C221.408 25.4777 220.087 26.0028 219.091 26.9445C218.096 27.8861 217.502 29.1724 217.433 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M275.674 135.091C275.743 136.455 276.337 137.742 277.333 138.683C278.328 139.625 279.649 140.15 281.023 140.15C282.397 140.15 283.718 139.625 284.714 138.683C285.709 137.742 286.303 136.455 286.372 135.091V30.5372C286.303 29.1724 285.709 27.8861 284.714 26.9445C283.718 26.0028 282.397 25.4777 281.023 25.4777C279.649 25.4777 278.328 26.0028 277.333 26.9445C276.337 27.8861 275.743 29.1724 275.674 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M333.916 135.091C333.985 136.455 334.578 137.742 335.574 138.683C336.569 139.625 337.891 140.15 339.264 140.15C340.638 140.15 341.959 139.625 342.955 138.683C343.95 137.742 344.544 136.455 344.613 135.091V30.5372C344.544 29.1724 343.95 27.8861 342.955 26.9445C341.959 26.0028 340.638 25.4777 339.264 25.4777C337.891 25.4777 336.569 26.0028 335.574 26.9445C334.578 27.8861 333.985 29.1724 333.916 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M392.156 135.091C392.225 136.455 392.819 137.742 393.814 138.683C394.81 139.625 396.131 140.15 397.505 140.15C398.878 140.15 400.2 139.625 401.195 138.683C402.191 137.742 402.784 136.455 402.854 135.091V30.5372C402.784 29.1724 402.191 27.8861 401.195 26.9445C400.2 26.0028 398.878 25.4777 397.505 25.4777C396.131 25.4777 394.81 26.0028 393.814 26.9445C392.819 27.8861 392.225 29.1724 392.156 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M450.382 135.091C450.586 136.358 451.237 137.512 452.218 138.345C453.2 139.178 454.448 139.635 455.738 139.635C457.028 139.635 458.276 139.178 459.258 138.345C460.239 137.512 460.89 136.358 461.094 135.091V30.5371C460.89 29.2697 460.239 28.116 459.258 27.283C458.276 26.4501 457.028 25.9926 455.738 25.9926C454.448 25.9926 453.2 26.4501 452.218 27.283C451.237 28.116 450.586 29.2697 450.382 30.5371V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M509.52 135.091C509.589 136.455 510.183 137.742 511.178 138.683C512.174 139.625 513.495 140.15 514.869 140.15C516.243 140.15 517.564 139.625 518.559 138.683C519.555 137.742 520.149 136.455 520.218 135.091V30.5372C520.149 29.1724 519.555 27.8861 518.559 26.9445C517.564 26.0028 516.243 25.4777 514.869 25.4777C513.495 25.4777 512.174 26.0028 511.178 26.9445C510.183 27.8861 509.589 29.1724 509.52 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M567.748 135.091C567.951 136.358 568.602 137.512 569.584 138.345C570.565 139.178 571.813 139.635 573.103 139.635C574.394 139.635 575.642 139.178 576.623 138.345C577.605 137.512 578.256 136.358 578.459 135.091V30.5371C578.256 29.2697 577.605 28.116 576.623 27.283C575.642 26.4501 574.394 25.9926 573.103 25.9926C571.813 25.9926 570.565 26.4501 569.584 27.283C568.602 28.116 567.951 29.2697 567.748 30.5371V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M626.001 135.091C626.071 136.455 626.664 137.742 627.66 138.683C628.655 139.625 629.977 140.15 631.35 140.15C632.724 140.15 634.045 139.625 635.041 138.683C636.036 137.742 636.63 136.455 636.699 135.091V30.5372C636.63 29.1724 636.036 27.8861 635.041 26.9445C634.045 26.0028 632.724 25.4777 631.35 25.4777C629.977 25.4777 628.655 26.0028 627.66 26.9445C626.664 27.8861 626.071 29.1724 626.001 30.5372V135.091Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M61.3516 192.385H53.0537C52.3552 192.383 51.6632 192.518 51.0176 192.784C50.3719 193.049 49.7853 193.438 49.2914 193.929C48.7975 194.421 48.4061 195.004 48.1396 195.646C47.8732 196.289 47.7369 196.977 47.7387 197.672V198.751C47.7369 199.445 47.8732 200.134 48.1396 200.776C48.4061 201.418 48.7975 202.002 49.2914 202.493C49.7853 202.984 50.3719 203.374 51.0176 203.639C51.6632 203.904 52.3552 204.039 53.0537 204.038H61.3516V192.385Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M59.7658 174.743H354.299C354.998 174.742 355.69 174.877 356.335 175.142C356.981 175.407 357.568 175.797 358.062 176.288C358.555 176.779 358.947 177.363 359.213 178.005C359.48 178.647 359.616 179.336 359.614 180.03V212.751C359.616 213.446 359.48 214.135 359.214 214.778C358.947 215.421 358.556 216.005 358.062 216.498C357.569 216.99 356.982 217.381 356.336 217.647C355.691 217.914 354.998 218.051 354.299 218.051H345.988V217.862C345.99 212.588 344.947 207.364 342.919 202.491C340.892 197.617 337.919 193.188 334.171 189.457C330.423 185.726 325.973 182.766 321.075 180.746C316.176 178.726 310.926 177.685 305.624 177.684C285.042 177.684 265.938 193.248 265.246 218.038H252.217C252.217 182.809 211.107 165.572 185.63 187.286C177.292 194.367 171.828 205.036 171.462 218.078H164.953H158.459C158.478 212.786 157.449 207.542 155.428 202.646C153.408 197.751 150.437 193.3 146.686 189.548C142.934 185.796 138.475 182.816 133.564 180.781C128.654 178.745 123.388 177.692 118.068 177.684C97.5265 177.684 78.3953 193.18 77.6903 218.078H59.6166C58.9175 218.08 58.2249 217.944 57.5786 217.679C56.9321 217.415 56.3446 217.025 55.8496 216.534C55.3546 216.043 54.9619 215.46 54.6939 214.817C54.426 214.175 54.2881 213.486 54.2881 212.791V180.03C54.3166 178.609 54.9081 177.256 55.9338 176.266C56.9595 175.276 58.3366 174.729 59.7658 174.743Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M664.453 183.874H726.687C729.622 183.874 732.002 186.241 732.002 189.161V226.076C732.002 228.996 729.622 231.363 726.687 231.363H664.453C661.518 231.363 659.138 228.996 659.138 226.076V189.161C659.138 186.241 661.518 183.874 664.453 183.874Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M674.259 231.983C674.259 232.849 674.605 233.679 675.22 234.291C675.835 234.903 676.67 235.247 677.54 235.247C678.41 235.247 679.245 234.903 679.86 234.291C680.476 233.679 680.821 232.849 680.821 231.983V183.942C680.821 183.076 680.476 182.246 679.86 181.634C679.245 181.022 678.41 180.678 677.54 180.678C676.67 180.678 675.835 181.022 675.22 181.634C674.605 182.246 674.259 183.076 674.259 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M692.789 231.983C692.789 232.412 692.874 232.836 693.039 233.232C693.204 233.628 693.446 233.988 693.75 234.291C694.055 234.594 694.417 234.835 694.815 234.999C695.213 235.163 695.639 235.247 696.07 235.247C696.501 235.247 696.928 235.163 697.326 234.999C697.724 234.835 698.086 234.594 698.391 234.291C698.695 233.988 698.937 233.628 699.102 233.232C699.267 232.836 699.352 232.412 699.352 231.983V183.942C699.352 183.513 699.267 183.089 699.102 182.693C698.937 182.297 698.695 181.937 698.391 181.634C698.086 181.331 697.724 181.09 697.326 180.926C696.928 180.762 696.501 180.678 696.07 180.678C695.639 180.678 695.213 180.762 694.815 180.926C694.417 181.09 694.055 181.331 693.75 181.634C693.446 181.937 693.204 182.297 693.039 182.693C692.874 183.089 692.789 183.513 692.789 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M711.321 231.983C711.321 232.849 711.667 233.679 712.282 234.291C712.898 234.903 713.732 235.247 714.603 235.247C715.473 235.247 716.307 234.903 716.923 234.291C717.538 233.679 717.884 232.849 717.884 231.983V183.942C717.884 183.076 717.538 182.246 716.923 181.634C716.307 181.022 715.473 180.678 714.603 180.678C713.732 180.678 712.898 181.022 712.282 181.634C711.667 182.246 711.321 183.076 711.321 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M571.962 217.754C571.962 224.557 573.99 231.206 577.789 236.862C581.588 242.518 586.988 246.926 593.306 249.529C599.623 252.132 606.575 252.813 613.282 251.486C619.989 250.159 626.149 246.883 630.985 242.074C635.82 237.264 639.113 231.135 640.447 224.464C641.781 217.792 641.096 210.877 638.479 204.593C635.863 198.308 631.431 192.937 625.745 189.158C620.06 185.379 613.375 183.362 606.537 183.362C597.367 183.362 588.573 186.985 582.089 193.435C575.605 199.885 571.962 208.633 571.962 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M582.753 217.754C582.753 222.366 584.128 226.875 586.704 230.71C589.28 234.545 592.941 237.534 597.225 239.299C601.508 241.064 606.222 241.525 610.769 240.626C615.317 239.726 619.494 237.505 622.772 234.244C626.051 230.982 628.284 226.827 629.188 222.304C630.093 217.78 629.629 213.091 627.854 208.83C626.08 204.569 623.075 200.927 619.22 198.365C615.365 195.802 610.832 194.435 606.196 194.435C599.978 194.435 594.016 196.892 589.619 201.265C585.223 205.638 582.753 211.57 582.753 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M587.452 217.754C587.452 221.47 588.56 225.103 590.635 228.192C592.711 231.282 595.66 233.69 599.112 235.112C602.563 236.534 606.36 236.906 610.024 236.181C613.688 235.456 617.053 233.667 619.695 231.039C622.336 228.412 624.135 225.064 624.864 221.42C625.592 217.775 625.218 213.998 623.789 210.565C622.359 207.132 619.939 204.197 616.833 202.133C613.727 200.068 610.075 198.967 606.339 198.967C603.859 198.967 601.403 199.453 599.112 200.397C596.82 201.341 594.738 202.725 592.984 204.469C591.23 206.214 589.839 208.285 588.89 210.565C587.941 212.844 587.452 215.287 587.452 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M595.593 217.754C595.606 219.882 596.252 221.959 597.449 223.723C598.646 225.487 600.341 226.86 602.32 227.668C604.299 228.476 606.474 228.684 608.572 228.266C610.67 227.847 612.596 226.821 614.109 225.316C615.622 223.811 616.654 221.895 617.074 219.808C617.495 217.721 617.286 215.558 616.473 213.589C615.661 211.62 614.281 209.934 612.508 208.743C610.734 207.553 608.647 206.91 606.507 206.897C605.074 206.897 603.655 207.178 602.33 207.723C601.006 208.269 599.803 209.069 598.789 210.077C597.776 211.085 596.972 212.282 596.423 213.599C595.875 214.917 595.593 216.328 595.593 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M477.543 217.754C477.543 224.557 479.57 231.206 483.369 236.862C487.168 242.518 492.568 246.926 498.886 249.529C505.204 252.132 512.155 252.813 518.862 251.486C525.569 250.159 531.729 246.883 536.565 242.074C541.4 237.264 544.693 231.135 546.027 224.464C547.361 217.792 546.676 210.877 544.06 204.593C541.443 198.308 537.011 192.937 531.325 189.158C525.64 185.379 518.955 183.362 512.117 183.362C502.947 183.362 494.153 186.985 487.669 193.435C481.185 199.885 477.543 208.633 477.543 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M488.333 217.754C488.333 222.367 489.708 226.876 492.285 230.711C494.861 234.546 498.523 237.535 502.808 239.3C507.092 241.065 511.807 241.526 516.354 240.625C520.902 239.724 525.079 237.502 528.357 234.239C531.635 230.976 533.867 226.82 534.77 222.295C535.673 217.771 535.207 213.082 533.43 208.821C531.654 204.56 528.647 200.919 524.79 198.358C520.933 195.797 516.399 194.432 511.762 194.435C505.547 194.438 499.588 196.897 495.195 201.27C490.801 205.642 488.333 211.572 488.333 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M493.032 217.754C493.032 221.471 494.14 225.104 496.216 228.194C498.292 231.284 501.243 233.692 504.695 235.113C508.147 236.535 511.945 236.906 515.609 236.18C519.273 235.454 522.639 233.663 525.28 231.035C527.92 228.406 529.718 225.057 530.445 221.411C531.173 217.766 530.797 213.988 529.365 210.555C527.933 207.122 525.511 204.189 522.403 202.126C519.295 200.064 515.642 198.964 511.906 198.967C506.899 198.97 502.099 200.951 498.559 204.474C495.02 207.997 493.032 212.774 493.032 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M501.035 217.754C501.035 219.902 501.675 222.001 502.874 223.786C504.074 225.572 505.778 226.963 507.773 227.785C509.767 228.607 511.962 228.822 514.079 228.403C516.196 227.984 518.141 226.95 519.667 225.431C521.194 223.913 522.233 221.978 522.655 219.872C523.076 217.766 522.86 215.583 522.033 213.599C521.207 211.615 519.808 209.92 518.013 208.727C516.219 207.534 514.108 206.897 511.95 206.897C509.055 206.897 506.279 208.041 504.232 210.077C502.185 212.113 501.035 214.875 501.035 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M271.052 217.754C271.052 224.557 273.08 231.207 276.88 236.863C280.68 242.519 286.08 246.927 292.399 249.53C298.717 252.133 305.67 252.813 312.377 251.485C319.084 250.157 325.245 246.88 330.079 242.069C334.914 237.258 338.206 231.128 339.538 224.456C340.871 217.783 340.184 210.868 337.565 204.583C334.946 198.299 330.513 192.929 324.825 189.151C319.138 185.374 312.452 183.359 305.613 183.362C296.446 183.365 287.655 186.99 281.174 193.44C274.693 199.889 271.052 208.635 271.052 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M282.726 217.754C282.726 222.367 284.101 226.876 286.677 230.711C289.254 234.546 292.916 237.535 297.2 239.3C301.485 241.065 306.199 241.526 310.747 240.625C315.295 239.724 319.472 237.502 322.75 234.239C326.028 230.976 328.26 226.82 329.163 222.295C330.066 217.771 329.599 213.082 327.823 208.821C326.047 204.56 323.04 200.919 319.183 198.358C315.326 195.797 310.792 194.432 306.155 194.435C303.077 194.435 300.029 195.038 297.186 196.21C294.342 197.382 291.759 199.1 289.583 201.266C287.407 203.431 285.682 206.002 284.505 208.831C283.329 211.66 282.724 214.693 282.726 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M287.425 217.754C287.425 221.471 288.533 225.104 290.609 228.194C292.685 231.284 295.635 233.692 299.087 235.113C302.539 236.535 306.338 236.906 310.002 236.18C313.666 235.454 317.031 233.663 319.672 231.035C322.313 228.406 324.111 225.057 324.838 221.411C325.565 217.766 325.189 213.988 323.758 210.555C322.326 207.122 319.903 204.189 316.795 202.126C313.688 200.064 310.035 198.964 306.299 198.967C301.292 198.97 296.491 200.951 292.952 204.474C289.413 207.997 287.425 212.774 287.425 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M295.428 217.754C295.428 219.905 296.07 222.006 297.273 223.793C298.475 225.58 300.184 226.972 302.182 227.791C304.18 228.611 306.378 228.822 308.498 228.398C310.617 227.973 312.561 226.933 314.085 225.407C315.609 223.882 316.643 221.941 317.056 219.831C317.47 217.72 317.244 215.535 316.407 213.552C315.571 211.57 314.161 209.879 312.358 208.694C310.554 207.509 308.437 206.884 306.275 206.897C304.845 206.897 303.429 207.178 302.109 207.725C300.788 208.271 299.589 209.072 298.581 210.081C297.573 211.09 296.776 212.288 296.235 213.605C295.694 214.921 295.42 216.332 295.428 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M177.514 217.754C177.514 224.557 179.542 231.207 183.342 236.863C187.142 242.519 192.542 246.927 198.861 249.53C205.179 252.133 212.132 252.813 218.839 251.485C225.546 250.157 231.706 246.88 236.541 242.069C241.376 237.258 244.668 231.128 246 224.456C247.333 217.783 246.646 210.868 244.027 204.583C241.408 198.299 236.975 192.929 231.287 189.151C225.6 185.374 218.914 183.359 212.075 183.362C202.908 183.365 194.117 186.99 187.636 193.44C181.155 199.889 177.514 208.635 177.514 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M188.306 217.754C188.306 222.367 189.681 226.876 192.258 230.711C194.834 234.546 198.496 237.535 202.781 239.3C207.065 241.065 211.779 241.526 216.327 240.625C220.875 239.724 225.052 237.502 228.33 234.239C231.608 230.976 233.84 226.82 234.743 222.295C235.646 217.771 235.179 213.082 233.403 208.821C231.627 204.56 228.62 200.919 224.763 198.358C220.906 195.797 216.372 194.432 211.735 194.435C208.657 194.435 205.609 195.038 202.766 196.21C199.922 197.382 197.339 199.1 195.163 201.266C192.987 203.431 191.262 206.002 190.085 208.831C188.909 211.66 188.304 214.693 188.306 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M193.004 217.754C193.004 221.471 194.112 225.104 196.188 228.194C198.264 231.284 201.215 233.692 204.667 235.113C208.119 236.535 211.917 236.906 215.581 236.18C219.245 235.454 222.61 233.663 225.251 231.035C227.892 228.406 229.69 225.057 230.417 221.411C231.144 217.766 230.768 213.988 229.337 210.555C227.905 207.122 225.482 204.189 222.374 202.126C219.267 200.064 215.614 198.964 211.878 198.967C206.871 198.97 202.07 200.951 198.531 204.474C194.992 207.997 193.004 212.774 193.004 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M201.143 217.754C201.157 219.885 201.804 221.965 203.004 223.73C204.204 225.496 205.903 226.868 207.886 227.675C209.869 228.481 212.048 228.685 214.148 228.261C216.247 227.837 218.174 226.804 219.684 225.293C221.194 223.781 222.22 221.858 222.633 219.767C223.046 217.676 222.828 215.51 222.005 213.542C221.182 211.575 219.792 209.893 218.009 208.711C216.227 207.528 214.133 206.897 211.99 206.897C209.108 206.918 206.352 208.071 204.32 210.105C202.289 212.138 201.147 214.887 201.143 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M83.0924 217.754C83.0924 224.557 85.1201 231.206 88.9192 236.862C92.7183 242.518 98.1181 246.926 104.436 249.529C110.753 252.132 117.705 252.813 124.412 251.486C131.119 250.159 137.279 246.883 142.115 242.074C146.95 237.264 150.243 231.135 151.577 224.464C152.911 217.792 152.226 210.877 149.609 204.593C146.993 198.308 142.561 192.937 136.875 189.158C131.19 185.379 124.505 183.362 117.667 183.362C108.497 183.362 99.7029 186.985 93.2189 193.435C86.735 199.885 83.0924 208.633 83.0924 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M94.7677 217.754C94.7677 222.366 96.1426 226.875 98.7185 230.71C101.294 234.545 104.956 237.534 109.239 239.299C113.523 241.064 118.237 241.525 122.784 240.626C127.331 239.726 131.509 237.505 134.787 234.244C138.066 230.982 140.298 226.827 141.203 222.304C142.107 217.78 141.643 213.091 139.869 208.83C138.095 204.569 135.09 200.927 131.235 198.365C127.38 195.802 122.847 194.435 118.211 194.435C111.993 194.435 106.03 196.892 101.634 201.265C97.2375 205.638 94.7677 211.57 94.7677 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M98.5841 217.754C98.5841 221.47 99.6919 225.103 101.767 228.192C103.843 231.282 106.792 233.69 110.243 235.112C113.695 236.534 117.492 236.906 121.156 236.181C124.82 235.456 128.185 233.667 130.827 231.039C133.468 228.412 135.267 225.064 135.995 221.42C136.724 217.775 136.35 213.998 134.921 210.565C133.491 207.132 131.07 204.197 127.964 202.133C124.858 200.068 121.207 198.967 117.471 198.967C114.991 198.967 112.535 199.453 110.243 200.397C107.952 201.341 105.87 202.725 104.116 204.469C102.362 206.214 100.971 208.285 100.022 210.565C99.0727 212.844 98.5841 215.287 98.5841 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M106.722 217.754C106.736 219.885 107.383 221.965 108.583 223.73C109.783 225.496 111.482 226.868 113.465 227.675C115.448 228.481 117.627 228.685 119.727 228.261C121.826 227.837 123.753 226.804 125.263 225.293C126.773 223.781 127.799 221.858 128.212 219.767C128.626 217.676 128.407 215.51 127.584 213.542C126.761 211.575 125.371 209.893 123.588 208.711C121.806 207.528 119.712 206.897 117.569 206.897C114.686 206.915 111.928 208.067 109.895 210.101C107.863 212.135 106.722 214.886 106.722 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </>
      ) : null}
      {carVersion === 1 ? (
        <>
          <path
            d="M796.1 217.754C796.1 224.56 798.13 231.213 801.933 236.87C805.735 242.528 811.14 246.936 817.462 249.537C823.785 252.137 830.741 252.814 837.45 251.48C844.159 250.146 850.319 246.863 855.151 242.045C859.983 237.227 863.27 231.091 864.595 224.414C865.919 217.737 865.223 210.82 862.594 204.537C859.964 198.254 855.52 192.888 849.824 189.118C844.127 185.349 837.435 183.346 830.593 183.362C821.438 183.383 812.664 187.016 806.198 193.464C799.732 199.911 796.1 208.647 796.1 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M806.893 217.754C806.893 222.37 808.27 226.882 810.849 230.718C813.429 234.555 817.095 237.544 821.383 239.306C825.672 241.069 830.389 241.526 834.939 240.62C839.489 239.713 843.666 237.484 846.941 234.215C850.216 230.945 852.442 226.783 853.338 222.254C854.233 217.725 853.757 213.034 851.97 208.774C850.184 204.514 847.166 200.878 843.3 198.325C839.435 195.773 834.894 194.419 830.254 194.435C824.05 194.453 818.106 196.918 813.726 201.289C809.347 205.661 806.889 211.582 806.893 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M811.592 217.754C811.592 221.474 812.702 225.109 814.781 228.201C816.86 231.292 819.814 233.7 823.27 235.12C826.726 236.539 830.528 236.906 834.194 236.175C837.86 235.443 841.225 233.646 843.863 231.01C846.501 228.375 848.294 225.02 849.013 221.37C849.733 217.72 849.347 213.94 847.905 210.508C846.463 207.077 844.03 204.148 840.913 202.093C837.796 200.039 834.137 198.95 830.398 198.966C825.403 198.988 820.62 200.977 817.095 204.498C813.571 208.019 811.592 212.785 811.592 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M819.73 217.754C819.744 219.885 820.391 221.965 821.591 223.73C822.791 225.496 824.49 226.868 826.473 227.675C828.456 228.481 830.635 228.685 832.735 228.261C834.834 227.837 836.761 226.804 838.271 225.293C839.781 223.781 840.807 221.858 841.22 219.767C841.633 217.676 841.415 215.51 840.592 213.542C839.769 211.575 838.379 209.893 836.596 208.711C834.814 207.528 832.72 206.897 830.577 206.897C827.695 206.918 824.939 208.071 822.907 210.105C820.876 212.138 819.734 214.887 819.73 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M901.429 187.974C901.425 189.375 900.864 190.718 899.868 191.709C898.872 192.7 897.522 193.258 896.114 193.261H893.877V175.593H896.114C896.811 175.591 897.502 175.726 898.147 175.991C898.791 176.255 899.377 176.643 899.871 177.133C900.365 177.623 900.757 178.205 901.024 178.845C901.291 179.486 901.429 180.173 901.429 180.867V187.974Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M889.76 217.013C889.76 217.728 890.045 218.414 890.554 218.92C891.062 219.426 891.752 219.71 892.471 219.71H902.057C902.441 219.71 902.82 219.634 903.174 219.488C903.528 219.341 903.849 219.126 904.119 218.855C904.388 218.584 904.602 218.263 904.746 217.91C904.89 217.556 904.962 217.178 904.959 216.797V198.211C904.959 197.446 904.653 196.712 904.109 196.17C903.565 195.629 902.827 195.325 902.057 195.325H892.417C891.698 195.325 891.008 195.609 890.5 196.115C889.991 196.621 889.705 197.307 889.705 198.022L889.76 217.013Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M486.877 164.83H669.349V175.93H486.877V164.83Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M66.4445 164.115H334.023V175.93H66.4445V164.115Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M455.799 192.627H447.501C446.091 192.627 444.739 193.184 443.743 194.176C442.746 195.167 442.186 196.512 442.186 197.914V198.993C442.186 200.396 442.746 201.74 443.743 202.732C444.739 203.723 446.091 204.28 447.501 204.28H455.799V192.627Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M453.995 174.986H741.709V218.307H646.582V218.145C646.585 212.87 645.544 207.645 643.517 202.77C641.491 197.894 638.518 193.464 634.77 189.732C631.022 185.999 626.571 183.038 621.672 181.017C616.773 178.996 611.521 177.955 606.218 177.953C585.636 177.953 566.532 193.518 565.84 218.307H552.797C552.797 218.24 552.797 218.186 552.797 218.118C552.768 207.475 548.505 197.275 540.939 189.749C533.373 182.223 523.119 177.982 512.419 177.953C491.878 177.953 472.747 193.45 472.042 218.415H453.968C453.269 218.415 452.577 218.278 451.931 218.011C451.286 217.745 450.699 217.354 450.205 216.862C449.712 216.369 449.32 215.785 449.054 215.142C448.788 214.499 448.652 213.81 448.653 213.115V180.273C448.653 179.576 448.792 178.887 449.061 178.244C449.329 177.6 449.723 177.016 450.22 176.525C450.716 176.034 451.305 175.645 451.953 175.381C452.601 175.117 453.295 174.982 453.995 174.986Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M822.892 75.1801H748.807V45.0495C748.807 44.3752 748.947 43.7081 749.219 43.0904C749.491 42.4726 749.888 41.9175 750.386 41.4601C750.884 41.0026 751.472 40.6528 752.113 40.4326C752.753 40.2124 753.433 40.1266 754.109 40.1806L783.938 44.0245C787.346 44.2901 790.528 45.8269 792.846 48.3269L822.892 75.1801Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M791.893 206.911H792.124C791.045 210.581 790.515 214.389 790.551 218.213H741.74V80.5751C741.744 79.174 742.305 77.8313 743.301 76.8405C744.297 75.8498 745.646 75.2916 747.055 75.2881H866.221C867.777 75.2888 869.294 75.769 870.564 76.6627C871.834 77.5564 872.794 78.8195 873.313 80.2784L890.302 128.077L894.369 138.152V219.265H872.038C872.017 214.87 871.562 210.489 870.682 206.182C866.696 190.321 849.26 177.859 830.942 177.859C822.185 177.852 813.663 180.682 806.667 185.922C799.671 191.161 794.58 198.524 792.164 206.897L791.893 206.911Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.49 97.2453C798.49 95.8431 799.05 94.4983 800.047 93.5068C801.043 92.5153 802.395 91.9583 803.805 91.9583H857.09C858.647 91.9565 860.168 92.432 861.444 93.32C862.72 94.2081 863.69 95.4656 864.222 96.9216L874.716 125.461C874.931 126.026 875.003 126.635 874.927 127.234C874.851 127.833 874.629 128.405 874.28 128.9C873.931 129.394 873.466 129.796 872.925 130.071C872.385 130.346 871.785 130.486 871.177 130.478H803.805C802.396 130.474 801.046 129.916 800.05 128.925C799.055 127.935 798.493 126.592 798.49 125.191V97.2453Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.316 139.676C798.316 140.832 798.778 141.94 799.599 142.757C800.42 143.574 801.534 144.033 802.696 144.033H811.455C812.616 144.033 813.73 143.574 814.551 142.757C815.373 141.94 815.834 140.832 815.834 139.676C815.834 138.521 815.373 137.413 814.551 136.596C813.73 135.779 812.616 135.32 811.455 135.32H802.696C801.534 135.32 800.42 135.779 799.599 136.596C798.778 137.413 798.316 138.521 798.316 139.676Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M878.646 200.882H880.178C880.999 200.882 881.812 200.721 882.57 200.408C883.329 200.096 884.018 199.638 884.598 199.061C885.178 198.483 885.639 197.798 885.953 197.043C886.267 196.289 886.429 195.481 886.429 194.664V136.372L870.701 91.5267C870.114 89.8988 869.035 88.4911 867.613 87.4964C866.191 86.5016 864.494 85.9685 862.755 85.97H796.996C795.337 85.9735 793.747 86.6316 792.576 87.7998C791.404 88.968 790.746 90.5508 790.746 92.2011V164.682C790.753 166.336 791.419 167.919 792.599 169.085C793.778 170.25 795.374 170.903 797.037 170.899H832.045C848.776 170.899 865.128 181.231 870.931 195.473C871.533 197.034 872.588 198.382 873.962 199.345C875.335 200.308 876.966 200.843 878.646 200.882Z"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M755.414 91.9583H776.986C779.921 91.9583 782.301 94.3253 782.301 97.2453V125.15C782.301 128.07 779.921 130.437 776.986 130.437H755.414C752.479 130.437 750.099 128.07 750.099 125.15V97.2453C750.099 94.3253 752.479 91.9583 755.414 91.9583Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M6.65393 1H723.701C726.823 1 729.354 3.51804 729.354 6.6242V159.934C729.354 163.04 726.823 165.558 723.701 165.558H6.65393C3.53131 165.558 1 163.04 1 159.934V6.6242C1 3.51804 3.53131 1 6.65393 1Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M14.4003 8.66077H717.17C720 8.66077 722.295 10.9433 722.295 13.759V152.799C722.295 155.615 720 157.898 717.17 157.898H14.4003C11.5698 157.898 9.27515 155.615 9.27515 152.799V13.759C9.27515 10.9433 11.5698 8.66077 14.4003 8.66077Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M61.3516 192.385H53.0537C52.3552 192.383 51.6632 192.518 51.0176 192.784C50.3719 193.049 49.7853 193.438 49.2914 193.929C48.7975 194.421 48.4061 195.004 48.1396 195.646C47.8732 196.289 47.7369 196.977 47.7387 197.672V198.751C47.7369 199.445 47.8732 200.134 48.1396 200.776C48.4061 201.418 48.7975 202.002 49.2914 202.493C49.7853 202.984 50.3719 203.374 51.0176 203.639C51.6632 203.904 52.3552 204.039 53.0537 204.038H61.3516V192.385Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M59.7658 174.743H354.299C354.998 174.742 355.69 174.877 356.335 175.142C356.981 175.407 357.568 175.797 358.062 176.288C358.555 176.779 358.947 177.363 359.213 178.005C359.48 178.647 359.616 179.336 359.614 180.03V212.751C359.616 213.446 359.48 214.135 359.214 214.778C358.947 215.421 358.556 216.005 358.062 216.498C357.569 216.99 356.982 217.381 356.336 217.647C355.691 217.914 354.998 218.051 354.299 218.051H345.988V217.862C345.99 212.588 344.947 207.364 342.919 202.491C340.892 197.617 337.919 193.188 334.171 189.457C330.423 185.726 325.973 182.766 321.075 180.746C316.176 178.726 310.926 177.685 305.624 177.684C285.042 177.684 265.938 193.248 265.246 218.038H252.217C252.217 182.809 211.107 165.572 185.63 187.286C177.292 194.367 171.828 205.036 171.462 218.078H164.953H158.459C158.478 212.786 157.449 207.542 155.428 202.646C153.408 197.751 150.437 193.3 146.686 189.548C142.934 185.796 138.475 182.816 133.564 180.781C128.654 178.745 123.388 177.692 118.068 177.684C97.5265 177.684 78.3953 193.18 77.6903 218.078H59.6166C58.9175 218.08 58.2249 217.944 57.5786 217.679C56.9321 217.415 56.3446 217.025 55.8496 216.534C55.3546 216.043 54.9619 215.46 54.6939 214.817C54.426 214.175 54.2881 213.486 54.2881 212.791V180.03C54.3166 178.609 54.9081 177.256 55.9338 176.266C56.9595 175.276 58.3366 174.729 59.7658 174.743Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M664.453 183.874H726.687C729.622 183.874 732.002 186.241 732.002 189.161V226.076C732.002 228.996 729.622 231.363 726.687 231.363H664.453C661.518 231.363 659.138 228.996 659.138 226.076V189.161C659.138 186.241 661.518 183.874 664.453 183.874Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M674.259 231.983C674.259 232.849 674.605 233.679 675.22 234.291C675.835 234.903 676.67 235.247 677.54 235.247C678.41 235.247 679.245 234.903 679.86 234.291C680.476 233.679 680.821 232.849 680.821 231.983V183.942C680.821 183.076 680.476 182.246 679.86 181.634C679.245 181.022 678.41 180.678 677.54 180.678C676.67 180.678 675.835 181.022 675.22 181.634C674.605 182.246 674.259 183.076 674.259 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M692.789 231.983C692.789 232.412 692.874 232.836 693.039 233.232C693.204 233.628 693.446 233.988 693.75 234.291C694.055 234.594 694.417 234.835 694.815 234.999C695.213 235.163 695.639 235.247 696.07 235.247C696.501 235.247 696.928 235.163 697.326 234.999C697.724 234.835 698.086 234.594 698.391 234.291C698.695 233.988 698.937 233.628 699.102 233.232C699.267 232.836 699.352 232.412 699.352 231.983V183.942C699.352 183.513 699.267 183.089 699.102 182.693C698.937 182.297 698.695 181.937 698.391 181.634C698.086 181.331 697.724 181.09 697.326 180.926C696.928 180.762 696.501 180.678 696.07 180.678C695.639 180.678 695.213 180.762 694.815 180.926C694.417 181.09 694.055 181.331 693.75 181.634C693.446 181.937 693.204 182.297 693.039 182.693C692.874 183.089 692.789 183.513 692.789 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M711.321 231.983C711.321 232.849 711.667 233.679 712.282 234.291C712.898 234.903 713.732 235.247 714.603 235.247C715.473 235.247 716.307 234.903 716.923 234.291C717.538 233.679 717.884 232.849 717.884 231.983V183.942C717.884 183.076 717.538 182.246 716.923 181.634C716.307 181.022 715.473 180.678 714.603 180.678C713.732 180.678 712.898 181.022 712.282 181.634C711.667 182.246 711.321 183.076 711.321 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M571.962 217.754C571.962 224.557 573.99 231.206 577.789 236.862C581.588 242.518 586.988 246.926 593.306 249.529C599.623 252.132 606.575 252.813 613.282 251.486C619.989 250.159 626.149 246.883 630.985 242.074C635.82 237.264 639.113 231.135 640.447 224.464C641.781 217.792 641.096 210.877 638.479 204.593C635.863 198.308 631.431 192.937 625.745 189.158C620.06 185.379 613.375 183.362 606.537 183.362C597.367 183.362 588.573 186.985 582.089 193.435C575.605 199.885 571.962 208.633 571.962 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M582.753 217.754C582.753 222.366 584.128 226.875 586.704 230.71C589.28 234.545 592.941 237.534 597.225 239.299C601.508 241.064 606.222 241.525 610.769 240.626C615.317 239.726 619.494 237.505 622.772 234.244C626.051 230.982 628.284 226.827 629.188 222.304C630.093 217.78 629.629 213.091 627.854 208.83C626.08 204.569 623.075 200.927 619.22 198.365C615.365 195.802 610.832 194.435 606.196 194.435C599.978 194.435 594.016 196.892 589.619 201.265C585.223 205.638 582.753 211.57 582.753 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M587.452 217.754C587.452 221.47 588.56 225.103 590.635 228.192C592.711 231.282 595.66 233.69 599.112 235.112C602.563 236.534 606.36 236.906 610.024 236.181C613.688 235.456 617.053 233.667 619.695 231.039C622.336 228.412 624.135 225.064 624.864 221.42C625.592 217.775 625.218 213.998 623.789 210.565C622.359 207.132 619.939 204.197 616.833 202.133C613.727 200.068 610.075 198.967 606.339 198.967C603.859 198.967 601.403 199.453 599.112 200.397C596.82 201.341 594.738 202.725 592.984 204.469C591.23 206.214 589.839 208.285 588.89 210.565C587.941 212.844 587.452 215.287 587.452 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M595.593 217.754C595.606 219.882 596.252 221.959 597.449 223.723C598.646 225.487 600.341 226.86 602.32 227.668C604.299 228.476 606.474 228.684 608.572 228.266C610.67 227.847 612.596 226.821 614.109 225.316C615.622 223.811 616.654 221.895 617.074 219.808C617.495 217.721 617.286 215.558 616.473 213.589C615.661 211.62 614.281 209.934 612.508 208.743C610.734 207.553 608.647 206.91 606.507 206.897C605.074 206.897 603.655 207.178 602.33 207.723C601.006 208.269 599.803 209.069 598.789 210.077C597.776 211.085 596.972 212.282 596.423 213.599C595.875 214.917 595.593 216.328 595.593 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M477.543 217.754C477.543 224.557 479.57 231.206 483.369 236.862C487.168 242.518 492.568 246.926 498.886 249.529C505.204 252.132 512.155 252.813 518.862 251.486C525.569 250.159 531.729 246.883 536.565 242.074C541.4 237.264 544.693 231.135 546.027 224.464C547.361 217.792 546.676 210.877 544.06 204.593C541.443 198.308 537.011 192.937 531.325 189.158C525.64 185.379 518.955 183.362 512.117 183.362C502.947 183.362 494.153 186.985 487.669 193.435C481.185 199.885 477.543 208.633 477.543 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M488.333 217.754C488.333 222.367 489.708 226.876 492.285 230.711C494.861 234.546 498.523 237.535 502.808 239.3C507.092 241.065 511.807 241.526 516.354 240.625C520.902 239.724 525.079 237.502 528.357 234.239C531.635 230.976 533.867 226.82 534.77 222.295C535.673 217.771 535.207 213.082 533.43 208.821C531.654 204.56 528.647 200.919 524.79 198.358C520.933 195.797 516.399 194.432 511.762 194.435C505.547 194.438 499.588 196.897 495.195 201.27C490.801 205.642 488.333 211.572 488.333 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M493.032 217.754C493.032 221.471 494.14 225.104 496.216 228.194C498.292 231.284 501.243 233.692 504.695 235.113C508.147 236.535 511.945 236.906 515.609 236.18C519.273 235.454 522.639 233.663 525.28 231.035C527.92 228.406 529.718 225.057 530.445 221.411C531.173 217.766 530.797 213.988 529.365 210.555C527.933 207.122 525.511 204.189 522.403 202.126C519.295 200.064 515.642 198.964 511.906 198.967C506.899 198.97 502.099 200.951 498.559 204.474C495.02 207.997 493.032 212.774 493.032 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M501.035 217.754C501.035 219.902 501.675 222.001 502.874 223.786C504.074 225.572 505.778 226.963 507.773 227.785C509.767 228.607 511.962 228.822 514.079 228.403C516.196 227.984 518.141 226.95 519.667 225.431C521.194 223.913 522.233 221.978 522.655 219.872C523.076 217.766 522.86 215.583 522.033 213.599C521.207 211.615 519.808 209.92 518.013 208.727C516.219 207.534 514.108 206.897 511.95 206.897C509.055 206.897 506.279 208.041 504.232 210.077C502.185 212.113 501.035 214.875 501.035 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M271.052 217.754C271.052 224.557 273.08 231.207 276.88 236.863C280.68 242.519 286.08 246.927 292.399 249.53C298.717 252.133 305.67 252.813 312.377 251.485C319.084 250.157 325.245 246.88 330.079 242.069C334.914 237.258 338.206 231.128 339.538 224.456C340.871 217.783 340.184 210.868 337.565 204.583C334.946 198.299 330.513 192.929 324.825 189.151C319.138 185.374 312.452 183.359 305.613 183.362C296.446 183.365 287.655 186.99 281.174 193.44C274.693 199.889 271.052 208.635 271.052 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M282.726 217.754C282.726 222.367 284.101 226.876 286.677 230.711C289.254 234.546 292.916 237.535 297.2 239.3C301.485 241.065 306.199 241.526 310.747 240.625C315.295 239.724 319.472 237.502 322.75 234.239C326.028 230.976 328.26 226.82 329.163 222.295C330.066 217.771 329.599 213.082 327.823 208.821C326.047 204.56 323.04 200.919 319.183 198.358C315.326 195.797 310.792 194.432 306.155 194.435C303.077 194.435 300.029 195.038 297.186 196.21C294.342 197.382 291.759 199.1 289.583 201.266C287.407 203.431 285.682 206.002 284.505 208.831C283.329 211.66 282.724 214.693 282.726 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M287.425 217.754C287.425 221.471 288.533 225.104 290.609 228.194C292.685 231.284 295.635 233.692 299.087 235.113C302.539 236.535 306.338 236.906 310.002 236.18C313.666 235.454 317.031 233.663 319.672 231.035C322.313 228.406 324.111 225.057 324.838 221.411C325.565 217.766 325.189 213.988 323.758 210.555C322.326 207.122 319.903 204.189 316.795 202.126C313.688 200.064 310.035 198.964 306.299 198.967C301.292 198.97 296.491 200.951 292.952 204.474C289.413 207.997 287.425 212.774 287.425 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M295.428 217.754C295.428 219.905 296.07 222.006 297.273 223.793C298.475 225.58 300.184 226.972 302.182 227.791C304.18 228.611 306.378 228.822 308.498 228.398C310.617 227.973 312.561 226.933 314.085 225.407C315.609 223.882 316.643 221.941 317.056 219.831C317.47 217.72 317.244 215.535 316.407 213.552C315.571 211.57 314.161 209.879 312.358 208.694C310.554 207.509 308.437 206.884 306.275 206.897C304.845 206.897 303.429 207.178 302.109 207.725C300.788 208.271 299.589 209.072 298.581 210.081C297.573 211.09 296.776 212.288 296.235 213.605C295.694 214.921 295.42 216.332 295.428 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M177.514 217.754C177.514 224.557 179.542 231.207 183.342 236.863C187.142 242.519 192.542 246.927 198.861 249.53C205.179 252.133 212.132 252.813 218.839 251.485C225.546 250.157 231.706 246.88 236.541 242.069C241.376 237.258 244.668 231.128 246 224.456C247.333 217.783 246.646 210.868 244.027 204.583C241.408 198.299 236.975 192.929 231.287 189.151C225.6 185.374 218.914 183.359 212.075 183.362C202.908 183.365 194.117 186.99 187.636 193.44C181.155 199.889 177.514 208.635 177.514 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M188.306 217.754C188.306 222.367 189.681 226.876 192.258 230.711C194.834 234.546 198.496 237.535 202.781 239.3C207.065 241.065 211.779 241.526 216.327 240.625C220.875 239.724 225.052 237.502 228.33 234.239C231.608 230.976 233.84 226.82 234.743 222.295C235.646 217.771 235.179 213.082 233.403 208.821C231.627 204.56 228.62 200.919 224.763 198.358C220.906 195.797 216.372 194.432 211.735 194.435C208.657 194.435 205.609 195.038 202.766 196.21C199.922 197.382 197.339 199.1 195.163 201.266C192.987 203.431 191.262 206.002 190.085 208.831C188.909 211.66 188.304 214.693 188.306 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M193.004 217.754C193.004 221.471 194.112 225.104 196.188 228.194C198.264 231.284 201.215 233.692 204.667 235.113C208.119 236.535 211.917 236.906 215.581 236.18C219.245 235.454 222.61 233.663 225.251 231.035C227.892 228.406 229.69 225.057 230.417 221.411C231.144 217.766 230.768 213.988 229.337 210.555C227.905 207.122 225.482 204.189 222.374 202.126C219.267 200.064 215.614 198.964 211.878 198.967C206.871 198.97 202.07 200.951 198.531 204.474C194.992 207.997 193.004 212.774 193.004 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M201.143 217.754C201.157 219.885 201.804 221.965 203.004 223.73C204.204 225.496 205.903 226.868 207.886 227.675C209.869 228.481 212.048 228.685 214.148 228.261C216.247 227.837 218.174 226.804 219.684 225.293C221.194 223.781 222.22 221.858 222.633 219.767C223.046 217.676 222.828 215.51 222.005 213.542C221.182 211.575 219.792 209.893 218.009 208.711C216.227 207.528 214.133 206.897 211.99 206.897C209.108 206.918 206.352 208.071 204.32 210.105C202.289 212.138 201.147 214.887 201.143 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M83.0924 217.754C83.0924 224.557 85.1201 231.206 88.9192 236.862C92.7183 242.518 98.1181 246.926 104.436 249.529C110.753 252.132 117.705 252.813 124.412 251.486C131.119 250.159 137.279 246.883 142.115 242.074C146.95 237.264 150.243 231.135 151.577 224.464C152.911 217.792 152.226 210.877 149.609 204.593C146.993 198.308 142.561 192.937 136.875 189.158C131.19 185.379 124.505 183.362 117.667 183.362C108.497 183.362 99.7029 186.985 93.2189 193.435C86.735 199.885 83.0924 208.633 83.0924 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M94.7677 217.754C94.7677 222.366 96.1426 226.875 98.7185 230.71C101.294 234.545 104.956 237.534 109.239 239.299C113.523 241.064 118.237 241.525 122.784 240.626C127.331 239.726 131.509 237.505 134.787 234.244C138.066 230.982 140.298 226.827 141.203 222.304C142.107 217.78 141.643 213.091 139.869 208.83C138.095 204.569 135.09 200.927 131.235 198.365C127.38 195.802 122.847 194.435 118.211 194.435C111.993 194.435 106.03 196.892 101.634 201.265C97.2375 205.638 94.7677 211.57 94.7677 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M98.5841 217.754C98.5841 221.47 99.6919 225.103 101.767 228.192C103.843 231.282 106.792 233.69 110.243 235.112C113.695 236.534 117.492 236.906 121.156 236.181C124.82 235.456 128.185 233.667 130.827 231.039C133.468 228.412 135.267 225.064 135.995 221.42C136.724 217.775 136.35 213.998 134.921 210.565C133.491 207.132 131.07 204.197 127.964 202.133C124.858 200.068 121.207 198.967 117.471 198.967C114.991 198.967 112.535 199.453 110.243 200.397C107.952 201.341 105.87 202.725 104.116 204.469C102.362 206.214 100.971 208.285 100.022 210.565C99.0727 212.844 98.5841 215.287 98.5841 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M106.722 217.754C106.736 219.885 107.383 221.965 108.583 223.73C109.783 225.496 111.482 226.868 113.465 227.675C115.448 228.481 117.627 228.685 119.727 228.261C121.826 227.837 123.753 226.804 125.263 225.293C126.773 223.781 127.799 221.858 128.212 219.767C128.626 217.676 128.407 215.51 127.584 213.542C126.761 211.575 125.371 209.893 123.588 208.711C121.806 207.528 119.712 206.897 117.569 206.897C114.686 206.915 111.928 208.067 109.895 210.101C107.863 212.135 106.722 214.886 106.722 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path d="M159.25 151.141H241.794V87.9407H159.25V151.141Z" fill="#FED700" />
          <path d="M216.307 151.141H241.794V87.9407H216.307V151.141Z" fill="#EBC400" />
          <path d="M189.536 106.035H209.928V94.7977H189.536V106.035Z" fill="white" />
          <path
            d="M194.19 146.903H184.821V137.558H194.19V146.903ZM185.524 146.212H193.5V138.236H185.524V146.212Z"
            fill="white"
          />
          <path d="M192.189 144.7H186.809V145.391H192.189V144.7Z" fill="white" />
          <path d="M190.975 139.415L192.189 142.451H189.749L190.975 139.415Z" fill="white" />
          <path d="M191.32 141.879H190.63V144.45H191.32V141.879Z" fill="white" />
          <path d="M188.023 139.415L189.249 142.451H186.809L188.023 139.415Z" fill="white" />
          <path d="M188.381 141.879H187.69V144.45H188.381V141.879Z" fill="white" />
          <path
            d="M183.239 146.784H173.882V137.463H183.239V146.784ZM174.572 146.093H182.548V138.117H174.572V146.093Z"
            fill="white"
          />
          <path
            d="M180.928 140.284V141.355C180.929 141.439 180.914 141.523 180.883 141.6C180.852 141.678 180.806 141.749 180.747 141.809C180.689 141.869 180.618 141.917 180.541 141.949C180.464 141.981 180.381 141.998 180.297 141.998C180.128 141.995 179.968 141.926 179.85 141.806C179.732 141.686 179.666 141.524 179.666 141.355V140.284H180.928ZM181.618 139.594H178.987V141.355C178.97 141.539 178.991 141.723 179.049 141.898C179.107 142.073 179.201 142.233 179.325 142.37C179.449 142.506 179.6 142.615 179.768 142.689C179.937 142.764 180.119 142.802 180.303 142.802C180.487 142.802 180.669 142.764 180.837 142.689C181.006 142.615 181.157 142.506 181.28 142.37C181.404 142.233 181.498 142.073 181.556 141.898C181.615 141.723 181.636 141.539 181.618 141.355V139.641V139.594Z"
            fill="white"
          />
          <path d="M180.654 142.677H179.964V144.665H180.654V142.677Z" fill="white" />
          <path d="M181.632 144.331H178.989V145.022H181.632V144.331Z" fill="white" />
          <path
            d="M176.808 142.677C176.982 142.678 177.155 142.645 177.316 142.579C177.477 142.514 177.623 142.416 177.746 142.293C177.869 142.17 177.967 142.024 178.033 141.863C178.098 141.702 178.131 141.529 178.13 141.355V139.641H175.499V141.403C175.508 141.744 175.65 142.068 175.895 142.306C176.14 142.544 176.467 142.677 176.808 142.677Z"
            fill="white"
          />
          <path d="M177.167 142.677H176.477V144.665H177.167V142.677Z" fill="white" />
          <path d="M178.142 144.331H175.499V145.022H178.142V144.331Z" fill="white" />
          <path
            d="M172.285 146.784H162.94V137.463H172.285V146.784ZM163.631 146.093H171.571V138.117H163.595L163.631 146.093Z"
            fill="white"
          />
          <path
            d="M168.272 145.022C168.006 145.019 167.752 144.911 167.565 144.721C167.377 144.532 167.272 144.276 167.272 144.01V139.641H167.999V144.058C167.99 144.104 167.991 144.152 168.003 144.198C168.014 144.244 168.036 144.287 168.066 144.324C168.096 144.36 168.134 144.39 168.177 144.41C168.22 144.43 168.267 144.441 168.314 144.441C168.362 144.441 168.408 144.43 168.451 144.41C168.494 144.39 168.532 144.36 168.562 144.324C168.592 144.287 168.614 144.244 168.626 144.198C168.637 144.152 168.639 144.104 168.63 144.058V143.724H169.32V144.058C169.308 144.324 169.191 144.575 168.995 144.755C168.798 144.936 168.539 145.032 168.272 145.022Z"
            fill="white"
          />
          <path
            d="M165.286 142.105H169.953C169.953 141.509 169.716 140.936 169.294 140.514C168.872 140.092 168.299 139.855 167.703 139.855H167.536C166.939 139.855 166.367 140.092 165.945 140.514C165.523 140.936 165.286 141.509 165.286 142.105Z"
            fill="white"
          />
          <path d="M76.3355 151.141H158.88V87.9407H76.3355L76.3355 151.141Z" fill="#FED700" />
          <path d="M133.393 151.141H158.88V87.9407H133.393V151.141Z" fill="#EBC400" />
          <path d="M106.609 106.035H127.001V94.7977H106.609V106.035Z" fill="white" />
          <path
            d="M111.253 146.903H101.908V137.558H111.253V146.903ZM102.587 146.212H110.563V138.236H102.587V146.212Z"
            fill="white"
          />
          <path d="M109.265 144.7H103.884V145.391H109.265V144.7Z" fill="white" />
          <path d="M108.049 139.415L109.263 142.451H106.835L108.049 139.415Z" fill="white" />
          <path d="M108.395 141.879H107.704V144.45H108.395V141.879Z" fill="white" />
          <path d="M105.11 139.415L106.324 142.451H103.884L105.11 139.415Z" fill="white" />
          <path d="M105.454 141.879H104.764V144.45H105.454V141.879Z" fill="white" />
          <path
            d="M100.313 146.784H90.9678V137.463H100.313V146.784ZM91.6463 146.093H99.6222V138.117H91.6463V146.093Z"
            fill="white"
          />
          <path
            d="M98.0158 140.284V141.355C98.0158 141.523 97.9493 141.683 97.831 141.801C97.7127 141.92 97.5522 141.986 97.3849 141.986C97.2175 141.986 97.057 141.92 96.9387 141.801C96.8204 141.683 96.7539 141.523 96.7539 141.355V140.284H98.0158ZM98.7062 139.594H96.0635V141.355C96.0635 141.706 96.2027 142.042 96.4505 142.29C96.6983 142.538 97.0344 142.677 97.3849 142.677C97.7353 142.677 98.0714 142.538 98.3192 142.29C98.567 142.042 98.7062 141.706 98.7062 141.355V139.641V139.594Z"
            fill="white"
          />
          <path d="M97.7295 142.677H97.0391V144.665H97.7295V142.677Z" fill="white" />
          <path d="M98.7062 144.331H96.0635V145.022H98.7062V144.331Z" fill="white" />
          <path
            d="M93.8946 142.677C94.2451 142.677 94.5812 142.537 94.829 142.29C95.0768 142.042 95.216 141.706 95.216 141.355V139.641H92.5732V141.403C92.5856 141.745 92.7302 142.069 92.9767 142.307C93.2232 142.544 93.5523 142.677 93.8946 142.677Z"
            fill="white"
          />
          <path d="M94.2403 142.677H93.5498V144.665H94.2403V142.677Z" fill="white" />
          <path d="M95.216 144.331H92.5732V145.022H95.216V144.331Z" fill="white" />
          <path
            d="M89.4308 146.784H80.0264V137.463H89.4308V146.784ZM80.7644 146.093H88.7403V138.117H80.7049L80.7644 146.093Z"
            fill="white"
          />
          <path
            d="M85.3605 145.022C85.0921 145.022 84.8348 144.915 84.645 144.725C84.4552 144.536 84.3486 144.278 84.3486 144.01V139.641H85.0391V144.058C85.03 144.104 85.0313 144.152 85.043 144.198C85.0546 144.244 85.0763 144.287 85.1065 144.324C85.1366 144.36 85.1745 144.39 85.2174 144.41C85.2603 144.43 85.3071 144.441 85.3545 144.441C85.402 144.441 85.4488 144.43 85.4917 144.41C85.5346 144.39 85.5725 144.36 85.6026 144.324C85.6328 144.287 85.6545 144.244 85.6661 144.198C85.6778 144.152 85.6791 144.104 85.67 144.058V143.724H86.3605V144.058C86.3483 144.316 86.238 144.559 86.0521 144.738C85.8662 144.918 85.6187 145.019 85.3605 145.022Z"
            fill="white"
          />
          <path
            d="M82.3604 142.105H87.0506C87.0522 141.809 86.9951 141.516 86.8826 141.242C86.7701 140.969 86.6044 140.72 86.3952 140.511C86.1859 140.302 85.9373 140.136 85.6636 140.023C85.3899 139.911 85.0966 139.854 84.8007 139.855H84.6698C84.3733 139.854 84.0795 139.911 83.8051 140.023C83.5308 140.136 83.2813 140.301 83.0712 140.51C82.861 140.719 82.6942 140.968 82.5804 141.242C82.4666 141.515 82.408 141.809 82.408 142.105H82.3604Z"
            fill="white"
          />
          <path d="M130.669 88.5598L213.214 88.5598V25.3599L130.669 25.3599V88.5598Z" fill="#FED700" />
          <path d="M187.727 88.5598H213.214V25.3599H187.727V88.5598Z" fill="#EBC400" />
          <path d="M160.953 43.4425H181.345V32.2049H160.953V43.4425Z" fill="white" />
          <path
            d="M165.62 84.3219H156.239V74.9771H165.62V84.3219ZM156.93 83.691H164.906V75.7151H156.93V83.691Z"
            fill="white"
          />
          <path d="M163.607 82.1195H158.227V82.81H163.607V82.1195Z" fill="white" />
          <path d="M162.393 76.8341L163.607 79.8697H161.167L162.393 76.8341Z" fill="white" />
          <path d="M162.737 79.2864H162.047V81.8577H162.737V79.2864Z" fill="white" />
          <path d="M159.441 76.8341L160.667 79.8697H158.227L159.441 76.8341Z" fill="white" />
          <path d="M159.799 79.2864H159.108V81.8577H159.799V79.2864Z" fill="white" />
          <path
            d="M154.655 84.2267H145.298V74.8818H154.655V84.2267ZM145.988 83.5363H153.964V75.5604H145.988V83.5363Z"
            fill="white"
          />
          <path
            d="M152.358 77.7389V78.8102C152.337 78.9642 152.26 79.1053 152.143 79.2074C152.026 79.3095 151.876 79.3658 151.721 79.3658C151.565 79.3658 151.415 79.3095 151.298 79.2074C151.181 79.1053 151.105 78.9642 151.084 78.8102V77.7389H152.358ZM153.036 77.0484H150.405V78.8102C150.388 78.9935 150.409 79.1784 150.467 79.353C150.525 79.5277 150.619 79.6883 150.743 79.8245C150.867 79.9607 151.018 80.0695 151.186 80.144C151.355 80.2184 151.537 80.2569 151.721 80.2569C151.905 80.2569 152.087 80.2184 152.255 80.144C152.424 80.0695 152.575 79.9607 152.698 79.8245C152.822 79.6883 152.916 79.5277 152.974 79.353C153.033 79.1784 153.054 78.9935 153.036 78.8102V77.0127V77.0484Z"
            fill="white"
          />
          <path d="M152.071 80.0958H151.381V82.0838H152.071V80.0958Z" fill="white" />
          <path d="M153.048 81.7505H150.405V82.4409H153.048V81.7505Z" fill="white" />
          <path
            d="M148.241 80.1197C148.414 80.1197 148.585 80.0855 148.745 80.0189C148.904 79.9524 149.049 79.8548 149.171 79.732C149.293 79.6091 149.389 79.4634 149.454 79.3031C149.519 79.1429 149.552 78.9713 149.55 78.7983V77.0127H146.919V78.7745C146.915 78.9505 146.945 79.1256 147.01 79.2894C147.074 79.4532 147.171 79.6024 147.294 79.7279C147.418 79.8535 147.565 79.9529 147.728 80.0202C147.89 80.0875 148.065 80.1214 148.241 80.1197Z"
            fill="white"
          />
          <path d="M148.584 80.0958H147.894V82.0838H148.584V80.0958Z" fill="white" />
          <path d="M149.562 81.7505H146.919V82.4409H149.562V81.7505Z" fill="white" />
          <path
            d="M143.716 84.2267H134.359V74.8818H143.716V84.2267ZM135.05 83.5363H143.002V75.5604H135.026L135.05 83.5363Z"
            fill="white"
          />
          <path
            d="M139.692 82.4291C139.561 82.4291 139.431 82.4033 139.31 82.353C139.188 82.3028 139.078 82.2291 138.985 82.1363C138.892 82.0434 138.819 81.9332 138.769 81.8119C138.718 81.6905 138.692 81.5605 138.692 81.4292V77.0127H139.43V81.4292C139.421 81.4757 139.423 81.5237 139.434 81.5697C139.446 81.6157 139.468 81.6585 139.498 81.6951C139.528 81.7317 139.566 81.7612 139.609 81.7814C139.652 81.8017 139.698 81.8122 139.746 81.8122C139.793 81.8122 139.84 81.8017 139.883 81.7814C139.926 81.7612 139.964 81.7317 139.994 81.6951C140.024 81.6585 140.046 81.6157 140.057 81.5697C140.069 81.5237 140.07 81.4757 140.061 81.4292V81.0959H140.752V81.4292C140.752 81.5657 140.724 81.7008 140.67 81.8261C140.617 81.9515 140.537 82.0645 140.438 82.1582C140.339 82.2519 140.222 82.3242 140.093 82.3708C139.965 82.4174 139.829 82.4373 139.692 82.4291Z"
            fill="white"
          />
          <path
            d="M136.704 79.5244H141.371C141.371 79.229 141.312 78.9364 141.199 78.6634C141.086 78.3905 140.921 78.1424 140.712 77.9335C140.503 77.7246 140.255 77.5589 139.982 77.4458C139.709 77.3327 139.416 77.2745 139.121 77.2745H138.954C138.357 77.2745 137.785 77.5116 137.363 77.9335C136.941 78.3555 136.704 78.9277 136.704 79.5244Z"
            fill="white"
          />
        </>
      ) : null}
      {carVersion === 2 ? (
        <>
          <path
            d="M796.1 217.754C796.1 224.56 798.13 231.213 801.933 236.87C805.735 242.528 811.14 246.936 817.462 249.537C823.785 252.137 830.741 252.814 837.45 251.48C844.159 250.146 850.319 246.863 855.151 242.045C859.983 237.227 863.27 231.091 864.595 224.414C865.919 217.737 865.223 210.82 862.594 204.537C859.964 198.254 855.52 192.888 849.824 189.118C844.127 185.349 837.435 183.346 830.593 183.362C821.438 183.383 812.664 187.016 806.198 193.464C799.732 199.911 796.1 208.647 796.1 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M806.893 217.754C806.893 222.37 808.27 226.882 810.849 230.718C813.429 234.555 817.095 237.544 821.383 239.306C825.672 241.069 830.389 241.526 834.939 240.62C839.489 239.713 843.666 237.484 846.941 234.215C850.216 230.945 852.442 226.783 853.338 222.254C854.233 217.725 853.757 213.034 851.97 208.774C850.184 204.514 847.166 200.878 843.3 198.325C839.435 195.773 834.894 194.419 830.254 194.435C824.05 194.453 818.106 196.918 813.726 201.289C809.347 205.661 806.889 211.582 806.893 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M811.592 217.754C811.592 221.474 812.702 225.109 814.781 228.201C816.86 231.292 819.814 233.7 823.27 235.12C826.726 236.539 830.528 236.906 834.194 236.175C837.86 235.443 841.225 233.646 843.863 231.01C846.501 228.375 848.294 225.02 849.013 221.37C849.733 217.72 849.347 213.94 847.905 210.508C846.463 207.077 844.03 204.148 840.913 202.093C837.796 200.039 834.137 198.95 830.398 198.966C825.403 198.988 820.62 200.977 817.095 204.498C813.571 208.019 811.592 212.785 811.592 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M819.73 217.754C819.744 219.885 820.391 221.965 821.591 223.73C822.791 225.496 824.49 226.868 826.473 227.675C828.456 228.481 830.635 228.685 832.735 228.261C834.834 227.837 836.761 226.804 838.271 225.293C839.781 223.781 840.807 221.858 841.22 219.767C841.633 217.676 841.415 215.51 840.592 213.542C839.769 211.575 838.379 209.893 836.596 208.711C834.814 207.528 832.72 206.897 830.577 206.897C827.695 206.918 824.939 208.071 822.907 210.105C820.876 212.138 819.734 214.887 819.73 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M901.429 187.974C901.425 189.375 900.864 190.718 899.868 191.709C898.872 192.7 897.522 193.258 896.114 193.261H893.877V175.593H896.114C896.811 175.591 897.502 175.726 898.147 175.991C898.791 176.255 899.377 176.643 899.871 177.133C900.365 177.623 900.757 178.205 901.024 178.845C901.291 179.486 901.429 180.173 901.429 180.867V187.974Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M889.76 217.013C889.76 217.728 890.045 218.414 890.554 218.92C891.062 219.426 891.752 219.71 892.471 219.71H902.057C902.441 219.71 902.82 219.634 903.174 219.488C903.528 219.341 903.849 219.126 904.119 218.855C904.388 218.584 904.602 218.263 904.746 217.91C904.89 217.556 904.962 217.178 904.959 216.797V198.211C904.959 197.446 904.653 196.712 904.109 196.17C903.565 195.629 902.827 195.325 902.057 195.325H892.417C891.698 195.325 891.008 195.609 890.5 196.115C889.991 196.621 889.705 197.307 889.705 198.022L889.76 217.013Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M486.877 164.83H669.349V175.93H486.877V164.83Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M66.4445 164.115H334.023V175.93H66.4445V164.115Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M455.799 192.627H447.501C446.091 192.627 444.739 193.184 443.743 194.176C442.746 195.167 442.186 196.512 442.186 197.914V198.993C442.186 200.396 442.746 201.74 443.743 202.732C444.739 203.723 446.091 204.28 447.501 204.28H455.799V192.627Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M453.995 174.986H741.709V218.307H646.582V218.145C646.585 212.87 645.544 207.645 643.517 202.77C641.491 197.894 638.518 193.464 634.77 189.732C631.022 185.999 626.571 183.038 621.672 181.017C616.773 178.996 611.521 177.955 606.218 177.953C585.636 177.953 566.532 193.518 565.84 218.307H552.797C552.797 218.24 552.797 218.186 552.797 218.118C552.768 207.475 548.505 197.275 540.939 189.749C533.373 182.223 523.119 177.982 512.419 177.953C491.878 177.953 472.747 193.45 472.042 218.415H453.968C453.269 218.415 452.577 218.278 451.931 218.011C451.286 217.745 450.699 217.354 450.205 216.862C449.712 216.369 449.32 215.785 449.054 215.142C448.788 214.499 448.652 213.81 448.653 213.115V180.273C448.653 179.576 448.792 178.887 449.061 178.244C449.329 177.6 449.723 177.016 450.22 176.525C450.716 176.034 451.305 175.645 451.953 175.381C452.601 175.117 453.295 174.982 453.995 174.986Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M822.892 75.1801H748.807V45.0495C748.807 44.3752 748.947 43.7081 749.219 43.0904C749.491 42.4726 749.888 41.9175 750.386 41.4601C750.884 41.0026 751.472 40.6528 752.113 40.4326C752.753 40.2124 753.433 40.1266 754.109 40.1806L783.938 44.0245C787.346 44.2901 790.528 45.8269 792.846 48.3269L822.892 75.1801Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M791.893 206.911H792.124C791.045 210.581 790.515 214.389 790.551 218.213H741.74V80.5751C741.744 79.174 742.305 77.8313 743.301 76.8405C744.297 75.8498 745.646 75.2916 747.055 75.2881H866.221C867.777 75.2888 869.294 75.769 870.564 76.6627C871.834 77.5564 872.794 78.8195 873.313 80.2784L890.302 128.077L894.369 138.152V219.265H872.038C872.017 214.87 871.562 210.489 870.682 206.182C866.696 190.321 849.26 177.859 830.942 177.859C822.185 177.852 813.663 180.682 806.667 185.922C799.671 191.161 794.58 198.524 792.164 206.897L791.893 206.911Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.49 97.2453C798.49 95.8431 799.05 94.4983 800.047 93.5068C801.043 92.5153 802.395 91.9583 803.805 91.9583H857.09C858.647 91.9565 860.168 92.432 861.444 93.32C862.72 94.2081 863.69 95.4656 864.222 96.9216L874.716 125.461C874.931 126.026 875.003 126.635 874.927 127.234C874.851 127.833 874.629 128.405 874.28 128.9C873.931 129.394 873.466 129.796 872.925 130.071C872.385 130.346 871.785 130.486 871.177 130.478H803.805C802.396 130.474 801.046 129.916 800.05 128.925C799.055 127.935 798.493 126.592 798.49 125.191V97.2453Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.316 139.676C798.316 140.832 798.778 141.94 799.599 142.757C800.42 143.574 801.534 144.033 802.696 144.033H811.455C812.616 144.033 813.73 143.574 814.551 142.757C815.373 141.94 815.834 140.832 815.834 139.676C815.834 138.521 815.373 137.413 814.551 136.596C813.73 135.779 812.616 135.32 811.455 135.32H802.696C801.534 135.32 800.42 135.779 799.599 136.596C798.778 137.413 798.316 138.521 798.316 139.676Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M878.646 200.882H880.178C880.999 200.882 881.812 200.721 882.57 200.408C883.329 200.096 884.018 199.638 884.598 199.061C885.178 198.483 885.639 197.798 885.953 197.043C886.267 196.289 886.429 195.481 886.429 194.664V136.372L870.701 91.5267C870.114 89.8988 869.035 88.4911 867.613 87.4964C866.191 86.5016 864.494 85.9685 862.755 85.97H796.996C795.337 85.9735 793.747 86.6316 792.576 87.7998C791.404 88.968 790.746 90.5508 790.746 92.2011V164.682C790.753 166.336 791.419 167.919 792.599 169.085C793.778 170.25 795.374 170.903 797.037 170.899H832.045C848.776 170.899 865.128 181.231 870.931 195.473C871.533 197.034 872.588 198.382 873.962 199.345C875.335 200.308 876.966 200.843 878.646 200.882Z"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M755.414 91.9583H776.986C779.921 91.9583 782.301 94.3253 782.301 97.2453V125.15C782.301 128.07 779.921 130.437 776.986 130.437H755.414C752.479 130.437 750.099 128.07 750.099 125.15V97.2453C750.099 94.3253 752.479 91.9583 755.414 91.9583Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M6.65393 1H723.701C726.823 1 729.354 3.51804 729.354 6.6242V159.934C729.354 163.04 726.823 165.558 723.701 165.558H6.65393C3.53131 165.558 1 163.04 1 159.934V6.6242C1 3.51804 3.53131 1 6.65393 1Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M14.4003 8.66077H717.17C720 8.66077 722.295 10.9433 722.295 13.759V152.799C722.295 155.615 720 157.898 717.17 157.898H14.4003C11.5698 157.898 9.27515 155.615 9.27515 152.799V13.759C9.27515 10.9433 11.5698 8.66077 14.4003 8.66077Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M61.3516 192.385H53.0537C52.3552 192.383 51.6632 192.518 51.0176 192.784C50.3719 193.049 49.7853 193.438 49.2914 193.929C48.7975 194.421 48.4061 195.004 48.1396 195.646C47.8732 196.289 47.7369 196.977 47.7387 197.672V198.751C47.7369 199.445 47.8732 200.134 48.1396 200.776C48.4061 201.418 48.7975 202.002 49.2914 202.493C49.7853 202.984 50.3719 203.374 51.0176 203.639C51.6632 203.904 52.3552 204.039 53.0537 204.038H61.3516V192.385Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M59.7658 174.743H354.299C354.998 174.742 355.69 174.877 356.335 175.142C356.981 175.407 357.568 175.797 358.062 176.288C358.555 176.779 358.947 177.363 359.213 178.005C359.48 178.647 359.616 179.336 359.614 180.03V212.751C359.616 213.446 359.48 214.135 359.214 214.778C358.947 215.421 358.556 216.005 358.062 216.498C357.569 216.99 356.982 217.381 356.336 217.647C355.691 217.914 354.998 218.051 354.299 218.051H345.988V217.862C345.99 212.588 344.947 207.364 342.919 202.491C340.892 197.617 337.919 193.188 334.171 189.457C330.423 185.726 325.973 182.766 321.075 180.746C316.176 178.726 310.926 177.685 305.624 177.684C285.042 177.684 265.938 193.248 265.246 218.038H252.217C252.217 182.809 211.107 165.572 185.63 187.286C177.292 194.367 171.828 205.036 171.462 218.078H164.953H158.459C158.478 212.786 157.449 207.542 155.428 202.646C153.408 197.751 150.437 193.3 146.686 189.548C142.934 185.796 138.475 182.816 133.564 180.781C128.654 178.745 123.388 177.692 118.068 177.684C97.5265 177.684 78.3953 193.18 77.6903 218.078H59.6166C58.9175 218.08 58.2249 217.944 57.5786 217.679C56.9321 217.415 56.3446 217.025 55.8496 216.534C55.3546 216.043 54.9619 215.46 54.6939 214.817C54.426 214.175 54.2881 213.486 54.2881 212.791V180.03C54.3166 178.609 54.9081 177.256 55.9338 176.266C56.9595 175.276 58.3366 174.729 59.7658 174.743Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M664.453 183.874H726.687C729.622 183.874 732.002 186.241 732.002 189.161V226.076C732.002 228.996 729.622 231.363 726.687 231.363H664.453C661.518 231.363 659.138 228.996 659.138 226.076V189.161C659.138 186.241 661.518 183.874 664.453 183.874Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M674.259 231.983C674.259 232.849 674.605 233.679 675.22 234.291C675.835 234.903 676.67 235.247 677.54 235.247C678.41 235.247 679.245 234.903 679.86 234.291C680.476 233.679 680.821 232.849 680.821 231.983V183.942C680.821 183.076 680.476 182.246 679.86 181.634C679.245 181.022 678.41 180.678 677.54 180.678C676.67 180.678 675.835 181.022 675.22 181.634C674.605 182.246 674.259 183.076 674.259 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M692.789 231.983C692.789 232.412 692.874 232.836 693.039 233.232C693.204 233.628 693.446 233.988 693.75 234.291C694.055 234.594 694.417 234.835 694.815 234.999C695.213 235.163 695.639 235.247 696.07 235.247C696.501 235.247 696.928 235.163 697.326 234.999C697.724 234.835 698.086 234.594 698.391 234.291C698.695 233.988 698.937 233.628 699.102 233.232C699.267 232.836 699.352 232.412 699.352 231.983V183.942C699.352 183.513 699.267 183.089 699.102 182.693C698.937 182.297 698.695 181.937 698.391 181.634C698.086 181.331 697.724 181.09 697.326 180.926C696.928 180.762 696.501 180.678 696.07 180.678C695.639 180.678 695.213 180.762 694.815 180.926C694.417 181.09 694.055 181.331 693.75 181.634C693.446 181.937 693.204 182.297 693.039 182.693C692.874 183.089 692.789 183.513 692.789 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M711.321 231.983C711.321 232.849 711.667 233.679 712.282 234.291C712.898 234.903 713.732 235.247 714.603 235.247C715.473 235.247 716.307 234.903 716.923 234.291C717.538 233.679 717.884 232.849 717.884 231.983V183.942C717.884 183.076 717.538 182.246 716.923 181.634C716.307 181.022 715.473 180.678 714.603 180.678C713.732 180.678 712.898 181.022 712.282 181.634C711.667 182.246 711.321 183.076 711.321 183.942V231.983Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M571.962 217.754C571.962 224.557 573.99 231.206 577.789 236.862C581.588 242.518 586.988 246.926 593.306 249.529C599.623 252.132 606.575 252.813 613.282 251.486C619.989 250.159 626.149 246.883 630.985 242.074C635.82 237.264 639.113 231.135 640.447 224.464C641.781 217.792 641.096 210.877 638.479 204.593C635.863 198.308 631.431 192.937 625.745 189.158C620.06 185.379 613.375 183.362 606.537 183.362C597.367 183.362 588.573 186.985 582.089 193.435C575.605 199.885 571.962 208.633 571.962 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M582.753 217.754C582.753 222.366 584.128 226.875 586.704 230.71C589.28 234.545 592.941 237.534 597.225 239.299C601.508 241.064 606.222 241.525 610.769 240.626C615.317 239.726 619.494 237.505 622.772 234.244C626.051 230.982 628.284 226.827 629.188 222.304C630.093 217.78 629.629 213.091 627.854 208.83C626.08 204.569 623.075 200.927 619.22 198.365C615.365 195.802 610.832 194.435 606.196 194.435C599.978 194.435 594.016 196.892 589.619 201.265C585.223 205.638 582.753 211.57 582.753 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M587.452 217.754C587.452 221.47 588.56 225.103 590.635 228.192C592.711 231.282 595.66 233.69 599.112 235.112C602.563 236.534 606.36 236.906 610.024 236.181C613.688 235.456 617.053 233.667 619.695 231.039C622.336 228.412 624.135 225.064 624.864 221.42C625.592 217.775 625.218 213.998 623.789 210.565C622.359 207.132 619.939 204.197 616.833 202.133C613.727 200.068 610.075 198.967 606.339 198.967C603.859 198.967 601.403 199.453 599.112 200.397C596.82 201.341 594.738 202.725 592.984 204.469C591.23 206.214 589.839 208.285 588.89 210.565C587.941 212.844 587.452 215.287 587.452 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M595.593 217.754C595.606 219.882 596.252 221.959 597.449 223.723C598.646 225.487 600.341 226.86 602.32 227.668C604.299 228.476 606.474 228.684 608.572 228.266C610.67 227.847 612.596 226.821 614.109 225.316C615.622 223.811 616.654 221.895 617.074 219.808C617.495 217.721 617.286 215.558 616.473 213.589C615.661 211.62 614.281 209.934 612.508 208.743C610.734 207.553 608.647 206.91 606.507 206.897C605.074 206.897 603.655 207.178 602.33 207.723C601.006 208.269 599.803 209.069 598.789 210.077C597.776 211.085 596.972 212.282 596.423 213.599C595.875 214.917 595.593 216.328 595.593 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M477.543 217.754C477.543 224.557 479.57 231.206 483.369 236.862C487.168 242.518 492.568 246.926 498.886 249.529C505.204 252.132 512.155 252.813 518.862 251.486C525.569 250.159 531.729 246.883 536.565 242.074C541.4 237.264 544.693 231.135 546.027 224.464C547.361 217.792 546.676 210.877 544.06 204.593C541.443 198.308 537.011 192.937 531.325 189.158C525.64 185.379 518.955 183.362 512.117 183.362C502.947 183.362 494.153 186.985 487.669 193.435C481.185 199.885 477.543 208.633 477.543 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M488.333 217.754C488.333 222.367 489.708 226.876 492.285 230.711C494.861 234.546 498.523 237.535 502.808 239.3C507.092 241.065 511.807 241.526 516.354 240.625C520.902 239.724 525.079 237.502 528.357 234.239C531.635 230.976 533.867 226.82 534.77 222.295C535.673 217.771 535.207 213.082 533.43 208.821C531.654 204.56 528.647 200.919 524.79 198.358C520.933 195.797 516.399 194.432 511.762 194.435C505.547 194.438 499.588 196.897 495.195 201.27C490.801 205.642 488.333 211.572 488.333 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M493.032 217.754C493.032 221.471 494.14 225.104 496.216 228.194C498.292 231.284 501.243 233.692 504.695 235.113C508.147 236.535 511.945 236.906 515.609 236.18C519.273 235.454 522.639 233.663 525.28 231.035C527.92 228.406 529.718 225.057 530.445 221.411C531.173 217.766 530.797 213.988 529.365 210.555C527.933 207.122 525.511 204.189 522.403 202.126C519.295 200.064 515.642 198.964 511.906 198.967C506.899 198.97 502.099 200.951 498.559 204.474C495.02 207.997 493.032 212.774 493.032 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M501.035 217.754C501.035 219.902 501.675 222.001 502.874 223.786C504.074 225.572 505.778 226.963 507.773 227.785C509.767 228.607 511.962 228.822 514.079 228.403C516.196 227.984 518.141 226.95 519.667 225.431C521.194 223.913 522.233 221.978 522.655 219.872C523.076 217.766 522.86 215.583 522.033 213.599C521.207 211.615 519.808 209.92 518.013 208.727C516.219 207.534 514.108 206.897 511.95 206.897C509.055 206.897 506.279 208.041 504.232 210.077C502.185 212.113 501.035 214.875 501.035 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M271.052 217.754C271.052 224.557 273.08 231.207 276.88 236.863C280.68 242.519 286.08 246.927 292.399 249.53C298.717 252.133 305.67 252.813 312.377 251.485C319.084 250.157 325.245 246.88 330.079 242.069C334.914 237.258 338.206 231.128 339.538 224.456C340.871 217.783 340.184 210.868 337.565 204.583C334.946 198.299 330.513 192.929 324.825 189.151C319.138 185.374 312.452 183.359 305.613 183.362C296.446 183.365 287.655 186.99 281.174 193.44C274.693 199.889 271.052 208.635 271.052 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M282.726 217.754C282.726 222.367 284.101 226.876 286.677 230.711C289.254 234.546 292.916 237.535 297.2 239.3C301.485 241.065 306.199 241.526 310.747 240.625C315.295 239.724 319.472 237.502 322.75 234.239C326.028 230.976 328.26 226.82 329.163 222.295C330.066 217.771 329.599 213.082 327.823 208.821C326.047 204.56 323.04 200.919 319.183 198.358C315.326 195.797 310.792 194.432 306.155 194.435C303.077 194.435 300.029 195.038 297.186 196.21C294.342 197.382 291.759 199.1 289.583 201.266C287.407 203.431 285.682 206.002 284.505 208.831C283.329 211.66 282.724 214.693 282.726 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M287.425 217.754C287.425 221.471 288.533 225.104 290.609 228.194C292.685 231.284 295.635 233.692 299.087 235.113C302.539 236.535 306.338 236.906 310.002 236.18C313.666 235.454 317.031 233.663 319.672 231.035C322.313 228.406 324.111 225.057 324.838 221.411C325.565 217.766 325.189 213.988 323.758 210.555C322.326 207.122 319.903 204.189 316.795 202.126C313.688 200.064 310.035 198.964 306.299 198.967C301.292 198.97 296.491 200.951 292.952 204.474C289.413 207.997 287.425 212.774 287.425 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M295.428 217.754C295.428 219.905 296.07 222.006 297.273 223.793C298.475 225.58 300.184 226.972 302.182 227.791C304.18 228.611 306.378 228.822 308.498 228.398C310.617 227.973 312.561 226.933 314.085 225.407C315.609 223.882 316.643 221.941 317.056 219.831C317.47 217.72 317.244 215.535 316.407 213.552C315.571 211.57 314.161 209.879 312.358 208.694C310.554 207.509 308.437 206.884 306.275 206.897C304.845 206.897 303.429 207.178 302.109 207.725C300.788 208.271 299.589 209.072 298.581 210.081C297.573 211.09 296.776 212.288 296.235 213.605C295.694 214.921 295.42 216.332 295.428 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M177.514 217.754C177.514 224.557 179.542 231.207 183.342 236.863C187.142 242.519 192.542 246.927 198.861 249.53C205.179 252.133 212.132 252.813 218.839 251.485C225.546 250.157 231.706 246.88 236.541 242.069C241.376 237.258 244.668 231.128 246 224.456C247.333 217.783 246.646 210.868 244.027 204.583C241.408 198.299 236.975 192.929 231.287 189.151C225.6 185.374 218.914 183.359 212.075 183.362C202.908 183.365 194.117 186.99 187.636 193.44C181.155 199.889 177.514 208.635 177.514 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M188.306 217.754C188.306 222.367 189.681 226.876 192.258 230.711C194.834 234.546 198.496 237.535 202.781 239.3C207.065 241.065 211.779 241.526 216.327 240.625C220.875 239.724 225.052 237.502 228.33 234.239C231.608 230.976 233.84 226.82 234.743 222.295C235.646 217.771 235.179 213.082 233.403 208.821C231.627 204.56 228.62 200.919 224.763 198.358C220.906 195.797 216.372 194.432 211.735 194.435C208.657 194.435 205.609 195.038 202.766 196.21C199.922 197.382 197.339 199.1 195.163 201.266C192.987 203.431 191.262 206.002 190.085 208.831C188.909 211.66 188.304 214.693 188.306 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M193.004 217.754C193.004 221.471 194.112 225.104 196.188 228.194C198.264 231.284 201.215 233.692 204.667 235.113C208.119 236.535 211.917 236.906 215.581 236.18C219.245 235.454 222.61 233.663 225.251 231.035C227.892 228.406 229.69 225.057 230.417 221.411C231.144 217.766 230.768 213.988 229.337 210.555C227.905 207.122 225.482 204.189 222.374 202.126C219.267 200.064 215.614 198.964 211.878 198.967C206.871 198.97 202.07 200.951 198.531 204.474C194.992 207.997 193.004 212.774 193.004 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M201.143 217.754C201.157 219.885 201.804 221.965 203.004 223.73C204.204 225.496 205.903 226.868 207.886 227.675C209.869 228.481 212.048 228.685 214.148 228.261C216.247 227.837 218.174 226.804 219.684 225.293C221.194 223.781 222.22 221.858 222.633 219.767C223.046 217.676 222.828 215.51 222.005 213.542C221.182 211.575 219.792 209.893 218.009 208.711C216.227 207.528 214.133 206.897 211.99 206.897C209.108 206.918 206.352 208.071 204.32 210.105C202.289 212.138 201.147 214.887 201.143 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M83.0924 217.754C83.0924 224.557 85.1201 231.206 88.9192 236.862C92.7183 242.518 98.1181 246.926 104.436 249.529C110.753 252.132 117.705 252.813 124.412 251.486C131.119 250.159 137.279 246.883 142.115 242.074C146.95 237.264 150.243 231.135 151.577 224.464C152.911 217.792 152.226 210.877 149.609 204.593C146.993 198.308 142.561 192.937 136.875 189.158C131.19 185.379 124.505 183.362 117.667 183.362C108.497 183.362 99.7029 186.985 93.2189 193.435C86.735 199.885 83.0924 208.633 83.0924 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M94.7677 217.754C94.7677 222.366 96.1426 226.875 98.7185 230.71C101.294 234.545 104.956 237.534 109.239 239.299C113.523 241.064 118.237 241.525 122.784 240.626C127.331 239.726 131.509 237.505 134.787 234.244C138.066 230.982 140.298 226.827 141.203 222.304C142.107 217.78 141.643 213.091 139.869 208.83C138.095 204.569 135.09 200.927 131.235 198.365C127.38 195.802 122.847 194.435 118.211 194.435C111.993 194.435 106.03 196.892 101.634 201.265C97.2375 205.638 94.7677 211.57 94.7677 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M98.5841 217.754C98.5841 221.47 99.6919 225.103 101.767 228.192C103.843 231.282 106.792 233.69 110.243 235.112C113.695 236.534 117.492 236.906 121.156 236.181C124.82 235.456 128.185 233.667 130.827 231.039C133.468 228.412 135.267 225.064 135.995 221.42C136.724 217.775 136.35 213.998 134.921 210.565C133.491 207.132 131.07 204.197 127.964 202.133C124.858 200.068 121.207 198.967 117.471 198.967C114.991 198.967 112.535 199.453 110.243 200.397C107.952 201.341 105.87 202.725 104.116 204.469C102.362 206.214 100.971 208.285 100.022 210.565C99.0727 212.844 98.5841 215.287 98.5841 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M106.722 217.754C106.736 219.885 107.383 221.965 108.583 223.73C109.783 225.496 111.482 226.868 113.465 227.675C115.448 228.481 117.627 228.685 119.727 228.261C121.826 227.837 123.753 226.804 125.263 225.293C126.773 223.781 127.799 221.858 128.212 219.767C128.626 217.676 128.407 215.51 127.584 213.542C126.761 211.575 125.371 209.893 123.588 208.711C121.806 207.528 119.712 206.897 117.569 206.897C114.686 206.915 111.928 208.067 109.895 210.101C107.863 212.135 106.722 214.886 106.722 217.754Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path d="M159.25 151.141H241.794V87.9407H159.25V151.141Z" fill="#FED700" />
          <path d="M216.307 151.141H241.794V87.9407H216.307V151.141Z" fill="#EBC400" />
          <path d="M189.536 106.035H209.928V94.7977H189.536V106.035Z" fill="white" />
          <path
            d="M194.19 146.903H184.821V137.558H194.19V146.903ZM185.524 146.212H193.5V138.236H185.524V146.212Z"
            fill="white"
          />
          <path d="M192.189 144.7H186.809V145.391H192.189V144.7Z" fill="white" />
          <path d="M190.975 139.415L192.189 142.451H189.749L190.975 139.415Z" fill="white" />
          <path d="M191.32 141.879H190.63V144.45H191.32V141.879Z" fill="white" />
          <path d="M188.023 139.415L189.249 142.451H186.809L188.023 139.415Z" fill="white" />
          <path d="M188.381 141.879H187.69V144.45H188.381V141.879Z" fill="white" />
          <path
            d="M183.239 146.784H173.882V137.463H183.239V146.784ZM174.572 146.093H182.548V138.117H174.572V146.093Z"
            fill="white"
          />
          <path
            d="M180.928 140.284V141.355C180.929 141.439 180.914 141.523 180.883 141.6C180.852 141.678 180.806 141.749 180.747 141.809C180.689 141.869 180.618 141.917 180.541 141.949C180.464 141.981 180.381 141.998 180.297 141.998C180.128 141.995 179.968 141.926 179.85 141.806C179.732 141.686 179.666 141.524 179.666 141.355V140.284H180.928ZM181.618 139.594H178.987V141.355C178.97 141.539 178.991 141.723 179.049 141.898C179.107 142.073 179.201 142.233 179.325 142.37C179.449 142.506 179.6 142.615 179.768 142.689C179.937 142.764 180.119 142.802 180.303 142.802C180.487 142.802 180.669 142.764 180.837 142.689C181.006 142.615 181.157 142.506 181.28 142.37C181.404 142.233 181.498 142.073 181.556 141.898C181.615 141.723 181.636 141.539 181.618 141.355V139.641V139.594Z"
            fill="white"
          />
          <path d="M180.654 142.677H179.964V144.665H180.654V142.677Z" fill="white" />
          <path d="M181.632 144.331H178.989V145.022H181.632V144.331Z" fill="white" />
          <path
            d="M176.808 142.677C176.982 142.678 177.155 142.645 177.316 142.579C177.477 142.514 177.623 142.416 177.746 142.293C177.869 142.17 177.967 142.024 178.033 141.863C178.098 141.702 178.131 141.529 178.13 141.355V139.641H175.499V141.403C175.508 141.744 175.65 142.068 175.895 142.306C176.14 142.544 176.467 142.677 176.808 142.677Z"
            fill="white"
          />
          <path d="M177.167 142.677H176.477V144.665H177.167V142.677Z" fill="white" />
          <path d="M178.142 144.331H175.499V145.022H178.142V144.331Z" fill="white" />
          <path
            d="M172.285 146.784H162.94V137.463H172.285V146.784ZM163.631 146.093H171.571V138.117H163.595L163.631 146.093Z"
            fill="white"
          />
          <path
            d="M168.272 145.022C168.006 145.019 167.752 144.911 167.565 144.721C167.377 144.532 167.272 144.276 167.272 144.01V139.641H167.999V144.058C167.99 144.104 167.991 144.152 168.003 144.198C168.014 144.244 168.036 144.287 168.066 144.324C168.096 144.36 168.134 144.39 168.177 144.41C168.22 144.43 168.267 144.441 168.314 144.441C168.362 144.441 168.408 144.43 168.451 144.41C168.494 144.39 168.532 144.36 168.562 144.324C168.592 144.287 168.614 144.244 168.626 144.198C168.637 144.152 168.639 144.104 168.63 144.058V143.724H169.32V144.058C169.308 144.324 169.191 144.575 168.995 144.755C168.798 144.936 168.539 145.032 168.272 145.022Z"
            fill="white"
          />
          <path
            d="M165.286 142.105H169.953C169.953 141.509 169.716 140.936 169.294 140.514C168.872 140.092 168.299 139.855 167.703 139.855H167.536C166.939 139.855 166.367 140.092 165.945 140.514C165.523 140.936 165.286 141.509 165.286 142.105Z"
            fill="white"
          />
          <path d="M76.3355 151.141H158.88V87.9407H76.3355L76.3355 151.141Z" fill="#FED700" />
          <path d="M133.393 151.141H158.88V87.9407H133.393V151.141Z" fill="#EBC400" />
          <path d="M106.609 106.035H127.001V94.7977H106.609V106.035Z" fill="white" />
          <path
            d="M111.253 146.903H101.908V137.558H111.253V146.903ZM102.587 146.212H110.563V138.236H102.587V146.212Z"
            fill="white"
          />
          <path d="M109.265 144.7H103.884V145.391H109.265V144.7Z" fill="white" />
          <path d="M108.049 139.415L109.263 142.451H106.835L108.049 139.415Z" fill="white" />
          <path d="M108.395 141.879H107.704V144.45H108.395V141.879Z" fill="white" />
          <path d="M105.11 139.415L106.324 142.451H103.884L105.11 139.415Z" fill="white" />
          <path d="M105.454 141.879H104.764V144.45H105.454V141.879Z" fill="white" />
          <path
            d="M100.313 146.784H90.9678V137.463H100.313V146.784ZM91.6463 146.093H99.6222V138.117H91.6463V146.093Z"
            fill="white"
          />
          <path
            d="M98.0158 140.284V141.355C98.0158 141.523 97.9493 141.683 97.831 141.801C97.7127 141.92 97.5522 141.986 97.3849 141.986C97.2175 141.986 97.057 141.92 96.9387 141.801C96.8204 141.683 96.7539 141.523 96.7539 141.355V140.284H98.0158ZM98.7062 139.594H96.0635V141.355C96.0635 141.706 96.2027 142.042 96.4505 142.29C96.6983 142.538 97.0344 142.677 97.3849 142.677C97.7353 142.677 98.0714 142.538 98.3192 142.29C98.567 142.042 98.7062 141.706 98.7062 141.355V139.641V139.594Z"
            fill="white"
          />
          <path d="M97.7295 142.677H97.0391V144.665H97.7295V142.677Z" fill="white" />
          <path d="M98.7062 144.331H96.0635V145.022H98.7062V144.331Z" fill="white" />
          <path
            d="M93.8946 142.677C94.2451 142.677 94.5812 142.537 94.829 142.29C95.0768 142.042 95.216 141.706 95.216 141.355V139.641H92.5732V141.403C92.5856 141.745 92.7302 142.069 92.9767 142.307C93.2232 142.544 93.5523 142.677 93.8946 142.677Z"
            fill="white"
          />
          <path d="M94.2403 142.677H93.5498V144.665H94.2403V142.677Z" fill="white" />
          <path d="M95.216 144.331H92.5732V145.022H95.216V144.331Z" fill="white" />
          <path
            d="M89.4308 146.784H80.0264V137.463H89.4308V146.784ZM80.7644 146.093H88.7403V138.117H80.7049L80.7644 146.093Z"
            fill="white"
          />
          <path
            d="M85.3605 145.022C85.0921 145.022 84.8348 144.915 84.645 144.725C84.4552 144.536 84.3486 144.278 84.3486 144.01V139.641H85.0391V144.058C85.03 144.104 85.0313 144.152 85.043 144.198C85.0546 144.244 85.0763 144.287 85.1065 144.324C85.1366 144.36 85.1745 144.39 85.2174 144.41C85.2603 144.43 85.3071 144.441 85.3545 144.441C85.402 144.441 85.4488 144.43 85.4917 144.41C85.5346 144.39 85.5725 144.36 85.6026 144.324C85.6328 144.287 85.6545 144.244 85.6661 144.198C85.6778 144.152 85.6791 144.104 85.67 144.058V143.724H86.3605V144.058C86.3483 144.316 86.238 144.559 86.0521 144.738C85.8662 144.918 85.6187 145.019 85.3605 145.022Z"
            fill="white"
          />
          <path
            d="M82.3604 142.105H87.0506C87.0522 141.809 86.9951 141.516 86.8826 141.242C86.7701 140.969 86.6044 140.72 86.3952 140.511C86.1859 140.302 85.9373 140.136 85.6636 140.023C85.3899 139.911 85.0966 139.854 84.8007 139.855H84.6698C84.3733 139.854 84.0795 139.911 83.8051 140.023C83.5308 140.136 83.2813 140.301 83.0712 140.51C82.861 140.719 82.6942 140.968 82.5804 141.242C82.4666 141.515 82.408 141.809 82.408 142.105H82.3604Z"
            fill="white"
          />
          <path d="M130.669 88.5598L213.214 88.5598V25.3599L130.669 25.3599V88.5598Z" fill="#FED700" />
          <path d="M187.727 88.5598H213.214V25.3599H187.727V88.5598Z" fill="#EBC400" />
          <path d="M160.953 43.4425H181.345V32.2049H160.953V43.4425Z" fill="white" />
          <path
            d="M165.62 84.3219H156.239V74.9771H165.62V84.3219ZM156.93 83.691H164.906V75.7151H156.93V83.691Z"
            fill="white"
          />
          <path d="M163.607 82.1195H158.227V82.81H163.607V82.1195Z" fill="white" />
          <path d="M162.393 76.8341L163.607 79.8697H161.167L162.393 76.8341Z" fill="white" />
          <path d="M162.737 79.2864H162.047V81.8577H162.737V79.2864Z" fill="white" />
          <path d="M159.441 76.8341L160.667 79.8697H158.227L159.441 76.8341Z" fill="white" />
          <path d="M159.799 79.2864H159.108V81.8577H159.799V79.2864Z" fill="white" />
          <path
            d="M154.655 84.2267H145.298V74.8818H154.655V84.2267ZM145.988 83.5363H153.964V75.5604H145.988V83.5363Z"
            fill="white"
          />
          <path
            d="M152.358 77.7389V78.8102C152.337 78.9642 152.26 79.1053 152.143 79.2074C152.026 79.3095 151.876 79.3658 151.721 79.3658C151.565 79.3658 151.415 79.3095 151.298 79.2074C151.181 79.1053 151.105 78.9642 151.084 78.8102V77.7389H152.358ZM153.036 77.0484H150.405V78.8102C150.388 78.9935 150.409 79.1784 150.467 79.353C150.525 79.5277 150.619 79.6883 150.743 79.8245C150.867 79.9607 151.018 80.0695 151.186 80.144C151.355 80.2184 151.537 80.2569 151.721 80.2569C151.905 80.2569 152.087 80.2184 152.255 80.144C152.424 80.0695 152.575 79.9607 152.698 79.8245C152.822 79.6883 152.916 79.5277 152.974 79.353C153.033 79.1784 153.054 78.9935 153.036 78.8102V77.0127V77.0484Z"
            fill="white"
          />
          <path d="M152.071 80.0958H151.381V82.0838H152.071V80.0958Z" fill="white" />
          <path d="M153.048 81.7505H150.405V82.4409H153.048V81.7505Z" fill="white" />
          <path
            d="M148.241 80.1197C148.414 80.1197 148.585 80.0855 148.745 80.0189C148.904 79.9524 149.049 79.8548 149.171 79.732C149.293 79.6091 149.389 79.4634 149.454 79.3031C149.519 79.1429 149.552 78.9713 149.55 78.7983V77.0127H146.919V78.7745C146.915 78.9505 146.945 79.1256 147.01 79.2894C147.074 79.4532 147.171 79.6024 147.294 79.7279C147.418 79.8535 147.565 79.9529 147.728 80.0202C147.89 80.0875 148.065 80.1214 148.241 80.1197Z"
            fill="white"
          />
          <path d="M148.584 80.0958H147.894V82.0838H148.584V80.0958Z" fill="white" />
          <path d="M149.562 81.7505H146.919V82.4409H149.562V81.7505Z" fill="white" />
          <path
            d="M143.716 84.2267H134.359V74.8818H143.716V84.2267ZM135.05 83.5363H143.002V75.5604H135.026L135.05 83.5363Z"
            fill="white"
          />
          <path
            d="M139.692 82.4291C139.561 82.4291 139.431 82.4033 139.31 82.353C139.188 82.3028 139.078 82.2291 138.985 82.1363C138.892 82.0434 138.819 81.9332 138.769 81.8119C138.718 81.6905 138.692 81.5605 138.692 81.4292V77.0127H139.43V81.4292C139.421 81.4757 139.423 81.5237 139.434 81.5697C139.446 81.6157 139.468 81.6585 139.498 81.6951C139.528 81.7317 139.566 81.7612 139.609 81.7814C139.652 81.8017 139.698 81.8122 139.746 81.8122C139.793 81.8122 139.84 81.8017 139.883 81.7814C139.926 81.7612 139.964 81.7317 139.994 81.6951C140.024 81.6585 140.046 81.6157 140.057 81.5697C140.069 81.5237 140.07 81.4757 140.061 81.4292V81.0959H140.752V81.4292C140.752 81.5657 140.724 81.7008 140.67 81.8261C140.617 81.9515 140.537 82.0645 140.438 82.1582C140.339 82.2519 140.222 82.3242 140.093 82.3708C139.965 82.4174 139.829 82.4373 139.692 82.4291Z"
            fill="white"
          />
          <path
            d="M136.704 79.5244H141.371C141.371 79.229 141.312 78.9364 141.199 78.6634C141.086 78.3905 140.921 78.1424 140.712 77.9335C140.503 77.7246 140.255 77.5589 139.982 77.4458C139.709 77.3327 139.416 77.2745 139.121 77.2745H138.954C138.357 77.2745 137.785 77.5116 137.363 77.9335C136.941 78.3555 136.704 78.9277 136.704 79.5244Z"
            fill="white"
          />
          <path d="M353.914 150.781H436.458V87.5809H353.914V150.781Z" fill="#FED700" />
          <path d="M410.971 150.781H436.458V87.5809H410.971V150.781Z" fill="#EBC400" />
          <path d="M384.2 105.676H404.592V94.4379H384.2V105.676Z" fill="white" />
          <path
            d="M388.854 146.543H379.485V137.198H388.854V146.543ZM380.188 145.852H388.164V137.877H380.188V145.852Z"
            fill="white"
          />
          <path d="M386.853 144.341H381.473V145.031H386.853V144.341Z" fill="white" />
          <path d="M385.639 139.055L386.853 142.091H384.413L385.639 139.055Z" fill="white" />
          <path d="M385.984 141.519H385.294V144.091H385.984V141.519Z" fill="white" />
          <path d="M382.687 139.055L383.913 142.091H381.473L382.687 139.055Z" fill="white" />
          <path d="M383.045 141.519H382.354V144.091H383.045V141.519Z" fill="white" />
          <path
            d="M377.903 146.424H368.546V137.103H377.903V146.424ZM369.236 145.733H377.212V137.758H369.236V145.733Z"
            fill="white"
          />
          <path
            d="M375.592 139.924V140.995C375.593 141.079 375.578 141.163 375.547 141.241C375.516 141.319 375.47 141.389 375.411 141.449C375.353 141.509 375.282 141.557 375.205 141.589C375.128 141.622 375.045 141.638 374.961 141.638C374.793 141.635 374.632 141.566 374.514 141.446C374.396 141.326 374.33 141.164 374.33 140.995V139.924H375.592ZM376.282 139.234H373.651V140.995C373.634 141.179 373.655 141.364 373.713 141.538C373.771 141.713 373.865 141.874 373.989 142.01C374.113 142.146 374.264 142.255 374.432 142.329C374.601 142.404 374.783 142.442 374.967 142.442C375.151 142.442 375.333 142.404 375.501 142.329C375.67 142.255 375.821 142.146 375.945 142.01C376.068 141.874 376.162 141.713 376.221 141.538C376.279 141.364 376.3 141.179 376.282 140.995V139.281V139.234Z"
            fill="white"
          />
          <path d="M375.318 142.317H374.628V144.305H375.318V142.317Z" fill="white" />
          <path d="M376.296 143.972H373.653V144.662H376.296V143.972Z" fill="white" />
          <path
            d="M371.473 142.317C371.647 142.318 371.819 142.285 371.98 142.219C372.141 142.154 372.287 142.056 372.41 141.933C372.533 141.81 372.631 141.664 372.697 141.503C372.762 141.342 372.796 141.169 372.794 140.995V139.281H370.163V141.043C370.172 141.384 370.314 141.708 370.559 141.946C370.804 142.184 371.131 142.317 371.473 142.317Z"
            fill="white"
          />
          <path d="M371.831 142.317H371.141V144.305H371.831V142.317Z" fill="white" />
          <path d="M372.806 143.972H370.163V144.662H372.806V143.972Z" fill="white" />
          <path
            d="M366.949 146.424H357.604V137.103H366.949V146.424ZM358.295 145.733H366.235V137.758H358.259L358.295 145.733Z"
            fill="white"
          />
          <path
            d="M362.936 144.662C362.67 144.659 362.416 144.551 362.229 144.361C362.041 144.172 361.937 143.916 361.937 143.65V139.281H362.663V143.698C362.654 143.744 362.655 143.792 362.667 143.838C362.678 143.884 362.7 143.927 362.73 143.964C362.76 144 362.798 144.03 362.841 144.05C362.884 144.07 362.931 144.081 362.978 144.081C363.026 144.081 363.072 144.07 363.115 144.05C363.158 144.03 363.196 144 363.226 143.964C363.256 143.927 363.278 143.884 363.29 143.838C363.301 143.792 363.303 143.744 363.294 143.698V143.364H363.984V143.698C363.972 143.964 363.855 144.215 363.659 144.395C363.462 144.576 363.203 144.672 362.936 144.662Z"
            fill="white"
          />
          <path
            d="M359.95 141.745H364.617C364.617 141.149 364.38 140.576 363.958 140.154C363.536 139.733 362.963 139.495 362.367 139.495H362.2C361.603 139.495 361.031 139.733 360.609 140.154C360.187 140.576 359.95 141.149 359.95 141.745Z"
            fill="white"
          />
          <path d="M271 150.781H353.544V87.5809H271V150.781Z" fill="#FED700" />
          <path d="M328.057 150.781H353.544V87.5809H328.057V150.781Z" fill="#EBC400" />
          <path d="M301.273 105.676H321.665V94.4379H301.273V105.676Z" fill="white" />
          <path
            d="M305.917 146.543H296.572V137.198H305.917V146.543ZM297.251 145.852H305.227V137.877H297.251V145.852Z"
            fill="white"
          />
          <path d="M303.929 144.341H298.548V145.031H303.929V144.341Z" fill="white" />
          <path d="M302.713 139.055L303.927 142.091H301.499L302.713 139.055Z" fill="white" />
          <path d="M303.059 141.519H302.368V144.091H303.059V141.519Z" fill="white" />
          <path d="M299.774 139.055L300.988 142.091H298.548L299.774 139.055Z" fill="white" />
          <path d="M300.118 141.519H299.428V144.091H300.118V141.519Z" fill="white" />
          <path
            d="M294.977 146.424H285.632V137.103H294.977V146.424ZM286.31 145.733H294.286V137.758H286.31V145.733Z"
            fill="white"
          />
          <path
            d="M292.68 139.924V140.995C292.68 141.163 292.613 141.323 292.495 141.442C292.377 141.56 292.216 141.626 292.049 141.626C291.882 141.626 291.721 141.56 291.603 141.442C291.484 141.323 291.418 141.163 291.418 140.995V139.924H292.68ZM293.37 139.234H290.728V140.995C290.728 141.346 290.867 141.682 291.115 141.93C291.362 142.178 291.698 142.317 292.049 142.317C292.399 142.317 292.735 142.178 292.983 141.93C293.231 141.682 293.37 141.346 293.37 140.995V139.281V139.234Z"
            fill="white"
          />
          <path d="M292.394 142.317H291.703V144.305H292.394V142.317Z" fill="white" />
          <path d="M293.37 143.972H290.728V144.662H293.37V143.972Z" fill="white" />
          <path
            d="M288.559 142.317C288.909 142.317 289.245 142.178 289.493 141.93C289.741 141.682 289.88 141.346 289.88 140.995V139.281H287.237V141.043C287.25 141.385 287.394 141.709 287.641 141.947C287.887 142.184 288.216 142.317 288.559 142.317Z"
            fill="white"
          />
          <path d="M288.904 142.317H288.214V144.305H288.904V142.317Z" fill="white" />
          <path d="M289.88 143.972H287.237V144.662H289.88V143.972Z" fill="white" />
          <path
            d="M284.095 146.424H274.69V137.103H284.095V146.424ZM275.428 145.733H283.404V137.758H275.369L275.428 145.733Z"
            fill="white"
          />
          <path
            d="M280.025 144.662C279.756 144.662 279.499 144.555 279.309 144.366C279.119 144.176 279.013 143.918 279.013 143.65V139.281H279.703V143.698C279.694 143.744 279.695 143.792 279.707 143.838C279.719 143.884 279.74 143.927 279.771 143.964C279.801 144 279.839 144.03 279.881 144.05C279.924 144.07 279.971 144.081 280.019 144.081C280.066 144.081 280.113 144.07 280.156 144.05C280.199 144.03 280.237 144 280.267 143.964C280.297 143.927 280.319 143.884 280.33 143.838C280.342 143.792 280.343 143.744 280.334 143.698V143.364H281.025V143.698C281.012 143.956 280.902 144.199 280.716 144.379C280.53 144.558 280.283 144.659 280.025 144.662Z"
            fill="white"
          />
          <path
            d="M277.024 141.745H281.715C281.716 141.45 281.659 141.156 281.547 140.883C281.434 140.609 281.268 140.36 281.059 140.151C280.85 139.942 280.601 139.776 280.328 139.664C280.054 139.551 279.761 139.494 279.465 139.496H279.334C279.037 139.494 278.744 139.551 278.469 139.663C278.195 139.776 277.945 139.941 277.735 140.15C277.525 140.359 277.358 140.608 277.244 140.882C277.131 141.155 277.072 141.449 277.072 141.745H277.024Z"
            fill="white"
          />
          <path d="M325.334 88.2L407.878 88.2V25L325.334 25V88.2Z" fill="#FED700" />
          <path d="M382.391 88.2H407.878V25H382.391V88.2Z" fill="#EBC400" />
          <path d="M355.617 43.0826H376.009V31.845H355.617V43.0826Z" fill="white" />
          <path
            d="M360.284 83.9621H350.903V74.6172H360.284V83.9621ZM351.594 83.3311H359.57V75.3553H351.594V83.3311Z"
            fill="white"
          />
          <path d="M358.271 81.7596H352.891V82.4501H358.271V81.7596Z" fill="white" />
          <path d="M357.057 76.4742L358.271 79.5098H355.831L357.057 76.4742Z" fill="white" />
          <path d="M357.401 78.9265H356.711V81.4978H357.401V78.9265Z" fill="white" />
          <path d="M354.105 76.4742L355.331 79.5098H352.891L354.105 76.4742Z" fill="white" />
          <path d="M354.463 78.9265H353.772V81.4978H354.463V78.9265Z" fill="white" />
          <path
            d="M349.319 83.8668H339.962V74.522H349.319V83.8668ZM340.652 83.1764H348.628V75.2005H340.652V83.1764Z"
            fill="white"
          />
          <path
            d="M347.022 77.379V78.4504C347.001 78.6043 346.925 78.7454 346.807 78.8475C346.69 78.9497 346.54 79.0059 346.385 79.0059C346.229 79.0059 346.079 78.9497 345.962 78.8475C345.845 78.7454 345.769 78.6043 345.748 78.4504V77.379H347.022ZM347.7 76.6885H345.069V78.4504C345.052 78.6336 345.073 78.8185 345.131 78.9932C345.189 79.1678 345.283 79.3284 345.407 79.4646C345.531 79.6008 345.682 79.7096 345.85 79.7841C346.019 79.8586 346.201 79.897 346.385 79.897C346.569 79.897 346.751 79.8586 346.919 79.7841C347.088 79.7096 347.239 79.6008 347.362 79.4646C347.486 79.3284 347.58 79.1678 347.639 78.9932C347.697 78.8185 347.718 78.6336 347.7 78.4504V76.6528V76.6885Z"
            fill="white"
          />
          <path d="M346.735 79.736H346.045V81.724H346.735V79.736Z" fill="white" />
          <path d="M347.712 81.3906H345.069V82.0811H347.712V81.3906Z" fill="white" />
          <path
            d="M342.905 79.7599C343.078 79.7599 343.249 79.7256 343.409 79.659C343.568 79.5925 343.713 79.495 343.835 79.3721C343.957 79.2493 344.053 79.1035 344.118 78.9432C344.183 78.783 344.216 78.6114 344.214 78.4385V76.6528H341.583V78.4147C341.579 78.5906 341.609 78.7657 341.674 78.9295C341.738 79.0934 341.835 79.2425 341.958 79.3681C342.082 79.4936 342.229 79.5931 342.392 79.6604C342.554 79.7277 342.729 79.7615 342.905 79.7599Z"
            fill="white"
          />
          <path d="M343.248 79.736H342.558V81.724H343.248V79.736Z" fill="white" />
          <path d="M344.226 81.3906H341.583V82.0811H344.226V81.3906Z" fill="white" />
          <path
            d="M338.38 83.8668H329.023V74.522H338.38V83.8668ZM329.714 83.1764H337.666V75.2005H329.69L329.714 83.1764Z"
            fill="white"
          />
          <path
            d="M334.356 82.0693C334.225 82.0693 334.095 82.0434 333.974 81.9932C333.852 81.9429 333.742 81.8693 333.649 81.7764C333.556 81.6836 333.483 81.5733 333.433 81.452C333.382 81.3307 333.356 81.2006 333.356 81.0693V76.6528H334.095V81.0693C334.085 81.1159 334.087 81.1639 334.098 81.2098C334.11 81.2558 334.132 81.2986 334.162 81.3352C334.192 81.3719 334.23 81.4013 334.273 81.4216C334.316 81.4418 334.363 81.4523 334.41 81.4523C334.457 81.4523 334.504 81.4418 334.547 81.4216C334.59 81.4013 334.628 81.3719 334.658 81.3352C334.688 81.2986 334.71 81.2558 334.722 81.2098C334.733 81.1639 334.735 81.1159 334.725 81.0693V80.736H335.416V81.0693C335.416 81.2058 335.388 81.3409 335.334 81.4663C335.281 81.5916 335.202 81.7046 335.102 81.7983C335.003 81.892 334.886 81.9644 334.757 82.011C334.629 82.0576 334.493 82.0774 334.356 82.0693Z"
            fill="white"
          />
          <path
            d="M331.368 79.1646H336.035C336.035 78.8691 335.976 78.5766 335.863 78.3036C335.75 78.0306 335.585 77.7826 335.376 77.5737C335.167 77.3647 334.919 77.199 334.646 77.0859C334.373 76.9729 334.08 76.9147 333.785 76.9147H333.618C333.021 76.9147 332.449 77.1517 332.027 77.5737C331.605 77.9956 331.368 78.5679 331.368 79.1646Z"
            fill="white"
          />
        </>
      ) : null}
      {carVersion === 3 ? (
        <>
          <path
            d="M796.227 218.418C796.227 225.224 798.257 231.877 802.06 237.534C805.862 243.192 811.267 247.6 817.589 250.201C823.912 252.801 830.868 253.478 837.577 252.144C844.286 250.81 850.446 247.527 855.278 242.709C860.11 237.891 863.397 231.755 864.722 225.078C866.046 218.401 865.35 211.484 862.721 205.201C860.091 198.918 855.647 193.552 849.951 189.782C844.254 186.013 837.562 184.01 830.72 184.026C821.565 184.047 812.791 187.68 806.325 194.128C799.859 200.575 796.227 209.311 796.227 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M807.02 218.418C807.02 223.034 808.397 227.546 810.976 231.382C813.556 235.219 817.222 238.208 821.51 239.97C825.799 241.733 830.516 242.19 835.066 241.284C839.616 240.377 843.793 238.148 847.068 234.879C850.343 231.609 852.569 227.447 853.465 222.918C854.36 218.389 853.884 213.698 852.097 209.438C850.311 205.178 847.293 201.542 843.427 198.989C839.561 196.437 835.021 195.083 830.381 195.099C824.177 195.117 818.233 197.582 813.853 201.954C809.474 206.325 807.016 212.247 807.02 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M811.719 218.418C811.719 222.138 812.829 225.773 814.908 228.865C816.987 231.956 819.941 234.364 823.397 235.784C826.853 237.203 830.655 237.57 834.321 236.839C837.987 236.107 841.352 234.31 843.99 231.674C846.628 229.039 848.421 225.684 849.14 222.034C849.86 218.384 849.474 214.604 848.032 211.172C846.59 207.741 844.157 204.812 841.04 202.757C837.923 200.703 834.264 199.614 830.525 199.63C825.53 199.652 820.746 201.641 817.222 205.162C813.698 208.683 811.719 213.449 811.719 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M819.857 218.418C819.871 220.549 820.518 222.629 821.718 224.394C822.918 226.16 824.617 227.532 826.6 228.339C828.583 229.145 830.762 229.349 832.861 228.925C834.961 228.501 836.887 227.468 838.398 225.957C839.908 224.445 840.934 222.522 841.347 220.431C841.76 218.34 841.542 216.174 840.719 214.206C839.896 212.239 838.505 210.558 836.723 209.375C834.941 208.192 832.847 207.561 830.704 207.561C827.822 207.582 825.065 208.735 823.034 210.769C821.002 212.802 819.861 215.552 819.857 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M901.556 188.638C901.552 190.039 900.991 191.382 899.995 192.373C898.999 193.364 897.649 193.922 896.241 193.925H894.004V176.257H896.241C896.938 176.255 897.629 176.39 898.274 176.655C898.918 176.919 899.504 177.307 899.998 177.797C900.492 178.287 900.884 178.869 901.151 179.509C901.418 180.15 901.556 180.837 901.556 181.531V188.638Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M889.887 217.677C889.887 218.392 890.172 219.078 890.681 219.584C891.189 220.09 891.879 220.374 892.598 220.374H902.184C902.568 220.374 902.947 220.298 903.301 220.152C903.655 220.005 903.976 219.79 904.246 219.519C904.515 219.248 904.729 218.927 904.873 218.574C905.017 218.22 905.089 217.842 905.086 217.461V198.875C905.086 198.11 904.78 197.376 904.236 196.834C903.692 196.293 902.954 195.989 902.184 195.989H892.544C891.825 195.989 891.135 196.273 890.627 196.779C890.118 197.285 889.832 197.971 889.832 198.686L889.887 217.677Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M487.004 165.494H669.476V176.594H487.004V165.494Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M66.5714 164.779H334.15V176.594H66.5714V164.779Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M455.926 193.292H447.628C446.218 193.292 444.866 193.849 443.87 194.84C442.873 195.832 442.313 197.176 442.313 198.579V199.658C442.313 201.06 442.873 202.404 443.87 203.396C444.866 204.387 446.218 204.945 447.628 204.945H455.926V193.292Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M454.122 175.65H741.836V218.971H646.709V218.809C646.712 213.534 645.671 208.309 643.644 203.434C641.618 198.558 638.645 194.128 634.897 190.396C631.149 186.663 626.698 183.702 621.799 181.681C616.9 179.66 611.648 178.619 606.345 178.617C585.763 178.617 566.659 194.182 565.967 218.971H552.924C552.924 218.904 552.924 218.85 552.924 218.782C552.895 208.139 548.632 197.939 541.066 190.413C533.5 182.887 523.246 178.646 512.546 178.617C492.005 178.617 472.874 194.114 472.169 219.079H454.095C453.396 219.079 452.704 218.942 452.058 218.675C451.413 218.409 450.826 218.018 450.332 217.526C449.839 217.034 449.447 216.449 449.181 215.806C448.915 215.163 448.779 214.474 448.78 213.779V180.937C448.78 180.241 448.919 179.551 449.188 178.908C449.456 178.264 449.85 177.68 450.347 177.189C450.843 176.698 451.432 176.309 452.08 176.045C452.728 175.781 453.422 175.647 454.122 175.65Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M823.019 75.8441H748.934V45.7135C748.934 45.0392 749.074 44.3721 749.346 43.7544C749.618 43.1366 750.015 42.5815 750.513 42.1241C751.011 41.6666 751.599 41.3168 752.24 41.0966C752.88 40.8764 753.56 40.7906 754.236 40.8446L784.065 44.6885C787.473 44.9541 790.655 46.4909 792.973 48.9909L823.019 75.8441Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M792.02 207.575H792.251C791.172 211.245 790.642 215.053 790.678 218.877H741.867V81.239C741.871 79.8379 742.432 78.4952 743.428 77.5045C744.424 76.5137 745.773 75.9556 747.182 75.952H866.348C867.904 75.9527 869.421 76.433 870.691 77.3266C871.961 78.2203 872.921 79.4835 873.44 80.9423L890.429 128.741L894.496 138.816V219.929H872.165C872.144 215.534 871.689 211.153 870.809 206.846C866.823 190.985 849.387 178.523 831.069 178.523C822.312 178.516 813.79 181.346 806.794 186.585C799.798 191.825 794.707 199.188 792.291 207.561L792.02 207.575Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.617 97.9093C798.617 96.5071 799.177 95.1624 800.173 94.1708C801.17 93.1793 802.522 92.6223 803.932 92.6223H857.217C858.774 92.6205 860.295 93.096 861.571 93.9841C862.847 94.8722 863.817 96.1297 864.349 97.5856L874.843 126.125C875.057 126.69 875.13 127.299 875.054 127.898C874.978 128.497 874.755 129.069 874.407 129.564C874.058 130.058 873.593 130.46 873.052 130.735C872.512 131.011 871.912 131.15 871.304 131.142H803.932C802.523 131.138 801.173 130.58 800.177 129.59C799.181 128.599 798.62 127.256 798.617 125.855V97.9093Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M798.443 140.34C798.443 141.496 798.905 142.604 799.726 143.421C800.547 144.238 801.661 144.697 802.823 144.697H811.582C812.743 144.697 813.857 144.238 814.678 143.421C815.5 142.604 815.961 141.496 815.961 140.34C815.961 139.185 815.5 138.077 814.678 137.26C813.857 136.443 812.743 135.984 811.582 135.984H802.823C801.661 135.984 800.547 136.443 799.726 137.26C798.905 138.077 798.443 139.185 798.443 140.34Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M878.773 201.546H880.305C881.126 201.546 881.939 201.385 882.697 201.072C883.455 200.76 884.145 200.302 884.725 199.725C885.305 199.147 885.766 198.462 886.08 197.707C886.394 196.953 886.556 196.145 886.556 195.328V137.036L870.828 92.1907C870.241 90.5628 869.162 89.1551 867.74 88.1604C866.318 87.1657 864.621 86.6325 862.882 86.634H797.123C795.464 86.6375 793.874 87.2956 792.703 88.4638C791.531 89.632 790.873 91.2148 790.873 92.8651V165.346C790.88 167 791.546 168.583 792.726 169.749C793.905 170.914 795.501 171.567 797.164 171.563H832.172C848.903 171.563 865.255 181.895 871.058 196.137C871.66 197.698 872.715 199.046 874.089 200.009C875.462 200.972 877.093 201.507 878.773 201.546Z"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M755.541 92.6223H777.113C780.048 92.6223 782.428 94.9894 782.428 97.9093V125.815C782.428 128.734 780.048 131.102 777.113 131.102H755.541C752.606 131.102 750.226 128.734 750.226 125.815V97.9093C750.226 94.9894 752.606 92.6223 755.541 92.6223Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M6.78088 1.664H723.828C726.95 1.664 729.481 4.18204 729.481 7.2882V160.598C729.481 163.704 726.95 166.222 723.828 166.222H6.78088C3.65826 166.222 1.12695 163.704 1.12695 160.598V7.2882C1.12695 4.18204 3.65826 1.664 6.78088 1.664Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M14.5273 9.32477H717.297C720.127 9.32477 722.422 11.6073 722.422 14.423V153.463C722.422 156.279 720.127 158.562 717.297 158.562H14.5273C11.6967 158.562 9.4021 156.279 9.4021 153.463V14.423C9.4021 11.6073 11.6967 9.32477 14.5273 9.32477Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M684.369 135.755C684.438 137.119 685.031 138.406 686.027 139.347C687.023 140.289 688.344 140.814 689.718 140.814C691.091 140.814 692.413 140.289 693.408 139.347C694.404 138.406 694.997 137.119 695.066 135.755V31.2012C694.997 29.8364 694.404 28.5501 693.408 27.6085C692.413 26.6668 691.091 26.1417 689.718 26.1417C688.344 26.1417 687.023 26.6668 686.027 27.6085C685.031 28.5501 684.438 29.8364 684.369 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M101.08 135.755C101.149 137.119 101.742 138.406 102.738 139.347C103.733 140.289 105.055 140.814 106.428 140.814C107.802 140.814 109.123 140.289 110.119 139.347C111.115 138.406 111.708 137.119 111.777 135.755V31.2012C111.708 29.8364 111.115 28.5501 110.119 27.6085C109.123 26.6668 107.802 26.1417 106.428 26.1417C105.055 26.1417 103.733 26.6668 102.738 27.6085C101.742 28.5501 101.149 29.8364 101.08 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M42.8385 135.755C42.9076 137.119 43.5013 138.406 44.4968 139.347C45.4923 140.289 46.8135 140.814 48.1873 140.814C49.561 140.814 50.8823 140.289 51.8778 139.347C52.8733 138.406 53.467 137.119 53.5361 135.755V31.2012C53.467 29.8364 52.8733 28.5501 51.8778 27.6085C50.8823 26.6668 49.561 26.1417 48.1873 26.1417C46.8135 26.1417 45.4923 26.6668 44.4968 27.6085C43.5013 28.5501 42.9076 29.8364 42.8385 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M159.32 135.755C159.389 137.119 159.983 138.406 160.978 139.347C161.974 140.289 163.295 140.814 164.669 140.814C166.042 140.814 167.364 140.289 168.359 139.347C169.355 138.406 169.948 137.119 170.018 135.755V31.2012C169.948 29.8364 169.355 28.5501 168.359 27.6085C167.364 26.6668 166.042 26.1417 164.669 26.1417C163.295 26.1417 161.974 26.6668 160.978 27.6085C159.983 28.5501 159.389 29.8364 159.32 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M217.56 135.755C217.629 137.119 218.223 138.406 219.218 139.347C220.214 140.289 221.535 140.814 222.909 140.814C224.283 140.814 225.604 140.289 226.599 139.347C227.595 138.406 228.189 137.119 228.258 135.755V31.2012C228.189 29.8364 227.595 28.5501 226.599 27.6085C225.604 26.6668 224.283 26.1417 222.909 26.1417C221.535 26.1417 220.214 26.6668 219.218 27.6085C218.223 28.5501 217.629 29.8364 217.56 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M275.801 135.755C275.87 137.119 276.464 138.406 277.46 139.347C278.455 140.289 279.776 140.814 281.15 140.814C282.524 140.814 283.845 140.289 284.841 139.347C285.836 138.406 286.43 137.119 286.499 135.755V31.2012C286.43 29.8364 285.836 28.5501 284.841 27.6085C283.845 26.6668 282.524 26.1417 281.15 26.1417C279.776 26.1417 278.455 26.6668 277.46 27.6085C276.464 28.5501 275.87 29.8364 275.801 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M334.042 135.755C334.112 137.119 334.705 138.406 335.701 139.347C336.696 140.289 338.018 140.814 339.391 140.814C340.765 140.814 342.086 140.289 343.082 139.347C344.077 138.406 344.671 137.119 344.74 135.755V31.2012C344.671 29.8364 344.077 28.5501 343.082 27.6085C342.086 26.6668 340.765 26.1417 339.391 26.1417C338.018 26.1417 336.696 26.6668 335.701 27.6085C334.705 28.5501 334.112 29.8364 334.042 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M392.283 135.755C392.352 137.119 392.946 138.406 393.941 139.347C394.937 140.289 396.258 140.814 397.632 140.814C399.005 140.814 400.327 140.289 401.322 139.347C402.318 138.406 402.911 137.119 402.98 135.755V31.2012C402.911 29.8364 402.318 28.5501 401.322 27.6085C400.327 26.6668 399.005 26.1417 397.632 26.1417C396.258 26.1417 394.937 26.6668 393.941 27.6085C392.946 28.5501 392.352 29.8364 392.283 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M450.509 135.755C450.713 137.022 451.364 138.176 452.345 139.009C453.327 139.842 454.575 140.299 455.865 140.299C457.155 140.299 458.403 139.842 459.385 139.009C460.366 138.176 461.017 137.022 461.221 135.755V31.2011C461.017 29.9337 460.366 28.78 459.385 27.947C458.403 27.1141 457.155 26.6566 455.865 26.6566C454.575 26.6566 453.327 27.1141 452.345 27.947C451.364 28.78 450.713 29.9337 450.509 31.2011V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M509.647 135.755C509.716 137.119 510.31 138.406 511.305 139.347C512.301 140.289 513.622 140.814 514.996 140.814C516.37 140.814 517.691 140.289 518.686 139.347C519.682 138.406 520.276 137.119 520.345 135.755V31.2012C520.276 29.8364 519.682 28.5501 518.686 27.6085C517.691 26.6668 516.37 26.1417 514.996 26.1417C513.622 26.1417 512.301 26.6668 511.305 27.6085C510.31 28.5501 509.716 29.8364 509.647 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M567.875 135.755C568.078 137.022 568.729 138.176 569.711 139.009C570.692 139.842 571.94 140.299 573.23 140.299C574.521 140.299 575.768 139.842 576.75 139.009C577.732 138.176 578.383 137.022 578.586 135.755V31.2011C578.383 29.9337 577.732 28.78 576.75 27.947C575.768 27.1141 574.521 26.6566 573.23 26.6566C571.94 26.6566 570.692 27.1141 569.711 27.947C568.729 28.78 568.078 29.9337 567.875 31.2011V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M626.128 135.755C626.198 137.119 626.791 138.406 627.787 139.347C628.782 140.289 630.104 140.814 631.477 140.814C632.851 140.814 634.172 140.289 635.168 139.347C636.163 138.406 636.757 137.119 636.826 135.755V31.2012C636.757 29.8364 636.163 28.5501 635.168 27.6085C634.172 26.6668 632.851 26.1417 631.477 26.1417C630.104 26.1417 628.782 26.6668 627.787 27.6085C626.791 28.5501 626.198 29.8364 626.128 31.2012V135.755Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M61.4785 193.049H53.1807C52.4822 193.047 51.7902 193.182 51.1446 193.448C50.4989 193.713 49.9123 194.102 49.4184 194.593C48.9245 195.085 48.533 195.668 48.2666 196.31C48.0001 196.953 47.8638 197.641 47.8656 198.336V199.415C47.8638 200.109 48.0001 200.798 48.2666 201.44C48.533 202.082 48.9245 202.666 49.4184 203.157C49.9123 203.648 50.4989 204.038 51.1446 204.303C51.7902 204.568 52.4822 204.703 53.1807 204.702H61.4785V193.049Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M59.8927 175.407H354.426C355.125 175.406 355.817 175.541 356.462 175.806C357.108 176.071 357.695 176.461 358.188 176.952C358.682 177.443 359.074 178.027 359.34 178.669C359.607 179.311 359.743 180 359.741 180.694V213.415C359.743 214.11 359.607 214.799 359.34 215.442C359.074 216.085 358.683 216.669 358.189 217.162C357.696 217.654 357.109 218.045 356.463 218.311C355.818 218.578 355.125 218.715 354.426 218.715H346.115V218.526C346.117 213.252 345.074 208.028 343.046 203.155C341.019 198.281 338.046 193.852 334.298 190.121C330.55 186.39 326.1 183.43 321.202 181.41C316.303 179.39 311.053 178.349 305.751 178.348C285.169 178.348 266.065 193.912 265.373 218.702H252.343C252.343 183.473 211.234 166.236 185.757 187.95C177.419 195.031 171.955 205.7 171.588 218.742H165.08H158.586C158.605 213.45 157.576 208.206 155.555 203.31C153.535 198.415 150.564 193.964 146.812 190.212C143.061 186.46 138.602 183.48 133.691 181.445C128.781 179.409 123.515 178.356 118.195 178.348C97.6534 178.348 78.5223 193.844 77.8172 218.742H59.7436C59.0445 218.744 58.3519 218.608 57.7055 218.343C57.0591 218.079 56.4716 217.689 55.9766 217.198C55.4816 216.707 55.0889 216.124 54.8209 215.481C54.5529 214.839 54.4151 214.15 54.4151 213.455V180.694C54.4436 179.273 55.035 177.92 56.0607 176.93C57.0865 175.94 58.4636 175.393 59.8927 175.407Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M664.58 184.538H726.814C729.749 184.538 732.129 186.905 732.129 189.825V226.74C732.129 229.66 729.749 232.027 726.814 232.027H664.58C661.645 232.027 659.265 229.66 659.265 226.74V189.825C659.265 186.905 661.645 184.538 664.58 184.538Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M674.386 232.647C674.386 233.513 674.732 234.343 675.347 234.955C675.962 235.567 676.797 235.911 677.667 235.911C678.537 235.911 679.372 235.567 679.987 234.955C680.603 234.343 680.948 233.513 680.948 232.647V184.606C680.948 183.74 680.603 182.91 679.987 182.298C679.372 181.686 678.537 181.342 677.667 181.342C676.797 181.342 675.962 181.686 675.347 182.298C674.732 182.91 674.386 183.74 674.386 184.606V232.647Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M692.916 232.647C692.916 233.076 693.001 233.5 693.166 233.896C693.331 234.292 693.573 234.652 693.877 234.955C694.182 235.258 694.544 235.499 694.942 235.663C695.34 235.827 695.766 235.911 696.197 235.911C696.628 235.911 697.055 235.827 697.453 235.663C697.851 235.499 698.213 235.258 698.517 234.955C698.822 234.652 699.064 234.292 699.229 233.896C699.394 233.5 699.479 233.076 699.479 232.647V184.606C699.479 184.177 699.394 183.753 699.229 183.357C699.064 182.961 698.822 182.601 698.517 182.298C698.213 181.995 697.851 181.754 697.453 181.59C697.055 181.426 696.628 181.342 696.197 181.342C695.766 181.342 695.34 181.426 694.942 181.59C694.544 181.754 694.182 181.995 693.877 182.298C693.573 182.601 693.331 182.961 693.166 183.357C693.001 183.753 692.916 184.177 692.916 184.606V232.647Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M711.448 232.647C711.448 233.513 711.794 234.343 712.409 234.955C713.025 235.567 713.859 235.911 714.73 235.911C715.6 235.911 716.434 235.567 717.05 234.955C717.665 234.343 718.011 233.513 718.011 232.647V184.606C718.011 183.74 717.665 182.91 717.05 182.298C716.434 181.686 715.6 181.342 714.73 181.342C713.859 181.342 713.025 181.686 712.409 182.298C711.794 182.91 711.448 183.74 711.448 184.606V232.647Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M572.089 218.418C572.089 225.221 574.117 231.87 577.916 237.526C581.715 243.182 587.115 247.59 593.433 250.193C599.75 252.796 606.702 253.477 613.409 252.15C620.116 250.823 626.276 247.547 631.112 242.738C635.947 237.928 639.24 231.8 640.574 225.128C641.908 218.456 641.223 211.541 638.606 205.257C635.99 198.972 631.558 193.601 625.872 189.822C620.187 186.043 613.502 184.026 606.664 184.026C597.494 184.026 588.7 187.649 582.216 194.099C575.732 200.549 572.089 209.297 572.089 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M582.88 218.418C582.88 223.03 584.255 227.539 586.831 231.374C589.407 235.209 593.068 238.198 597.352 239.963C601.635 241.728 606.349 242.19 610.896 241.29C615.444 240.39 619.621 238.169 622.899 234.908C626.178 231.646 628.411 227.491 629.315 222.968C630.22 218.444 629.755 213.755 627.981 209.494C626.207 205.233 623.202 201.591 619.347 199.029C615.492 196.466 610.959 195.099 606.323 195.099C600.105 195.099 594.143 197.556 589.746 201.929C585.35 206.302 582.88 212.234 582.88 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M587.579 218.418C587.579 222.134 588.687 225.767 590.762 228.856C592.838 231.946 595.787 234.354 599.239 235.776C602.69 237.198 606.487 237.57 610.151 236.845C613.815 236.12 617.18 234.331 619.822 231.703C622.463 229.076 624.262 225.728 624.991 222.084C625.719 218.439 625.345 214.662 623.916 211.229C622.486 207.796 620.065 204.861 616.96 202.797C613.854 200.732 610.202 199.63 606.466 199.63C603.986 199.63 601.53 200.116 599.239 201.061C596.947 202.005 594.865 203.389 593.111 205.133C591.357 206.878 589.966 208.949 589.017 211.229C588.068 213.508 587.579 215.951 587.579 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M595.72 218.418C595.733 220.547 596.379 222.623 597.576 224.387C598.773 226.151 600.468 227.524 602.447 228.332C604.426 229.14 606.601 229.348 608.699 228.93C610.797 228.511 612.723 227.485 614.236 225.98C615.749 224.475 616.781 222.559 617.201 220.472C617.622 218.386 617.413 216.222 616.6 214.253C615.788 212.284 614.408 210.598 612.635 209.408C610.861 208.217 608.774 207.574 606.634 207.561C605.201 207.561 603.782 207.842 602.457 208.387C601.133 208.933 599.93 209.733 598.916 210.741C597.903 211.749 597.099 212.946 596.55 214.263C596.002 215.581 595.72 216.993 595.72 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M477.669 218.418C477.669 225.221 479.697 231.87 483.496 237.526C487.295 243.182 492.695 247.59 499.013 250.193C505.331 252.796 512.282 253.477 518.989 252.15C525.696 250.823 531.856 247.547 536.692 242.738C541.527 237.928 544.82 231.8 546.154 225.128C547.488 218.456 546.803 211.541 544.187 205.257C541.57 198.972 537.138 193.601 531.452 189.822C525.767 186.043 519.082 184.026 512.244 184.026C503.074 184.026 494.28 187.649 487.796 194.099C481.312 200.549 477.669 209.297 477.669 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M488.46 218.418C488.46 223.031 489.835 227.54 492.412 231.375C494.988 235.21 498.65 238.199 502.935 239.964C507.219 241.729 511.933 242.19 516.481 241.289C521.029 240.388 525.206 238.166 528.484 234.903C531.762 231.64 533.994 227.484 534.897 222.959C535.8 218.435 535.334 213.746 533.557 209.485C531.781 205.224 528.774 201.583 524.917 199.022C521.06 196.461 516.526 195.096 511.889 195.099C505.674 195.102 499.715 197.561 495.321 201.934C490.928 206.307 488.46 212.236 488.46 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M493.159 218.418C493.159 222.135 494.267 225.768 496.343 228.858C498.419 231.948 501.37 234.356 504.822 235.777C508.274 237.199 512.072 237.57 515.736 236.844C519.4 236.118 522.766 234.327 525.406 231.699C528.047 229.07 529.845 225.721 530.572 222.075C531.3 218.43 530.924 214.652 529.492 211.219C528.06 207.786 525.637 204.853 522.53 202.79C519.422 200.727 515.769 199.628 512.033 199.63C507.026 199.634 502.226 201.615 498.686 205.138C495.147 208.661 493.159 213.438 493.159 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M501.162 218.418C501.162 220.566 501.802 222.665 503.001 224.45C504.201 226.236 505.905 227.627 507.9 228.449C509.894 229.271 512.089 229.486 514.206 229.067C516.323 228.648 518.268 227.614 519.794 226.096C521.321 224.577 522.36 222.643 522.781 220.536C523.203 218.43 522.987 216.247 522.16 214.263C521.334 212.279 519.935 210.584 518.14 209.391C516.345 208.198 514.235 207.561 512.077 207.561C509.182 207.561 506.406 208.705 504.359 210.741C502.312 212.777 501.162 215.539 501.162 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M271.179 218.418C271.179 225.221 273.207 231.871 277.007 237.527C280.807 243.183 286.207 247.592 292.526 250.194C298.844 252.797 305.797 253.477 312.504 252.149C319.211 250.821 325.372 247.544 330.206 242.733C335.041 237.922 338.333 231.792 339.665 225.12C340.998 218.447 340.311 211.532 337.692 205.248C335.073 198.963 330.64 193.593 324.952 189.815C319.265 186.038 312.579 184.023 305.74 184.026C296.573 184.029 287.782 187.654 281.301 194.104C274.82 200.553 271.179 209.299 271.179 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M282.853 218.418C282.853 223.031 284.228 227.54 286.804 231.375C289.381 235.21 293.043 238.199 297.327 239.964C301.612 241.729 306.326 242.19 310.874 241.289C315.422 240.388 319.599 238.166 322.877 234.903C326.155 231.64 328.386 227.484 329.289 222.959C330.192 218.435 329.726 213.746 327.95 209.485C326.173 205.224 323.167 201.583 319.31 199.022C315.453 196.461 310.919 195.096 306.282 195.099C303.204 195.099 300.156 195.702 297.313 196.874C294.469 198.046 291.886 199.764 289.71 201.93C287.534 204.095 285.809 206.666 284.632 209.495C283.456 212.324 282.851 215.357 282.853 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M287.552 218.418C287.552 222.135 288.66 225.768 290.736 228.858C292.812 231.948 295.762 234.356 299.214 235.777C302.666 237.199 306.465 237.57 310.129 236.844C313.793 236.118 317.158 234.327 319.799 231.699C322.44 229.07 324.238 225.721 324.965 222.075C325.692 218.43 325.316 214.652 323.885 211.219C322.453 207.786 320.03 204.853 316.922 202.79C313.815 200.727 310.162 199.628 306.425 199.63C301.419 199.634 296.618 201.615 293.079 205.138C289.54 208.661 287.552 213.438 287.552 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M295.555 218.418C295.555 220.569 296.197 222.67 297.4 224.457C298.602 226.244 300.311 227.636 302.309 228.455C304.307 229.275 306.505 229.486 308.625 229.062C310.744 228.637 312.688 227.597 314.212 226.071C315.736 224.546 316.77 222.605 317.183 220.495C317.597 218.384 317.371 216.199 316.534 214.216C315.698 212.234 314.288 210.543 312.485 209.358C310.681 208.173 308.564 207.548 306.402 207.561C304.972 207.561 303.556 207.842 302.236 208.389C300.915 208.935 299.716 209.736 298.708 210.745C297.7 211.754 296.903 212.952 296.362 214.268C295.82 215.585 295.546 216.996 295.555 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M177.641 218.418C177.641 225.221 179.669 231.871 183.469 237.527C187.269 243.183 192.669 247.592 198.988 250.194C205.306 252.797 212.259 253.477 218.966 252.149C225.673 250.821 231.833 247.544 236.668 242.733C241.503 237.922 244.795 231.792 246.127 225.12C247.46 218.447 246.773 211.532 244.154 205.248C241.535 198.963 237.102 193.593 231.414 189.815C225.727 186.038 219.041 184.023 212.202 184.026C203.035 184.029 194.244 187.654 187.763 194.104C181.282 200.553 177.641 209.299 177.641 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M188.433 218.418C188.433 223.031 189.808 227.54 192.385 231.375C194.961 235.21 198.623 238.199 202.907 239.964C207.192 241.729 211.906 242.19 216.454 241.289C221.002 240.388 225.179 238.166 228.457 234.903C231.735 231.64 233.967 227.484 234.87 222.959C235.773 218.435 235.306 213.746 233.53 209.485C231.754 205.224 228.747 201.583 224.89 199.022C221.033 196.461 216.499 195.096 211.862 195.099C208.784 195.099 205.736 195.702 202.893 196.874C200.049 198.046 197.466 199.764 195.29 201.93C193.114 204.095 191.389 206.666 190.212 209.495C189.036 212.324 188.431 215.357 188.433 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M193.131 218.418C193.131 222.135 194.239 225.768 196.315 228.858C198.391 231.948 201.342 234.356 204.794 235.777C208.245 237.199 212.044 237.57 215.708 236.844C219.372 236.118 222.737 234.327 225.378 231.699C228.019 229.07 229.817 225.721 230.544 222.075C231.271 218.43 230.895 214.652 229.464 211.219C228.032 207.786 225.609 204.853 222.501 202.79C219.394 200.727 215.741 199.628 212.005 199.63C206.998 199.634 202.197 201.615 198.658 205.138C195.119 208.661 193.131 213.438 193.131 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M201.27 218.418C201.284 220.549 201.931 222.629 203.131 224.394C204.331 226.16 206.03 227.532 208.013 228.339C209.996 229.145 212.175 229.349 214.275 228.925C216.374 228.501 218.301 227.468 219.811 225.957C221.321 224.445 222.347 222.522 222.76 220.431C223.173 218.34 222.955 216.174 222.132 214.206C221.309 212.239 219.919 210.558 218.136 209.375C216.354 208.192 214.26 207.561 212.117 207.561C209.235 207.582 206.479 208.735 204.447 210.769C202.416 212.802 201.274 215.552 201.27 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M83.2193 218.418C83.2193 225.221 85.2471 231.87 89.0462 237.526C92.8453 243.182 98.245 247.59 104.563 250.193C110.88 252.796 117.832 253.477 124.539 252.15C131.246 250.823 137.406 247.547 142.242 242.738C147.077 237.928 150.37 231.8 151.704 225.128C153.038 218.456 152.353 211.541 149.736 205.257C147.119 198.972 142.688 193.601 137.002 189.822C131.317 186.043 124.632 184.026 117.794 184.026C108.624 184.026 99.8299 187.649 93.3459 194.099C86.8619 200.549 83.2193 209.297 83.2193 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M94.8947 218.418C94.8947 223.03 96.2695 227.539 98.8455 231.374C101.421 235.209 105.083 238.198 109.366 239.963C113.65 241.728 118.363 242.19 122.911 241.29C127.458 240.39 131.636 238.169 134.914 234.908C138.193 231.646 140.425 227.491 141.33 222.968C142.234 218.444 141.77 213.755 139.996 209.494C138.221 205.233 135.217 201.591 131.362 199.029C127.506 196.466 122.974 195.099 118.337 195.099C112.12 195.099 106.157 197.556 101.761 201.929C97.3645 206.302 94.8947 212.234 94.8947 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M98.7111 218.418C98.7111 222.134 99.8188 225.767 101.894 228.856C103.97 231.946 106.919 234.354 110.37 235.776C113.822 237.198 117.619 237.57 121.283 236.845C124.947 236.12 128.312 234.331 130.953 231.703C133.595 229.076 135.394 225.728 136.122 222.084C136.851 218.439 136.477 214.662 135.048 211.229C133.618 207.796 131.197 204.861 128.091 202.797C124.985 200.732 121.334 199.63 117.598 199.63C115.118 199.63 112.662 200.116 110.37 201.061C108.079 202.005 105.997 203.389 104.243 205.133C102.489 206.878 101.098 208.949 100.149 211.229C99.1996 213.508 98.7111 215.951 98.7111 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
          <path
            d="M106.849 218.418C106.863 220.549 107.51 222.629 108.71 224.394C109.91 226.16 111.609 227.532 113.592 228.339C115.575 229.145 117.754 229.349 119.854 228.925C121.953 228.501 123.88 227.468 125.39 225.957C126.9 224.445 127.926 222.522 128.339 220.431C128.753 218.34 128.534 216.174 127.711 214.206C126.888 212.239 125.498 210.558 123.715 209.375C121.933 208.192 119.839 207.561 117.696 207.561C114.813 207.579 112.055 208.731 110.022 210.765C107.99 212.799 106.849 215.55 106.849 218.418Z"
            fill="#252733"
            stroke="#9E9E9E"
            strokeWidth="2"
            stroke-miterlimit="10"
          />
        </>
      ) : null}
    </svg>
  );
});

export default CarVersionLightMode;
