const Warehousing = ({ size = '96px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 96 96" fill="none">
      <g clip-path="url(#clip0_2674_244)">
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="#4A4C64"
        />
        <path
          d="M46.8482 69.0433C46.8481 69.7299 46.6444 70.4011 46.2629 70.972C45.8814 71.5429 45.3392 71.9878 44.7048 72.2505C44.0704 72.5132 43.3723 72.5819 42.6989 72.4479C42.0255 72.3139 41.4069 71.9832 40.9214 71.4976C40.4359 71.0121 40.1053 70.3935 39.9714 69.72C39.8375 69.0466 39.9063 68.3485 40.1691 67.7142C40.432 67.0798 40.877 66.5377 41.4479 66.1562C42.0188 65.7748 42.6901 65.5712 43.3767 65.5713C43.8326 65.5713 44.2841 65.6611 44.7053 65.8356C45.1265 66.0101 45.5092 66.2658 45.8316 66.5882C46.154 66.9106 46.4097 67.2934 46.5841 67.7146C46.7585 68.1359 46.8482 68.5874 46.8482 69.0433Z"
          fill="white"
        />
        <path
          d="M44.9508 69.0432C44.9507 69.3546 44.8584 69.6589 44.6853 69.9177C44.5123 70.1765 44.2664 70.3783 43.9788 70.4974C43.6911 70.6164 43.3746 70.6476 43.0693 70.5868C42.7639 70.526 42.4835 70.376 42.2634 70.1558C42.0433 69.9356 41.8934 69.6551 41.8327 69.3498C41.772 69.0444 41.8032 68.7279 41.9224 68.4403C42.0416 68.1527 42.2434 67.9068 42.5023 67.7339C42.7612 67.561 43.0655 67.4687 43.3769 67.4688C43.7944 67.4688 44.1947 67.6348 44.4899 67.93C44.7851 68.2253 44.9509 68.6257 44.9508 69.0432Z"
          fill="#4A4C64"
        />
        <path
          d="M67.3538 69.0433C67.3538 69.73 67.1502 70.4012 66.7687 70.9722C66.3872 71.5432 65.8449 71.9882 65.2105 72.2509C64.5761 72.5137 63.878 72.5825 63.2045 72.4485C62.531 72.3145 61.9124 71.9839 61.4268 71.4983C60.9413 71.0128 60.6106 70.3941 60.4766 69.7206C60.3427 69.0471 60.4114 68.349 60.6742 67.7146C60.937 67.0802 61.382 66.538 61.9529 66.1564C62.5239 65.7749 63.1951 65.5713 63.8818 65.5713C64.3378 65.5713 64.7893 65.6611 65.2105 65.8355C65.6318 66.01 66.0145 66.2658 66.3369 66.5882C66.6594 66.9106 66.9151 67.2933 67.0896 67.7146C67.2641 68.1358 67.3539 68.5873 67.3538 69.0433Z"
          fill="white"
        />
        <path
          d="M65.4564 69.0432C65.4563 69.3546 65.364 69.659 65.191 69.9179C65.018 70.1768 64.7721 70.3785 64.4844 70.4977C64.1967 70.6168 63.8802 70.648 63.5748 70.5872C63.2694 70.5265 62.9889 70.3765 62.7687 70.1563C62.5485 69.9362 62.3986 69.6556 62.3379 69.3502C62.2771 69.0448 62.3083 68.7283 62.4275 68.4406C62.5466 68.1529 62.7484 67.9071 63.0073 67.7341C63.2662 67.5611 63.5706 67.4688 63.882 67.4688C64.0887 67.4687 64.2935 67.5095 64.4845 67.5886C64.6755 67.6677 64.8491 67.7837 64.9953 67.9299C65.1415 68.0761 65.2574 68.2497 65.3365 68.4407C65.4157 68.6317 65.4564 68.8365 65.4564 69.0432Z"
          fill="#4A4C64"
        />
        <path
          d="M69.2036 61.5537H38.8395V23.4854H26.1619V23.4859C25.5174 23.491 24.901 23.7507 24.4471 24.2082C23.9932 24.6658 23.7385 25.2842 23.7385 25.9287C23.7385 26.5732 23.9932 27.1916 24.4471 27.6491C24.901 28.1067 25.5174 28.3663 26.1619 28.3715V28.372H34.2759V66.6824L40.0203 66.6823L40.3523 66.3034C40.9984 65.5659 42.1703 65.1078 43.4106 65.1078C44.6508 65.1078 45.8225 65.5659 46.4684 66.3033L46.8004 66.6823H60.5145L60.8465 66.3034C61.4925 65.5659 62.6644 65.1078 63.9047 65.1078C65.1451 65.1078 66.3171 65.5659 66.9631 66.3034L67.2951 66.6823L69.2036 66.6824C70.9182 66.6824 72.2613 65.556 72.2613 64.118C72.2613 62.68 70.9182 61.5537 69.2036 61.5537Z"
          fill="white"
        />
        <path
          d="M46.0123 38.9512H61.6545C61.8943 38.9512 62.1243 39.0464 62.2938 39.216C62.4634 39.3856 62.5587 39.6155 62.5587 39.8553V59.8715C62.5587 60.1113 62.4634 60.3413 62.2938 60.5108C62.1243 60.6804 61.8943 60.7757 61.6545 60.7757H46.0123C45.7725 60.7757 45.5425 60.6804 45.373 60.5108C45.2034 60.3413 45.1082 60.1113 45.1082 59.8715V39.8554C45.1082 39.7366 45.1315 39.619 45.177 39.5093C45.2224 39.3996 45.289 39.3 45.373 39.216C45.4569 39.132 45.5566 39.0654 45.6663 39.02C45.776 38.9746 45.8936 38.9512 46.0123 38.9512Z"
          fill="white"
        />
        <path
          d="M54.8968 45.8034H52.7693C52.6579 45.8025 52.5514 45.7573 52.4733 45.6778C52.3952 45.5984 52.3519 45.4912 52.3528 45.3798V38.8335H55.3134V45.3798C55.3143 45.4912 55.2709 45.5984 55.1928 45.6778C55.1147 45.7573 55.0083 45.8025 54.8968 45.8034Z"
          fill="#4A4C64"
        />
        <path
          d="M59.0053 28.7537H48.5433C48.044 28.7537 47.6392 29.1585 47.6392 29.6578V37.2187C47.6392 37.718 48.044 38.1229 48.5433 38.1229H59.0053C59.5047 38.1229 59.9095 37.718 59.9095 37.2187V29.6578C59.9095 29.1585 59.5047 28.7537 59.0053 28.7537Z"
          fill="white"
        />
        <path
          d="M54.8553 32.8322H52.6938C52.5852 32.8294 52.4821 32.7837 52.4072 32.7049C52.3323 32.6262 52.2917 32.521 52.2943 32.4124V28.5464H55.2549V32.4124C55.2575 32.521 55.2169 32.6262 55.142 32.7049C55.0671 32.7837 54.964 32.8294 54.8553 32.8322Z"
          fill="#4A4C64"
        />
      </g>
      <defs>
        <clipPath id="clip0_2674_244">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Warehousing;
