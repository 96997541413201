const TruckRepair = ({ size = '96px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 97 96" fill="none">
      <g clip-path="url(#clip0_2674_280)">
        <path
          d="M48.5 96C75.0097 96 96.5 74.5097 96.5 48C96.5 21.4903 75.0097 0 48.5 0C21.9903 0 0.5 21.4903 0.5 48C0.5 74.5097 21.9903 96 48.5 96Z"
          fill="#4A4C64"
        />
        <path
          d="M72.3612 61.5002H24.6387C24.3216 61.5003 24.0176 61.6262 23.7935 61.8504C23.5693 62.0745 23.4434 62.3785 23.4434 62.6955V68.3102C23.4434 68.6272 23.5693 68.9312 23.7935 69.1554C24.0176 69.3796 24.3216 69.5055 24.6387 69.5055H25.8824C26.0313 70.3853 26.4866 71.184 27.1678 71.7603C27.8489 72.3366 28.7121 72.6533 29.6043 72.6543H34.2852C35.1774 72.6532 36.0405 72.3366 36.7217 71.7603C37.4029 71.184 37.8582 70.3853 38.007 69.5055H58.993C59.1418 70.3853 59.5971 71.184 60.2783 71.7603C60.9595 72.3366 61.8226 72.6533 62.7148 72.6543H67.3958C68.288 72.6532 69.1511 72.3366 69.8323 71.7603C70.5134 71.184 70.9687 70.3853 71.1176 69.5055H72.3612C72.5182 69.5056 72.6737 69.4746 72.8187 69.4146C72.9637 69.3545 73.0955 69.2665 73.2065 69.1555C73.3175 69.0445 73.4056 68.9127 73.4657 68.7676C73.5257 68.6226 73.5567 68.4672 73.5567 68.3102V62.6955C73.5567 62.5386 73.5257 62.3831 73.4657 62.2381C73.4056 62.0931 73.3175 61.9613 73.2065 61.8503C73.0955 61.7393 72.9637 61.6513 72.8187 61.5912C72.6737 61.5311 72.5182 61.5002 72.3612 61.5002Z"
          fill="white"
        />
        <path
          d="M60.6307 23.3457L59.0412 24.7464C58.825 24.9369 58.6519 25.1712 58.5333 25.4337C58.4148 25.6963 58.3535 25.9811 58.3536 26.2692V28.0285C56.5206 27.5391 53.0502 26.8165 48.4999 26.8165C43.9495 26.8165 40.4791 27.5391 38.646 28.0286V26.2692C38.6461 25.9811 38.5849 25.6963 38.4663 25.4337C38.3478 25.1712 38.1746 24.9369 37.9585 24.7464L36.369 23.3457L35.4082 24.4363L36.9977 25.837C37.0591 25.891 37.1083 25.9575 37.1419 26.0321C37.1756 26.1066 37.1929 26.1874 37.1928 26.2692V32.6676H37.1934V39.3448C37.194 39.6793 37.3168 40.002 37.5386 40.2524C37.7605 40.5027 38.0661 40.6633 38.3981 40.7042V60.4778H58.6017V40.7042C58.9337 40.6633 59.2393 40.5027 59.4611 40.2523C59.6829 40.002 59.8057 39.6793 59.8063 39.3448V32.6676H59.8069V26.2692C59.8068 26.1874 59.8241 26.1066 59.8578 26.032C59.8914 25.9575 59.9406 25.891 60.002 25.837L61.5915 24.4363L60.6307 23.3457Z"
          fill="white"
        />
        <path
          d="M55.9862 43.5208H41.0141C40.9418 43.5208 40.8701 43.5066 40.8033 43.4789C40.7365 43.4512 40.6758 43.4107 40.6247 43.3595C40.5735 43.3084 40.533 43.2477 40.5053 43.1809C40.4776 43.1141 40.4634 43.0424 40.4634 42.9701C40.4634 42.8241 40.5214 42.684 40.6247 42.5807C40.7279 42.4775 40.868 42.4194 41.0141 42.4194H55.9862C56.1322 42.4194 56.2723 42.4775 56.3755 42.5807C56.4788 42.684 56.5368 42.8241 56.5368 42.9701C56.5369 43.0424 56.5226 43.1141 56.4949 43.1809C56.4673 43.2477 56.4267 43.3084 56.3756 43.3595C56.3244 43.4107 56.2637 43.4512 56.1969 43.4789C56.1301 43.5066 56.0585 43.5208 55.9862 43.5208Z"
          fill="#4A4C64"
        />
        <path
          d="M55.9862 46.4122H41.0141C40.868 46.4122 40.7279 46.3542 40.6247 46.2509C40.5214 46.1476 40.4634 46.0075 40.4634 45.8615C40.4634 45.7154 40.5214 45.5754 40.6247 45.4721C40.7279 45.3688 40.868 45.3108 41.0141 45.3108H55.9862C56.1322 45.3108 56.2723 45.3688 56.3755 45.4721C56.4788 45.5754 56.5368 45.7154 56.5368 45.8615C56.5368 46.0075 56.4788 46.1476 56.3755 46.2509C56.2723 46.3542 56.1322 46.4122 55.9862 46.4122Z"
          fill="#4A4C64"
        />
        <path
          d="M55.9862 49.3033H41.0141C40.868 49.3033 40.7279 49.2453 40.6247 49.142C40.5214 49.0387 40.4634 48.8987 40.4634 48.7526C40.4634 48.6065 40.5214 48.4665 40.6247 48.3632C40.7279 48.2599 40.868 48.2019 41.0141 48.2019H55.9862C56.1322 48.2019 56.2723 48.2599 56.3755 48.3632C56.4788 48.4665 56.5368 48.6065 56.5368 48.7526C56.5368 48.8987 56.4788 49.0387 56.3755 49.142C56.2723 49.2453 56.1322 49.3033 55.9862 49.3033Z"
          fill="#4A4C64"
        />
        <path
          d="M55.9862 52.1944H41.0141C40.868 52.1944 40.7279 52.1364 40.6247 52.0331C40.5214 51.9298 40.4634 51.7898 40.4634 51.6437C40.4634 51.4977 40.5214 51.3576 40.6247 51.2543C40.7279 51.151 40.868 51.093 41.0141 51.093H55.9862C56.1322 51.093 56.2723 51.151 56.3755 51.2543C56.4788 51.3576 56.5368 51.4977 56.5368 51.6437C56.5368 51.7898 56.4788 51.9298 56.3755 52.0331C56.2723 52.1364 56.1322 52.1944 55.9862 52.1944Z"
          fill="#4A4C64"
        />
        <path
          d="M55.9862 55.0855H41.0141C40.868 55.0855 40.7279 55.0275 40.6247 54.9242C40.5214 54.8209 40.4634 54.6809 40.4634 54.5348C40.4634 54.3888 40.5214 54.2487 40.6247 54.1454C40.7279 54.0422 40.868 53.9841 41.0141 53.9841H55.9862C56.1322 53.9841 56.2723 54.0422 56.3755 54.1454C56.4788 54.2487 56.5368 54.3888 56.5368 54.5348C56.5368 54.6809 56.4788 54.8209 56.3755 54.9242C56.2723 55.0275 56.1322 55.0855 55.9862 55.0855Z"
          fill="#4A4C64"
        />
        <path
          d="M55.9862 57.9766H41.0141C40.868 57.9766 40.7279 57.9186 40.6247 57.8153C40.5214 57.7121 40.4634 57.572 40.4634 57.4259C40.4634 57.2799 40.5214 57.1398 40.6247 57.0365C40.7279 56.9333 40.868 56.8752 41.0141 56.8752H55.9862C56.1322 56.8752 56.2723 56.9333 56.3755 57.0365C56.4788 57.1398 56.5368 57.2799 56.5368 57.4259C56.5368 57.572 56.4788 57.7121 56.3755 57.8153C56.2723 57.9186 56.1322 57.9766 55.9862 57.9766Z"
          fill="#4A4C64"
        />
        <path
          d="M27.8753 45.8613H37.6064V59.7893H26.6614V47.0753C26.6614 46.7533 26.7893 46.4445 27.0169 46.2169C27.2446 45.9892 27.5534 45.8613 27.8753 45.8613Z"
          fill="white"
        />
        <path
          d="M70.3386 59.7893H59.3935V45.8613H69.1247C69.4466 45.8613 69.7554 45.9892 69.9831 46.2168C70.2107 46.4445 70.3386 46.7533 70.3386 47.0752V59.7893Z"
          fill="white"
        />
        <path
          d="M38.3982 33.4248V39.5385C38.3982 39.5385 42.4931 37.5193 48.5 37.5193C54.5069 37.5193 58.6018 39.5385 58.6018 39.5385V33.4248H38.3982Z"
          fill="#4A4C64"
        />
        <path
          d="M62.887 35.1113H62.1297C61.9904 35.1129 61.8539 35.151 61.7339 35.2217C61.6138 35.2925 61.5144 35.3935 61.4456 35.5147H59.3587V35.878H61.3294C61.3286 35.8928 61.3209 35.9052 61.3209 35.9202V40.9108C61.3209 40.9257 61.3285 40.9382 61.3294 40.9529H58.0857V41.3162H61.4454C61.5143 41.4374 61.6137 41.5384 61.7338 41.6092C61.8538 41.68 61.9903 41.7181 62.1297 41.7197H62.887C63.1015 41.7197 63.3072 41.6345 63.4589 41.4828C63.6106 41.3311 63.6958 41.1254 63.6958 40.9108V35.9202C63.6958 35.7056 63.6106 35.4999 63.4589 35.3482C63.3072 35.1966 63.1015 35.1113 62.887 35.1113Z"
          fill="white"
        />
        <path
          d="M38.9142 40.9529H35.6706C35.6714 40.9382 35.679 40.9257 35.679 40.9108V35.9202C35.679 35.9052 35.6714 35.8928 35.6705 35.878H37.6412V35.5147H35.5544C35.4856 35.3935 35.3862 35.2925 35.2661 35.2218C35.1461 35.151 35.0096 35.1129 34.8703 35.1113H34.113C34.0068 35.1113 33.9016 35.1322 33.8035 35.1729C33.7054 35.2135 33.6162 35.2731 33.5411 35.3482C33.466 35.4233 33.4064 35.5125 33.3658 35.6106C33.3251 35.7088 33.3042 35.8139 33.3042 35.9202V40.9108C33.3042 41.1254 33.3894 41.3311 33.5411 41.4828C33.6928 41.6345 33.8985 41.7197 34.113 41.7197H34.8703C35.0096 41.7181 35.1461 41.68 35.2662 41.6092C35.3862 41.5384 35.4856 41.4374 35.5545 41.3162H38.9142V40.9529Z"
          fill="white"
        />
        <path
          d="M35.0251 51.3225C35.9566 51.3225 36.7116 50.5674 36.7116 49.636C36.7116 48.7045 35.9566 47.9495 35.0251 47.9495C34.0937 47.9495 33.3386 48.7045 33.3386 49.636C33.3386 50.5674 34.0937 51.3225 35.0251 51.3225Z"
          fill="#4A4C64"
        />
        <path
          d="M31.2908 50.4792C31.7565 50.4792 32.134 50.1017 32.134 49.636C32.134 49.1703 31.7565 48.7927 31.2908 48.7927C30.825 48.7927 30.4475 49.1703 30.4475 49.636C30.4475 50.1017 30.825 50.4792 31.2908 50.4792Z"
          fill="#4A4C64"
        />
        <path
          d="M61.9748 51.3225C62.9063 51.3225 63.6613 50.5674 63.6613 49.636C63.6613 48.7045 62.9063 47.9495 61.9748 47.9495C61.0434 47.9495 60.2883 48.7045 60.2883 49.636C60.2883 50.5674 61.0434 51.3225 61.9748 51.3225Z"
          fill="#4A4C64"
        />
        <path
          d="M65.7092 50.4792C66.1749 50.4792 66.5525 50.1017 66.5525 49.636C66.5525 49.1703 66.1749 48.7927 65.7092 48.7927C65.2435 48.7927 64.866 49.1703 64.866 49.636C64.866 50.1017 65.2435 50.4792 65.7092 50.4792Z"
          fill="#4A4C64"
        />
        <path
          d="M28.488 63.2771H68.5119C68.5308 63.2771 68.5489 63.2846 68.5622 63.298C68.5756 63.3113 68.5831 63.3294 68.5831 63.3483V64.5826C68.5831 64.6015 68.5756 64.6196 68.5622 64.633C68.5489 64.6464 68.5307 64.6539 68.5119 64.6539H28.488C28.4691 64.6539 28.451 64.6464 28.4376 64.633C28.4242 64.6197 28.4167 64.6015 28.4167 64.5827V63.3483C28.4167 63.339 28.4186 63.3297 28.4222 63.3211C28.4257 63.3124 28.431 63.3046 28.4376 63.298C28.4442 63.2913 28.4521 63.2861 28.4607 63.2825C28.4694 63.2789 28.4786 63.2771 28.488 63.2771Z"
          fill="#4A4C64"
        />
        <path
          d="M40.3428 29.484C40.3428 29.6091 40.3057 29.7314 40.2362 29.8354C40.1667 29.9394 40.0679 30.0204 39.9523 30.0683C39.8368 30.1162 39.7096 30.1287 39.5869 30.1043C39.4643 30.0799 39.3516 30.0196 39.2631 29.9312C39.1747 29.8428 39.1144 29.7301 39.09 29.6074C39.0656 29.4847 39.0782 29.3576 39.126 29.242C39.1739 29.1264 39.2549 29.0277 39.3589 28.9582C39.4629 28.8887 39.5852 28.8516 39.7103 28.8516C39.7934 28.8516 39.8756 28.8679 39.9523 28.8997C40.0291 28.9315 40.0988 28.9781 40.1575 29.0368C40.2163 29.0955 40.2629 29.1653 40.2946 29.242C40.3264 29.3187 40.3428 29.401 40.3428 29.484Z"
          fill="#4A4C64"
        />
        <path
          d="M42.8037 29.484C42.8037 29.6091 42.7666 29.7314 42.6971 29.8354C42.6276 29.9394 42.5288 30.0205 42.4133 30.0683C42.2977 30.1162 42.1705 30.1287 42.0478 30.1043C41.9252 30.0799 41.8125 30.0196 41.724 29.9312C41.6356 29.8427 41.5754 29.73 41.551 29.6074C41.5266 29.4847 41.5391 29.3575 41.587 29.2419C41.6348 29.1264 41.7159 29.0276 41.8199 28.9581C41.9239 28.8886 42.0462 28.8516 42.1713 28.8516C42.2543 28.8516 42.3366 28.8679 42.4133 28.8997C42.4901 28.9315 42.5598 28.9781 42.6185 29.0368C42.6772 29.0955 42.7238 29.1653 42.7556 29.242C42.7874 29.3187 42.8037 29.401 42.8037 29.484Z"
          fill="#4A4C64"
        />
        <path
          d="M56.6572 29.484C56.6572 29.6091 56.6943 29.7314 56.7638 29.8354C56.8333 29.9394 56.9321 30.0204 57.0477 30.0683C57.1632 30.1161 57.2904 30.1286 57.413 30.1042C57.5357 30.0798 57.6484 30.0196 57.7368 29.9311C57.8253 29.8427 57.8855 29.73 57.9099 29.6073C57.9343 29.4847 57.9218 29.3575 57.8739 29.2419C57.826 29.1264 57.745 29.0276 57.641 28.9581C57.537 28.8887 57.4147 28.8516 57.2896 28.8516C57.2066 28.8516 57.1243 28.8679 57.0476 28.8997C56.9709 28.9315 56.9012 28.9781 56.8424 29.0368C56.7837 29.0955 56.7371 29.1653 56.7053 29.242C56.6736 29.3187 56.6572 29.401 56.6572 29.484Z"
          fill="#4A4C64"
        />
        <path
          d="M54.196 29.484C54.1961 29.6091 54.2331 29.7314 54.3026 29.8354C54.3721 29.9394 54.4709 30.0204 54.5865 30.0683C54.702 30.1162 54.8292 30.1287 54.9519 30.1043C55.0746 30.0799 55.1872 30.0196 55.2757 29.9312C55.3641 29.8428 55.4244 29.7301 55.4488 29.6074C55.4732 29.4847 55.4607 29.3576 55.4128 29.242C55.3649 29.1264 55.2839 29.0277 55.1799 28.9582C55.0759 28.8887 54.9536 28.8516 54.8285 28.8516C54.7455 28.8516 54.6632 28.8679 54.5865 28.8997C54.5097 28.9315 54.44 28.9781 54.3813 29.0368C54.3226 29.0955 54.276 29.1653 54.2442 29.242C54.2124 29.3187 54.196 29.401 54.196 29.484Z"
          fill="#4A4C64"
        />
      </g>
      <defs>
        <clipPath id="clip0_2674_280">
          <rect width="96" height="96" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TruckRepair;
