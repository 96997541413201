const Stays = ({ size = '96px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 96 96" fill="none">
      <g clip-path="url(#clip0_2674_66)">
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="#4A4C64"
        />
        <path
          d="M63.6594 38.9968C63.6594 42.7842 62.875 46.8681 61.3277 51.1343C60.1012 54.5164 58.394 58.0235 56.2542 61.5588C52.6191 67.5624 49.0336 71.5264 48.8828 71.6921L48 72.6622L47.1173 71.6921C46.9664 71.5264 43.381 67.5624 39.7459 61.5588C37.6061 58.0235 35.8988 54.5164 34.6723 51.1343C33.125 46.8681 32.3406 42.7842 32.3406 38.9968C32.3406 34.8437 33.9904 30.8607 36.9271 27.9239C39.8638 24.9872 43.8469 23.3374 48 23.3374C52.1531 23.3374 56.1362 24.9872 59.0729 27.9239C62.0096 30.8607 63.6594 34.8437 63.6594 38.9968Z"
          fill="white"
        />
        <path
          d="M48 47.3769C52.6279 47.3769 56.3796 43.6252 56.3796 38.9973C56.3796 34.3694 52.6279 30.6177 48 30.6177C43.372 30.6177 39.6204 34.3694 39.6204 38.9973C39.6204 43.6252 43.372 47.3769 48 47.3769Z"
          fill="#4A4C64"
        />
      </g>
      <defs>
        <clipPath id="clip0_2674_66">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Stays;
