import React from 'react';

type CircleType = {
  strokeWidth: string;
};

const Circle = ({ strokeWidth }: CircleType) => {
  return (
    <svg className="circle__spinner" viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke={'#fff'}
        strokeDasharray="100"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Circle;
