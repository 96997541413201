import React, { useMemo } from 'react';

import SuccessIcon from '../../svgs/SuccessIcon';

import styles from './registerModalContent.module.scss';

const RegisterModalContent = ({
  onClick,
  activeCircle,
  modeSwitcher,
}: {
  onClick: () => void;
  activeCircle: string;
  modeSwitcher: boolean;
}) => {
  const Content = useMemo(() => {
    return {
      Carrier: (
        <div className={`${styles['contentWrapper']}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Sign Up With My Broker</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Sign Up With FreightMax</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Load Board</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Post My Truck</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Factoring</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Find Vendors</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Truck repair & Shops</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Towing & Recovery</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Tire Sales & Repair</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Trailer Repair & Refrigeration unit
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Truck Stops & Rest Areas
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Rentals</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Find Vendors</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Truck Wash</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Fuel Stops/ Gas Stations
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Stays</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Scales & Weigh Stations
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Compliance & IRP</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Warehousing & Crossdocs
              </div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              Carrier App (Description of app services and QR Code to App Store)
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      Broker: (
        <div className={`${styles['contentWrapper']}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Safety & Compliance</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Complete Carrier Monitoring
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Vin Verification</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Insurance Verification & Monitoring
              </div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              Carrier Rating & Performance
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              Broker Carrier Setup Portal
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              24/7 Load Dispatch Services
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Truckboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Loadboard & Posting</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Find Vendors</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Complete Broker TMS</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Accounting / Agency Finance
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Aging AR & AP</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Analytics</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Auto Rate Con Generation
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Customer Credit Check</div>
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      Dispatcher: (
        <div className={`${styles['contentWrapper']}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Vetted Carrier Database</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles.bigText} ${modeSwitcher ? styles.light : ''}`}>Safety & Compliance</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              Carrier Rating & Performance
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Truckboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Loadboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Dispatching TMS</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>AR With Aging</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Accounting / Agency Finance
              </div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Analytics</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Customer Credit Check </div>
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      Shipper: (
        <div className={`${styles['contentWrapper']}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Vetted Carrier Database</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Online Shipment Quote</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Post Your Own Load </div>
          </div>

          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>
                Load Dispatching Services
              </div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Find a Reputable Broker</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Find Industry Vendors</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      'Factoring company': (
        <div className={`${styles['contentWrapper']}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>
              Find Vetted Carriers & Brokers
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Client Management</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Credit Monitoring</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Factoring TMS</div>
          </div>

          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'8px'} height={'5px'} />
              </div>
              <div className={`${styles['smallText']} ${modeSwitcher ? styles.light : ''}`}>Aging AR & AP </div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']} ${modeSwitcher ? styles.light : ''}`}>Analytics</div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
    };
  }, []);
  return <div>{Content[activeCircle]}</div>;
};

export default RegisterModalContent;
