const FindVendors = ({ size = '96px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 97 96" fill="none">
      <g clip-path="url(#clip0_2674_318)">
        <path
          d="M48.5 95.9912C75.0047 95.9912 96.491 74.5049 96.491 48.0002C96.491 21.4956 75.0047 0.00927734 48.5 0.00927734C21.9953 0.00927734 0.509033 21.4956 0.509033 48.0002C0.509033 74.5049 21.9953 95.9912 48.5 95.9912Z"
          fill="#4A4C64"
        />
        <path
          d="M49.0596 41.3442V38.7937C49.0591 37.4211 48.5134 36.1049 47.5427 35.1346C46.5719 34.1642 45.2555 33.6191 43.883 33.6191C42.5104 33.6191 41.194 34.1642 40.2233 35.1346C39.2525 36.1049 38.7069 37.4211 38.7063 38.7937V41.3442C38.7069 42.7167 39.2525 44.0329 40.2233 45.0032C41.194 45.9736 42.5104 46.5187 43.883 46.5187C45.2555 46.5187 46.5719 45.9736 47.5427 45.0032C48.5134 44.0329 49.0591 42.7167 49.0596 41.3442Z"
          fill="white"
        />
        <path
          d="M33.6572 54.0779C36.4012 56.7288 40.0674 58.2104 43.8828 58.2104C47.6981 58.2104 51.3643 56.7288 54.1083 54.0779L53.303 50.6851C53.2084 50.2858 53.0287 49.9115 52.7762 49.588C52.5237 49.2644 52.2043 48.9992 51.84 48.8103L47.2755 46.4427C46.2699 47.1103 45.0897 47.4663 43.8827 47.4662C42.6757 47.4662 41.4956 47.11 40.4901 46.4424L35.9256 48.8103C35.5612 48.9992 35.2419 49.2644 34.9894 49.588C34.7369 49.9115 34.5572 50.2858 34.4625 50.6851L33.6572 54.0779Z"
          fill="white"
        />
        <path
          d="M29.7087 57.5569C26.4294 54.2777 24.3887 49.9628 23.9342 45.3476C23.4797 40.7324 24.6394 36.1024 27.2159 32.2464C29.7924 28.3905 33.6262 25.5472 38.0641 24.201C42.5019 22.8548 47.2692 23.089 51.5538 24.8638C55.8383 26.6385 59.3749 29.8439 61.561 33.9338C63.7471 38.0238 64.4474 42.7452 63.5427 47.2936C62.638 51.8421 60.1841 55.9361 56.5992 58.8781C53.0144 61.8201 48.5203 63.4281 43.8828 63.4281C41.2493 63.4353 38.6406 62.9201 36.2076 61.9123C33.7746 60.9045 31.5657 59.4241 29.7087 57.5569ZM32.2897 31.7897C29.6076 34.4718 27.9384 38.0009 27.5666 41.7758C27.1948 45.5506 28.1434 49.3376 30.2507 52.4914C32.3581 55.6452 35.4938 57.9708 39.1235 59.0719C42.7533 60.173 46.6525 59.9814 50.1569 58.5299C53.6613 57.0783 56.5539 54.4566 58.342 51.1114C60.13 47.7662 60.7028 43.9044 59.9628 40.1842C59.2228 36.464 57.2158 33.1155 54.2837 30.7092C51.3516 28.3029 47.6759 26.9877 43.8828 26.9877C41.7289 26.9818 39.5952 27.4032 37.6052 28.2274C35.6153 29.0517 33.8086 30.2625 32.2897 31.7897Z"
          fill="white"
        />
        <path
          d="M57.5718 60.2272L69.6574 72.3121C69.7684 72.4231 69.9002 72.5112 70.0453 72.5713C70.1904 72.6314 70.3459 72.6623 70.5029 72.6623C70.66 72.6623 70.8155 72.6314 70.9605 72.5713C71.1056 72.5112 71.2374 72.4231 71.3485 72.3121L72.8121 70.8485C72.9231 70.7374 73.0112 70.6056 73.0713 70.4605C73.1314 70.3155 73.1623 70.16 73.1623 70.0029C73.1623 69.8459 73.1314 69.6904 73.0713 69.5453C73.0112 69.4002 72.9231 69.2684 72.8121 69.1573L60.7272 57.0718C59.7868 58.2294 58.7294 59.2868 57.5718 60.2272Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2674_318">
          <rect width="96" height="96" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FindVendors;
