import { Controller, PathValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { ClientRequestTypes, findTitleById, formatPhone, onlyNumbersFormatter } from 'helpers';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import CustomInput from 'ui/input/Input';
import StateSelect from 'ui/state-select/StateSelect';
import CustomTextArea from 'ui/textarea/Textarea';

import { schema } from './validationSchema';

import styles from './styles.module.scss';

const FactoringCompany = ({ onClose, statesData }) => {
  const { api }: any = window.global;

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    trigger,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = data => {
    axios
      .post(`${api}/auth/client/request`, {
        type: ClientRequestTypes.CLIENT_REQUEST_TYPE_FACTORING_COMPANY,
        companyName: data.companyName,
        contactName: data.contactName,
        phone: data.phoneNumber,
        email: data.email,
        ...(data.address ? { address: data.address } : {}),
        ...(data.zipCode ? { zip: data.zipCode } : {}),
        ...(data.city ? { city: data.city } : {}),
        ...(data.state ? { state: findTitleById(statesData, data?.state) } : {}),
        ...(data.comments ? { tellUsMore: data.comments } : {}),
      })
      .then(() => {
        reset();
        onClose();
        window.location.href = 'https://app.freightmax.com/factoring-company/thank-you/';
      })
      .catch(() => {
        setError('email', { type: 'validate', message: 'Invalid email format' });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      <div className={styles.row}>
        <CustomInput
          name="companyName"
          control={control}
          rules={{ required: true }}
          placeholder="Company name"
          getValues={getValues}
          errors={errors}
          required
        />
        <CustomInput
          name="contactName"
          control={control}
          rules={{ required: true }}
          placeholder="Contact name"
          getValues={getValues}
          errors={errors}
          required
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="phoneNumber"
          control={control}
          rules={{ required: true }}
          placeholder="Phone number"
          onChange={(e: any) =>
            setValue('phoneNumber', formatPhone(e.target.value), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
          getValues={getValues}
          errors={errors}
          required
        />
        <CustomInput
          name="email"
          control={control}
          rules={{ required: true }}
          placeholder="Email"
          getValues={getValues}
          errors={errors}
          required
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="address"
          control={control}
          rules={{ required: false }}
          placeholder="Address"
          getValues={getValues}
          errors={errors}
        />
        <CustomInput
          name="zipCode"
          control={control}
          rules={{ required: false }}
          placeholder="Zip Code"
          getValues={getValues}
          onChange={(e: any) =>
            setValue('zipCode', onlyNumbersFormatter(e.target.value), {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
          errors={errors}
        />
      </div>
      <div className={styles.row}>
        <CustomInput
          name="city"
          control={control}
          rules={{ required: false }}
          placeholder="City"
          getValues={getValues}
          errors={errors}
        />
        <Controller
          name="state"
          control={control}
          rules={{ required: false }}
          render={({ field }) => {
            return (
              <StateSelect
                allowClear
                options={statesData}
                placeholder="State"
                name={field.name}
                errors={errors}
                onBlur={() => trigger('state')}
                onChange={(value: string) =>
                  setValue('state', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={field.value}
              />
            );
          }}
        />
        <ErrorMessage fieldError={errors?.state} />
      </div>
      <div>
        <CustomTextArea
          autoSize
          label="Tell us more..."
          isFilled={!!watch('comments')}
          name="comments"
          control={control}
          rules={{ required: false }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue('comments', e.target.value as PathValue<string, string>, { shouldDirty: false })
          }
        />
      </div>
      <div className={styles.buttonBlock}>
        <button onClick={onClose} className={styles.cancel}>
          Cancel
        </button>
        <button onClick={handleSubmit(onSubmit)} className={styles.send}>
          Send
        </button>
      </div>
    </form>
  );
};

export default FactoringCompany;
