import React from 'react';

const LoginIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path
        d="M9.39876 16.3565V15.2545H14.7437V4.23389H9.39876V3.13184H14.7437C15.0376 3.13184 15.2948 3.24204 15.5152 3.46245C15.7356 3.68287 15.8458 3.94001 15.8458 4.23389V15.2545C15.8458 15.5484 15.7356 15.8055 15.5152 16.0259C15.2948 16.2463 15.0376 16.3565 14.7437 16.3565H9.39876ZM7.96608 12.9585L7.17627 12.1687L9.04977 10.2952H2.62109V9.19316H9.01303L7.13953 7.31966L7.92934 6.52985L11.162 9.76256L7.96608 12.9585Z"
        fill="white"
      />
    </svg>
  );
};

export default LoginIcon;
