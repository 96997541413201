import React, { forwardRef, LegacyRef, useEffect, useState } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxChangeEvent, CheckboxRef } from 'antd/es/checkbox';
import clsx from 'clsx';

import { ICheckboxProps } from './constants/types';

import styles from './Checkbox.module.scss';

const Checkbox = forwardRef(
  (
    { checked = false, onChange, children, size, ...props }: ICheckboxProps,
    ref: LegacyRef<CheckboxRef> | undefined
  ) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = ({ target: { checked } }: CheckboxChangeEvent) => {
      setIsChecked(checked);
      if (onChange) {
        onChange(checked);
      }
    };

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    return (
      <div className={clsx(styles.checkbox, [styles[size]])}>
        <AntCheckbox ref={ref} checked={isChecked} onChange={handleChange} {...props}>
          {children}
        </AntCheckbox>
      </div>
    );
  }
);

export default Checkbox;
