/* eslint-disable @typescript-eslint/ban-ts-comment */

export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegExp = /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10}(\s*)?/;

export const onlyNumbersFormatter = (value: any) => value?.replace(/[-\s]/g, '').replace(/\D/g, '');

export const formatPhone = (phone: string) => {
  if (!phone) return phone;
  const value = onlyNumbersFormatter(phone);
  const formatedPhone = value.replace(/[^\d]/g, '');
  const phoneLenght = formatedPhone.length;
  if (phoneLenght < 4) return formatedPhone;
  if (phoneLenght < 7) {
    return `${formatedPhone.slice(0, 3)}-${formatedPhone.slice(3)}`;
  }
  return `${formatedPhone.slice(0, 3)}-${formatedPhone.slice(3, 6)}-${formatedPhone.slice(6)}`;
};

export const whiteSpaceFormatter = (value: string) => value?.replace(/\s/g, '');
export const getHighlightedOptions = (phaseWords: string, fullWords = '') => {
  const res: string | number[] = [];
  const result = [];
  const words = String(fullWords);
  const phase = phaseWords?.at(-1) === ' ' ? phaseWords?.trim() : phaseWords;
  for (let i = 0, j = phase?.length; i < words?.length; i++) {
    const firstIndex = i;
    while (j > 0) {
      if (words[i]?.toLocaleLowerCase() !== String(phase[phase.length - j])?.toLocaleLowerCase()) break;
      j--;
      i++;
    }
    if (j === 0) {
      res.push(i - phase.length, i - 1);
      // @ts-ignore
      result.push(words.slice(0, res[0]));
      if (res[1] === words.length - 1) {
        // @ts-ignore
        result.push(words.slice(res[0]));
      } else {
        // @ts-ignore
        result.push(words.slice(res[0], res[1] + 1));
        // @ts-ignore
        result.push(words.slice(res[1] + 1, words.length));
      }
      return result;
    }
    i = firstIndex;
    j = phase?.length;
  }

  return res;
};

export interface IState {
  id: number;
  name: string;
  regionId: number;
}

export interface IStateRegion {
  id: number;
  name: string;
}

export interface IStatesWithRegion {
  region: IStateRegion;
  state: Array<IState>;
}

export const transformStatesData = (data: Array<IStatesWithRegion>) => {
  return data
    ?.map((item: IStatesWithRegion) => {
      return {
        title: item.region.name,
        value: `${item.region.id}${item.region.name}`,
        key: `${item.region.id}${item.region.name}`,
        children: item.state?.map((state: IState) => {
          return {
            title: state.name,
            value: String(state.id),
            key: String(state.id),
          };
        }),
      };
    })
    .slice(0, 9);
};

export enum ClientRequestTypes {
  CLIENT_REQUEST_TYPE_BROKER = 1,
  CLIENT_REQUEST_TYPE_DISPATCHER = 2,
  CLIENT_REQUEST_TYPE_SHIPPER = 3,
  CLIENT_REQUEST_TYPE_FACTORING_COMPANY = 4,
}

export enum LoadTypeSelect {
  LOAD_TYPE_SELECT_FTL = 1,
  LOAD_TYPE_SELECT_LTL = 2,
}

export enum TruckTypeClient {
  TRUCK_TYPE_CLIENT_DRY_VAN = 1,
  TRUCK_TYPE_CLIENT_CONESTOGA = 2,
  TRUCK_TYPE_CLIENT_LOW_BOY = 3,
  TRUCK_TYPE_CLIENT_CURTAIN_VAN = 4,
  TRUCK_TYPE_CLIENT_REEFER = 5,
  TRUCK_TYPE_CLIENT_STEP_DECK = 6,
  TRUCK_TYPE_CLIENT_HOT_SHOT = 7,
  TRUCK_TYPE_CLIENT_FLAT_BED = 8,
  TRUCK_TYPE_CLIENT_SPRINTER_VAN = 9,
  TRUCK_TYPE_CLIENT_BOX_TRUCK = 10,
}

export interface ClientRequestParams {
  email: string;
  type: ClientRequestTypes;
  companyName: string;
  contactName: string;
  phone: string;
  address?: string;
  zip?: string;
  city?: string;
  state?: string;
  tellUsMore?: string;
  pickUpCity?: string;
  pickUpState?: string;
  deliveryCity?: string;
  deliveryState?: string;
  commodity?: string;
  loadType?: LoadTypeSelect;
  weight?: string;
  temperature?: string;
  palletCount?: string;
  truckType?: Array<TruckTypeClient>;
}

export const findTitleById = (data, id) => {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.value === id) {
      return item.title;
    }
    if (item.children) {
      const title = findTitleById(item.children, id);
      if (title) {
        return title;
      }
    }
  }
  return null;
};
