import React, { useMemo } from 'react';
import SuccessIcon from 'svgs/SuccessIcon';

import styles from './registerModalMobile.module.scss';

const RegisterModalMobile = ({
  onClick,
  activeCircle,
  lightMode,
}: {
  onClick: () => void;
  activeCircle: string;
  lightMode: boolean;
}) => {
  const Content = useMemo(() => {
    return {
      1: (
        <div className={`${styles['contentWrapper']} ${lightMode ? styles['lightMode'] : ''}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Sign Up With My Broker</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Sign Up With FreightMax</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Load Board</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Post My Truck</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Factoring</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Find Vendors</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Truck repair & Shops</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Towing & Recovery</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Tire Sales & Repair</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Trailer Repair & Refrigeration unit</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Truck Stops & Rest Areas</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Rentals</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Find Vendors</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Truck Wash</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Fuel Stops/ Gas Stations</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Stays</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Scales & Weigh Stations</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Compliance & IRP</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Warehousing & Crossdocs</div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>
              Carrier App( Description of app services and <br />
              QR Code to App Store)
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      2: (
        <div className={`${styles['contentWrapper']} ${lightMode ? styles['lightMode'] : ''}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Safety & Compliance</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Complete Carrier Monitoring</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Vin Verification</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Insurance Verification & Monitoring</div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Carrier Rating & Performance</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Broker Carrier Setup Portal</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>24/7 Load Dispatch Services</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Truckboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Loadboard & Posting</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Find Vendors</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Complete Broker TMS</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Accounting / Agency Finance</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Aging AR & AP</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Analytics</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Auto Rate Con Generation</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Customer Credit Check</div>
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      3: (
        <div className={`${styles['contentWrapper']} ${lightMode ? styles['lightMode'] : ''}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Vetted Carrier Database</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Safety & Compliance</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Carrier Rating & Performance</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Truckboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Loadboard</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Dispatching TMS</div>
          </div>
          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>AR With Aging</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Accounting / Agency Finance</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Analytics</div>
            </div>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Customer Credit Check </div>
            </div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      4: (
        <div className={`${styles['contentWrapper']} ${lightMode ? styles['lightMode'] : ''}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Vetted Carrier Database</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Online Shipment Quote</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Post Your Own Load </div>
          </div>

          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Load Dispatching Services</div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Find a Reputable Broker</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Find Industry Vendors</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Live Load Tracking</div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
      5: (
        <div className={`${styles['contentWrapper']} ${lightMode ? styles['lightMode'] : ''}`}>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Find Vetted Carriers & Brokers</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Client Management</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Credit Monitoring</div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Factoring TMS</div>
          </div>

          <div className={`${styles['smallTextWrapper']}`}>
            <div className={`${styles['smallItem']}`}>
              <div className={`${styles['smallSuccess']}`}>
                <SuccessIcon width={'9px'} height={'6px'} />
              </div>
              <div className={`${styles['smallText']}`}>Aging AR & AP </div>
            </div>
          </div>
          <div className={`${styles['bigItem']}`}>
            <div className={`${styles['bigSuccess']}`}>
              <SuccessIcon width={'9px'} height={'6px'} />
            </div>
            <div className={`${styles['bigText']}`}>Analytics</div>
          </div>
          <div className={`${styles['btnWrapper']}`}>
            <button onClick={onClick} className={`${styles['btn']}`}>
              Continue
            </button>
          </div>
        </div>
      ),
    };
  }, [lightMode]);
  return <div>{Content[activeCircle]}</div>;
};

export default RegisterModalMobile;
