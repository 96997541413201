const Scales = ({ size = '96px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 97 96" fill="none">
      <g clip-path="url(#clip0_2674_112)">
        <path
          d="M48.5 96C75.0097 96 96.5 74.5097 96.5 48C96.5 21.4903 75.0097 0 48.5 0C21.9903 0 0.5 21.4903 0.5 48C0.5 74.5097 21.9903 96 48.5 96Z"
          fill="#4A4C64"
        />
        <path
          d="M67.5142 53.8325H29.4859C29.2333 53.8325 28.9911 53.9329 28.8124 54.1115C28.6338 54.2901 28.5335 54.5324 28.5334 54.785V59.2591C28.5335 59.5117 28.6338 59.754 28.8124 59.9326C28.991 60.1112 29.2333 60.2116 29.4859 60.2116H30.477C30.5956 60.9127 30.9585 61.5491 31.5013 62.0084C32.0441 62.4676 32.7319 62.7199 33.4429 62.7207H37.1729C37.8839 62.7199 38.5716 62.4675 39.1144 62.0083C39.6572 61.5491 40.02 60.9127 40.1386 60.2116H56.8615C56.9801 60.9127 57.343 61.5491 57.8858 62.0083C58.4286 62.4676 59.1163 62.7199 59.8273 62.7207H63.5574C64.2684 62.7199 64.9561 62.4675 65.4989 62.0083C66.0417 61.5491 66.4045 60.9127 66.5231 60.2116H67.5142C67.6393 60.2116 67.7631 60.187 67.8787 60.1391C67.9943 60.0913 68.0993 60.0211 68.1878 59.9327C68.2762 59.8442 68.3464 59.7392 68.3943 59.6236C68.4421 59.5081 68.4668 59.3842 68.4668 59.2591V54.785C68.4668 54.6599 68.4421 54.536 68.3943 54.4205C68.3464 54.3049 68.2762 54.1999 68.1878 54.1115C68.0993 54.023 67.9943 53.9529 67.8787 53.905C67.7631 53.8571 67.6393 53.8325 67.5142 53.8325Z"
          fill="white"
        />
        <path
          d="M58.1664 23.4287L56.8998 24.5449C56.7276 24.6966 56.5896 24.8834 56.4952 25.0926C56.4007 25.3018 56.3519 25.5288 56.3519 25.7583V27.1603C54.8913 26.7703 52.1258 26.1944 48.4999 26.1944C44.8739 26.1944 42.1084 26.7703 40.6477 27.1603V25.7583C40.6478 25.5288 40.599 25.3018 40.5045 25.0926C40.41 24.8834 40.2721 24.6966 40.0998 24.5449L38.8333 23.4287L38.0676 24.2978L39.3343 25.414C39.3832 25.457 39.4223 25.51 39.4492 25.5694C39.476 25.6287 39.4898 25.6932 39.4897 25.7583V30.857H39.4901V36.1778C39.4907 36.4443 39.5885 36.7015 39.7653 36.901C39.9421 37.1004 40.1856 37.2285 40.4501 37.261V53.0178H56.5496V37.261C56.8142 37.2285 57.0577 37.1004 57.2344 36.9009C57.4112 36.7015 57.509 36.4443 57.5095 36.1778V30.857H57.51V25.7583C57.5099 25.6932 57.5237 25.6287 57.5505 25.5693C57.5773 25.51 57.6165 25.457 57.6655 25.414L58.932 24.2978L58.1664 23.4287Z"
          fill="white"
        />
        <path
          d="M54.4654 39.5051H42.5348C42.4771 39.5051 42.4201 39.4937 42.3668 39.4717C42.3136 39.4496 42.2652 39.4173 42.2245 39.3766C42.1837 39.3358 42.1514 39.2874 42.1293 39.2342C42.1073 39.181 42.0959 39.1239 42.0959 39.0663C42.0959 38.9499 42.1422 38.8383 42.2245 38.756C42.3068 38.6737 42.4184 38.6274 42.5348 38.6274H54.4654C54.5818 38.6274 54.6934 38.6737 54.7757 38.756C54.858 38.8383 54.9043 38.9499 54.9043 39.0663C54.9043 39.1239 54.8929 39.181 54.8709 39.2342C54.8488 39.2874 54.8165 39.3358 54.7757 39.3766C54.735 39.4173 54.6866 39.4496 54.6334 39.4717C54.5801 39.4937 54.5231 39.5051 54.4654 39.5051Z"
          fill="#4A4C64"
        />
        <path
          d="M54.4654 41.8093H42.5348C42.4184 41.8093 42.3068 41.7631 42.2245 41.6808C42.1422 41.5985 42.0959 41.4868 42.0959 41.3705C42.0959 41.2541 42.1422 41.1425 42.2245 41.0602C42.3068 40.9779 42.4184 40.9316 42.5348 40.9316H54.4654C54.5818 40.9316 54.6934 40.9779 54.7757 41.0602C54.858 41.1425 54.9043 41.2541 54.9043 41.3705C54.9043 41.4868 54.858 41.5985 54.7757 41.6808C54.6934 41.7631 54.5818 41.8093 54.4654 41.8093Z"
          fill="#4A4C64"
        />
        <path
          d="M54.4654 44.113H42.5348C42.4184 44.113 42.3068 44.0668 42.2245 43.9845C42.1422 43.9022 42.0959 43.7906 42.0959 43.6742C42.0959 43.5578 42.1422 43.4462 42.2245 43.3639C42.3068 43.2816 42.4184 43.2354 42.5348 43.2354H54.4654C54.5818 43.2354 54.6934 43.2816 54.7757 43.3639C54.858 43.4462 54.9043 43.5578 54.9043 43.6742C54.9043 43.7906 54.858 43.9022 54.7757 43.9845C54.6934 44.0668 54.5818 44.113 54.4654 44.113Z"
          fill="#4A4C64"
        />
        <path
          d="M54.4654 46.4167H42.5348C42.4184 46.4167 42.3068 46.3705 42.2245 46.2882C42.1422 46.2059 42.0959 46.0943 42.0959 45.9779C42.0959 45.8615 42.1422 45.7499 42.2245 45.6676C42.3068 45.5853 42.4184 45.5391 42.5348 45.5391H54.4654C54.5818 45.5391 54.6934 45.5853 54.7757 45.6676C54.858 45.7499 54.9043 45.8615 54.9043 45.9779C54.9043 46.0943 54.858 46.2059 54.7757 46.2882C54.6934 46.3705 54.5818 46.4167 54.4654 46.4167Z"
          fill="#4A4C64"
        />
        <path
          d="M54.4654 48.7209H42.5348C42.4184 48.7209 42.3068 48.6747 42.2245 48.5924C42.1422 48.5101 42.0959 48.3985 42.0959 48.2821C42.0959 48.1657 42.1422 48.0541 42.2245 47.9718C42.3068 47.8895 42.4184 47.8433 42.5348 47.8433H54.4654C54.5818 47.8433 54.6934 47.8895 54.7757 47.9718C54.858 48.0541 54.9043 48.1657 54.9043 48.2821C54.9043 48.3985 54.858 48.5101 54.7757 48.5924C54.6934 48.6747 54.5818 48.7209 54.4654 48.7209Z"
          fill="#4A4C64"
        />
        <path
          d="M54.4654 51.0246H42.5348C42.4184 51.0246 42.3068 50.9784 42.2245 50.8961C42.1422 50.8138 42.0959 50.7022 42.0959 50.5858C42.0959 50.4694 42.1422 50.3578 42.2245 50.2755C42.3068 50.1932 42.4184 50.147 42.5348 50.147H54.4654C54.5818 50.147 54.6934 50.1932 54.7757 50.2755C54.858 50.3578 54.9043 50.4694 54.9043 50.5858C54.9043 50.7022 54.858 50.8138 54.7757 50.8961C54.6934 50.9784 54.5818 51.0246 54.4654 51.0246Z"
          fill="#4A4C64"
        />
        <path
          d="M32.3116 41.3701H39.8193V52.4688H31.0977V42.5841C31.0977 42.2621 31.2256 41.9533 31.4532 41.7257C31.6809 41.498 31.9896 41.3701 32.3116 41.3701Z"
          fill="white"
        />
        <path
          d="M65.9023 52.4692H57.1806V41.3706H64.6884C65.0104 41.3706 65.3191 41.4984 65.5468 41.7261C65.7744 41.9538 65.9023 42.2625 65.9023 42.5845V52.4692Z"
          fill="white"
        />
        <path
          d="M40.4502 31.46V36.3317C40.4502 36.3317 43.7133 34.7227 48.4999 34.7227C53.2866 34.7227 56.5497 36.3317 56.5497 36.3317V31.46H40.4502Z"
          fill="#4A4C64"
        />
        <path
          d="M59.9644 32.8042H59.361C59.2499 32.8054 59.1412 32.8358 59.0455 32.8922C58.9498 32.9486 58.8706 33.0291 58.8158 33.1256H57.1528V33.4151H58.7232C58.7226 33.4269 58.7164 33.4368 58.7164 33.4487V37.4256C58.7164 37.4375 58.7225 37.4474 58.7232 37.4591H56.1384V37.7486H58.8156C58.8705 37.8452 58.9497 37.9257 59.0454 37.9821C59.1411 38.0385 59.2499 38.0689 59.361 38.0702H59.9644C60.1353 38.0702 60.2992 38.0022 60.4201 37.8814C60.541 37.7605 60.6089 37.5965 60.6089 37.4256V33.4487C60.6089 33.2778 60.541 33.1139 60.4201 32.993C60.2992 32.8721 60.1353 32.8042 59.9644 32.8042Z"
          fill="white"
        />
        <path
          d="M40.8615 37.4591H38.2768C38.2774 37.4474 38.2835 37.4375 38.2835 37.4256V33.4487C38.2835 33.4368 38.2774 33.4269 38.2767 33.4151H39.8471V33.1256H38.1842C38.1293 33.0291 38.0501 32.9486 37.9545 32.8922C37.8588 32.8358 37.7501 32.8055 37.639 32.8042H37.0356C36.951 32.8042 36.8672 32.8209 36.789 32.8533C36.7108 32.8856 36.6397 32.9331 36.5799 32.993C36.52 33.0528 36.4726 33.1239 36.4402 33.2021C36.4078 33.2803 36.3911 33.3641 36.3911 33.4487V37.4256C36.3911 37.5965 36.459 37.7605 36.5799 37.8814C36.7008 38.0022 36.8647 38.0702 37.0356 38.0702H37.639C37.7501 38.0689 37.8589 38.0386 37.9545 37.9821C38.0502 37.9257 38.1294 37.8452 38.1843 37.7486H40.8615V37.4591Z"
          fill="white"
        />
        <path
          d="M37.7624 45.722C38.5046 45.722 39.1063 45.1203 39.1063 44.3781C39.1063 43.6359 38.5046 43.0342 37.7624 43.0342C37.0201 43.0342 36.4185 43.6359 36.4185 44.3781C36.4185 45.1203 37.0201 45.722 37.7624 45.722Z"
          fill="#4A4C64"
        />
        <path
          d="M34.7867 45.05C35.1578 45.05 35.4587 44.7491 35.4587 44.378C35.4587 44.0069 35.1578 43.7061 34.7867 43.7061C34.4156 43.7061 34.1147 44.0069 34.1147 44.378C34.1147 44.7491 34.4156 45.05 34.7867 45.05Z"
          fill="#4A4C64"
        />
        <path
          d="M59.2375 45.722C59.9797 45.722 60.5814 45.1203 60.5814 44.3781C60.5814 43.6359 59.9797 43.0342 59.2375 43.0342C58.4952 43.0342 57.8936 43.6359 57.8936 44.3781C57.8936 45.1203 58.4952 45.722 59.2375 45.722Z"
          fill="#4A4C64"
        />
        <path
          d="M62.2135 45.05C62.5846 45.05 62.8854 44.7491 62.8854 44.378C62.8854 44.0069 62.5846 43.7061 62.2135 43.7061C61.8424 43.7061 61.5415 44.0069 61.5415 44.378C61.5415 44.7491 61.8424 45.05 62.2135 45.05Z"
          fill="#4A4C64"
        />
        <path
          d="M32.5678 55.248H64.4323C64.4512 55.248 64.4693 55.2555 64.4827 55.2689C64.496 55.2823 64.5035 55.3004 64.5035 55.3193V56.2739C64.5035 56.2928 64.496 56.3109 64.4827 56.3243C64.4693 56.3376 64.4512 56.3451 64.4323 56.3451H32.5678C32.5584 56.3451 32.5492 56.3433 32.5405 56.3397C32.5319 56.3361 32.5241 56.3309 32.5174 56.3243C32.5108 56.3177 32.5056 56.3098 32.502 56.3012C32.4984 56.2925 32.4966 56.2833 32.4966 56.2739V55.3193C32.4966 55.3004 32.5041 55.2823 32.5174 55.2689C32.5308 55.2555 32.5489 55.248 32.5678 55.248Z"
          fill="#4A4C64"
        />
        <path
          d="M41.9999 28.3199C41.9999 28.4196 41.9703 28.517 41.9149 28.5999C41.8596 28.6828 41.7808 28.7474 41.6888 28.7855C41.5967 28.8236 41.4953 28.8336 41.3976 28.8142C41.2998 28.7947 41.21 28.7467 41.1396 28.6762C41.0691 28.6058 41.0211 28.516 41.0016 28.4182C40.9822 28.3204 40.9922 28.2191 41.0303 28.127C41.0684 28.0349 41.133 27.9562 41.2159 27.9009C41.2988 27.8455 41.3962 27.8159 41.4959 27.8159C41.5621 27.8159 41.6276 27.8289 41.6888 27.8543C41.7499 27.8796 41.8055 27.9167 41.8523 27.9635C41.8991 28.0103 41.9362 28.0659 41.9615 28.127C41.9868 28.1882 41.9999 28.2537 41.9999 28.3199Z"
          fill="#4A4C64"
        />
        <path
          d="M43.9608 28.3199C43.9608 28.4196 43.9312 28.517 43.8759 28.5999C43.8205 28.6828 43.7418 28.7474 43.6497 28.7855C43.5576 28.8236 43.4563 28.8336 43.3585 28.8142C43.2607 28.7947 43.1709 28.7467 43.1005 28.6762C43.03 28.6057 42.982 28.5159 42.9626 28.4182C42.9431 28.3204 42.9531 28.2191 42.9913 28.127C43.0294 28.0349 43.094 27.9562 43.1769 27.9008C43.2598 27.8455 43.3572 27.8159 43.4569 27.8159C43.5231 27.8159 43.5886 27.829 43.6497 27.8543C43.7109 27.8796 43.7664 27.9167 43.8132 27.9635C43.86 28.0103 43.8971 28.0659 43.9225 28.127C43.9478 28.1882 43.9608 28.2537 43.9608 28.3199Z"
          fill="#4A4C64"
        />
        <path
          d="M55 28.3199C55 28.4196 55.0296 28.517 55.085 28.5999C55.1403 28.6827 55.219 28.7473 55.3111 28.7855C55.4032 28.8236 55.5045 28.8336 55.6023 28.8141C55.7 28.7947 55.7898 28.7467 55.8603 28.6762C55.9308 28.6057 55.9788 28.5159 55.9982 28.4182C56.0176 28.3204 56.0077 28.2191 55.9695 28.127C55.9314 28.0349 55.8668 27.9562 55.7839 27.9008C55.701 27.8455 55.6036 27.8159 55.5039 27.8159C55.4378 27.8159 55.3722 27.829 55.3111 27.8543C55.2499 27.8796 55.1944 27.9167 55.1476 27.9635C55.1008 28.0103 55.0637 28.0659 55.0383 28.127C55.013 28.1882 55 28.2537 55 28.3199Z"
          fill="#4A4C64"
        />
        <path
          d="M53.0391 28.3199C53.0391 28.4196 53.0686 28.517 53.124 28.5999C53.1794 28.6828 53.2581 28.7474 53.3502 28.7855C53.4423 28.8236 53.5436 28.8336 53.6414 28.8142C53.7391 28.7947 53.8289 28.7467 53.8994 28.6762C53.9699 28.6058 54.0179 28.516 54.0373 28.4182C54.0568 28.3204 54.0468 28.2191 54.0086 28.127C53.9705 28.0349 53.9059 27.9562 53.823 27.9009C53.7402 27.8455 53.6427 27.8159 53.5431 27.8159C53.4769 27.8159 53.4113 27.829 53.3502 27.8543C53.289 27.8796 53.2335 27.9167 53.1867 27.9635C53.1399 28.0103 53.1028 28.0659 53.0774 28.127C53.0521 28.1882 53.0391 28.2537 53.0391 28.3199Z"
          fill="#4A4C64"
        />
        <path
          d="M61.2656 72.5715C60.0294 71.3273 58.8443 70.1408 57.6681 68.9457C57.4078 68.6812 57.4012 68.3316 57.6074 68.02C57.7035 67.8796 57.8448 67.7763 58.0078 67.7272C58.1707 67.6781 58.3456 67.6863 58.5033 67.7503C58.6609 67.8233 58.8037 67.9248 58.9244 68.0496C59.4238 68.5482 59.9121 69.0578 60.4045 69.5633L60.4827 69.5212V64.7402H62.0056V69.5128C62.0385 69.5349 62.0715 69.557 62.1044 69.579C62.4754 69.1878 62.8442 68.7945 63.2184 68.4064C63.378 68.2367 63.5461 68.0751 63.722 67.9222C64.09 67.6103 64.5059 67.6278 64.8072 67.9544C65.1009 68.2726 65.1004 68.6823 64.7738 69.0159C63.6243 70.1901 62.4663 71.356 61.2656 72.5715Z"
          fill="white"
        />
        <path
          d="M34.9911 69.6495V64.7397H36.4867V69.6326C36.8621 69.2576 37.1611 68.959 37.46 68.6603C37.7076 68.4129 37.9479 68.1578 38.2043 67.9199C38.3468 67.7793 38.5391 67.7006 38.7393 67.7008C38.9395 67.701 39.1316 67.7801 39.2739 67.921C39.4151 68.0623 39.4961 68.2528 39.4999 68.4526C39.5036 68.6523 39.4299 68.8458 39.294 68.9923C38.1378 70.1762 36.9709 71.3499 35.7903 72.5452C35.5851 72.3599 35.4172 72.2207 35.2633 72.0674C34.2465 71.0548 33.2319 70.0402 32.2195 69.0234C32.0873 68.8999 32.0011 68.7352 31.9748 68.5563C31.9485 68.3774 31.9838 68.1949 32.0748 68.0386C32.1712 67.8983 32.3107 67.7932 32.4722 67.7394C32.6337 67.6856 32.8084 67.686 32.9696 67.7406C33.0979 67.7924 33.2138 67.8708 33.3097 67.9705C33.8503 68.5019 34.3834 69.0409 34.9911 69.6495Z"
          fill="white"
        />
        <path
          d="M47.7286 69.6221V64.7476H49.2427V69.6321C49.6569 69.2188 49.9977 68.8786 50.3386 68.5383C50.5366 68.3406 50.7291 68.1369 50.9339 67.9464C51.2846 67.6203 51.7467 67.6234 52.0516 67.9441C52.3518 68.2599 52.3404 68.6896 52.0019 69.0313C50.8533 70.1908 49.699 71.3447 48.5191 72.5287C48.4068 72.4262 48.3035 72.339 48.2081 72.2439C47.1517 71.1896 46.096 70.1345 45.0411 69.0786C44.6553 68.6923 44.6127 68.2323 44.9287 67.9153C45.2462 67.5968 45.7307 67.6253 46.1086 67.9982C46.636 68.5186 47.1556 69.047 47.7286 69.6221Z"
          fill="white"
        />
        <path d="M29.7493 62.4863V63.9264H67.2509V62.4863H29.7493Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2674_112">
          <rect width="96" height="96" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Scales;
