import React from 'react';

const CloseIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.19406 18.2857L0.621094 16.7128L8.19099 9.14286L0.621094 1.57296L2.19406 -1.15685e-08L9.76395 7.56989L17.3338 -1.22916e-07L18.9068 1.57296L11.3369 9.14286L18.9068 16.7128L17.3338 18.2857L9.76395 10.7158L2.19406 18.2857Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseIcon;
