import { Typography as AntTypography } from 'antd';
import clsx from 'clsx';

import { ITypographyProps } from './constants/types';

import styles from './Typography.module.scss';

const { Title } = AntTypography;

const Typography = ({
  level = 2,
  children,
  variant,
  type = 'secondary',
  required = false,
  className,
  ...props
}: ITypographyProps) => {
  return variant === 'heading' ? (
    <Title level={level} type={type} {...props} className={clsx(className, { [styles.requiredField]: required })}>
      {children}
    </Title>
  ) : (
    <p className={clsx(className, { [styles.requiredField]: required })} {...props}>
      {children}
    </p>
  );
};

export default Typography;
