import React from 'react';

const BoxIcon = () => {
  return (
    <svg width="289" height="229" viewBox="0 0 289 229" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_64)">
        <path d="M179.702 228.162L0.0766602 171.86V38.3282L179.702 76.4768V228.162Z" fill="#D3B875" />
        <path d="M288.077 166.7L179.702 228.162V76.4768L288.077 24.4474V166.7Z" fill="#B78C58" />
        <path
          d="M0.0766602 38.3282L109.711 0.161621L288.077 24.4474L179.701 76.4768L0.0766602 38.3282Z"
          fill="#F2D08B"
        />
        <path
          opacity="0.6"
          d="M52.213 20.1792L221.498 57.1616L224.978 117.628L230.94 126.305L233.891 115.152L237.895 118.623L241.871 110.69L244.849 115.152L241.013 47.0403L67.1381 14.9834L52.213 20.1792Z"
          fill="#846B3D"
        />
        <path
          opacity="0.6"
          d="M73.8718 54.001L179.701 9.69314L197.709 12.1424L96.1714 58.7375L73.8718 54.001Z"
          fill="#846B3D"
        />
        <path d="M73.8718 54.001V194.993L96.1714 201.98V58.7375L73.8718 54.001Z" fill="#846B3D" />
        <path
          d="M219.512 189.747C219.512 191.391 218.285 191.954 216.529 192.719L198.147 201.98C195.665 203.214 195.168 200.63 195.168 199.004V170.752C195.168 169.113 196.242 168.721 198.147 167.78L216.529 158.519C220.424 156.538 219.237 160.568 219.512 168.186V189.747Z"
          fill="white"
        />
        <path d="M197.05 173.534L215.297 164.692V165.709L197.05 174.858V173.534Z" fill="#4C402C" />
        <path d="M197.05 177.019L215.297 168.172V169.19L197.05 178.343V177.019Z" fill="#4C402C" />
        <path d="M197.05 180.927L215.297 172.085V173.098L197.05 182.251V180.927Z" fill="#4C402C" />
        <path d="M197.483 191.652L209.015 186.06L209.159 187.118L197.483 192.971V191.652Z" fill="#4C402C" />
        <path d="M197.483 194.295L209.015 188.703L209.159 189.761L197.483 195.614V194.295Z" fill="#4C402C" />
        <path d="M197.483 196.992L209.015 191.4L209.159 192.458L197.483 198.311V196.992Z" fill="#4C402C" />
      </g>
      <defs>
        <clipPath id="clip0_1_64">
          <rect width="288" height="228" fill="white" transform="translate(0.0766602 0.161621)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxIcon;
