import { ForwardedRef, forwardRef, useRef } from 'react';
import { RefSelectProps, Select } from 'antd';
import clsx from 'clsx';
import CloseIconForSelect from 'svgs/CloseIconForSelect';
import Typography from 'ui/typography/Typography';

import { ISelectProps } from './constants/types';

import styles from './simpleFlatSelect.module.scss';

const SimpleFlatSelect = forwardRef(
  (
    {
      options,
      placeholder,
      label,
      allowClear,
      name,
      suffixIcon,
      isDisabled,
      onChange,
      value,
      required,
      errors,
      setValue,
      resetFunc,
      defaultValue,
      onClick,
      ...props
    }: ISelectProps,
    ref: ForwardedRef<RefSelectProps>
  ) => {
    const selectRef = useRef<RefSelectProps>(null);

    const handleReset = () => {
      if (setValue) {
        setValue(name, '');
      }
      if (onChange) {
        onChange('');
      }
      if (resetFunc) {
        resetFunc();
      }
    };

    const handleChange = (value: string) => {
      if (onChange) {
        onChange(value);
      }
      selectRef?.current?.blur();
    };

    const dropdownRef = useRef<HTMLInputElement>(null);

    return (
      <div className={styles.selectMainWrapper} onClick={onClick}>
        {!!label && <label>{label}</label>}
        <div
          id={`simpleFlatSelect_${name}`}
          className={clsx(styles.selectWrapper, {
            [styles.selected]: value && String(value).length,
            [styles.required]: required,
            [styles.error]: errors && errors[name || ''],
          })}
          ref={dropdownRef}
        >
          <Select
            {...props}
            ref={selectRef || ref}
            placeholder={label ? placeholder : null}
            {...(allowClear ? { onClear: handleReset, clearIcon: <CloseIconForSelect />, allowClear } : {})}
            suffixIcon={!(allowClear && value) ? suffixIcon : <CloseIconForSelect />}
            value={value}
            onChange={value => handleChange(value)}
            disabled={isDisabled || false}
            className={clsx(styles.select)}
            options={options}
            defaultValue={defaultValue}
            getPopupContainer={() => document.getElementById(`simpleFlatSelect_${name}`)!}
          />
          {!label && (
            <Typography variant="paragraph" className={`placeholder-select ${styles.placeholder}`}>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default SimpleFlatSelect;
