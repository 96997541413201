const MobileVersionData = [
  {
    title: 'I’m a Carrier',
    id: '1',
  },
  {
    title: 'I’m a Broker',
    id: '2',
  },
  {
    title: 'I’m a Dispatcher',
    id: '3',
  },
  {
    title: 'I’m a Shipper',
    id: '4',
  },
  {
    title: 'I’m a Factoring company',
    id: '5',
  },
];

export default MobileVersionData;
