import * as yup from 'yup';

import { emailPattern, phoneRegExp } from '../../../helpers';

export const onlyCharacters = /^[A-Za-z\s"'`]+$/;
export const schema = yup.object({
  companyName: yup.string().required('Company name is a required field'),
  contactName: yup.string().required('Contact name is a required field'),
  phoneNumber: yup
    .string()
    .required('Phone number is a required field')
    .max(12, 'Maximum of 10 digits allowed')
    .matches(phoneRegExp, 'Incorrect phone format'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required field')
    .matches(emailPattern, 'Invalid email format'),
  address: yup.string(),
  zipCode: yup.string().test('isZipLength', function (value: any) {
    if (value) {
      if (value.length === 5) {
        return true;
      } else {
        return this.createError({
          message: 'Must be 5 digits',
          path: 'zipCode',
        });
      }
    }

    return true;
  }),
  city: yup.string(),
  state: yup.mixed(),
  comments: yup.string(),
  // optional
  pickUpCity: yup.string(),
  pickUpState: yup.string(),
  deliveryCity: yup.string(),
  deliveryState: yup.string(),
  commodity: yup.string(),
  loadType: yup.string(),
  weight: yup.string(),
  temperature: yup.string(),
  palletCount: yup.string(),
  truckType: yup.array(),
});
