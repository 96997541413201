import CloseIcon from '../../svgs/CloseIcon';

import styles from './modal.module.scss';

type ModalType = {
  onClose: () => void;
  size?: string;
  modeSwitcher?: any;
};

const VendorModal = ({ onClose, size, modeSwitcher }: ModalType) => {
  return (
    <>
      <div
        style={{
          display: 'block',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.85)',
          overflow: 'hidden',
        }}
      ></div>
      <div
        className={`${styles.container} ${size === 'small' ? styles.small : ''} ${
          modeSwitcher ? styles.lightMode : ''
        }`}
      >
        <div className={`${styles['modalHeaderWrapper']}`}>
          <div className={`${styles['name']}`}>coming soon</div>
          <div onClick={onClose} className={`${styles['closeIcon']}`}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.childrenWrapper}>
          <p className={styles.childrenTitle}>
            Questions or <br /> concerns?
          </p>
          <p className={styles.callUs}>
            Call us at <a href="tel:806-806-8686">806-806-8686</a>
          </p>
          <p className={styles.orEmail}>
            or email <a href="mailto:info@freigthmax.com ">info@freigthmax.com </a>
          </p>
        </div>
        <div className={styles.buttons}>
          <a href="tel:806-806-8686" className={styles.call}>
            Call
          </a>
          <a href="mailto:info@freigthmax.com " className={styles.sendEmail}>
            Send email
          </a>
        </div>
      </div>
    </>
  );
};

export default VendorModal;
