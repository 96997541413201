import React from 'react';

const Logo = () => {
  return (
    <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1866 0.425537H0.28125C0.28125 4.9274 3.93073 8.57688 8.43259 8.57688H27.1866V0.425537ZM27.1865 11.8381H16.9329C7.92723 11.8381 0.626669 19.1387 0.626669 28.1444V35.8474H8.78832V28.1529C8.78832 23.6501 12.4386 19.9998 16.9415 19.9998H27.1865V11.8381Z"
        fill="#379FFF"
      />
      <path
        d="M30.9268 18.8244V0.632266H37.17V3.04066H33.6969V7.87811H36.9219V10.2968H33.6969V18.8244H30.9268Z"
        fill="white"
      />
      <path
        d="M39.1132 18.8244V0.632266H43.6923C44.6432 0.632266 45.353 0.818322 45.8216 1.19043C46.2902 1.56255 46.5968 2.10693 46.7415 2.82359C46.8931 3.54025 46.9689 4.4154 46.9689 5.44905C46.9689 6.51715 46.8587 7.35785 46.6382 7.97114C46.4245 8.57755 45.9835 8.98411 45.3151 9.19084C45.6321 9.24597 45.8974 9.3769 46.111 9.58363C46.3246 9.78347 46.4969 10.0798 46.6278 10.4726C46.7587 10.8585 46.8518 11.3581 46.9069 11.9713C46.9689 12.5778 46.9999 13.322 46.9999 14.204C46.9999 14.2729 46.9999 14.4659 46.9999 14.7829C46.9999 15.0998 46.9999 15.4754 46.9999 15.9095C47.0068 16.3368 47.0137 16.764 47.0206 17.1912C47.0275 17.6185 47.0378 17.9837 47.0516 18.2869C47.0654 18.5901 47.0826 18.7693 47.1033 18.8244H44.3331C44.3125 18.79 44.2952 18.6142 44.2814 18.2973C44.2746 17.9734 44.2642 17.5634 44.2504 17.0672C44.2435 16.5711 44.2367 16.037 44.2298 15.4651C44.2298 14.8931 44.2298 14.3315 44.2298 13.7802C44.2298 13.2221 44.2298 12.7294 44.2298 12.3021C44.2298 11.7577 44.1884 11.3477 44.1057 11.0721C44.0299 10.7895 43.8852 10.5932 43.6716 10.4829C43.4649 10.3726 43.1651 10.3072 42.7723 10.2865L41.8834 10.2348V18.8244H39.1132ZM41.8937 8.08484H42.9997C43.3374 8.08484 43.5923 8.00904 43.7646 7.85744C43.9438 7.70584 44.0644 7.42676 44.1264 7.02019C44.1953 6.61362 44.2298 6.02789 44.2298 5.26299V5.14929C44.2298 4.50843 44.1988 4.01573 44.1367 3.67118C44.0747 3.32663 43.9438 3.08889 43.744 2.95797C43.551 2.82704 43.2478 2.76157 42.8343 2.76157H41.8937V8.08484Z"
        fill="white"
      />
      <path
        d="M49.367 18.8244V0.632266H55.4655V3.01998H52.1371V8.31224H55.3001V10.6483H52.1371V16.4367H55.5895V18.8244H49.367Z"
        fill="white"
      />
      <path
        d="M66.6805 19.0105C65.5849 19.0105 64.7028 18.5798 64.0344 17.7184C63.366 16.8501 63.0318 15.4444 63.0318 13.5011V5.59376C63.0318 4.78063 63.1076 4.05363 63.2592 3.41277C63.4177 2.77191 63.6588 2.23097 63.9827 1.78995C64.3135 1.34204 64.7407 1.00438 65.2644 0.776976C65.7881 0.542683 66.4187 0.425537 67.156 0.425537C67.9554 0.425537 68.6065 0.546129 69.1096 0.787313C69.6126 1.02161 69.9985 1.36615 70.2673 1.82096C70.5429 2.26887 70.7324 2.82015 70.8358 3.47479C70.9391 4.12943 70.9908 4.87021 70.9908 5.69712V6.98918H68.4067V5.08727C68.4067 4.26725 68.3206 3.68496 68.1483 3.34041C67.9829 2.99587 67.6659 2.82359 67.1973 2.82359C66.6805 2.82359 66.3222 3.02688 66.1224 3.43344C65.9225 3.83312 65.8226 4.54633 65.8226 5.57309V13.4598C65.8226 14.6037 65.9053 15.4237 66.0707 15.9199C66.243 16.4091 66.5978 16.6538 67.1353 16.6538C67.659 16.6538 68.0277 16.4022 68.2413 15.8992C68.4618 15.3893 68.5721 14.5417 68.5721 13.3564V10.9067H67.1767V8.89109H70.9908V18.8244H69.7504L69.3267 17.0362C69.1751 17.6633 68.8512 18.1491 68.355 18.4936C67.8658 18.8382 67.3076 19.0105 66.6805 19.0105Z"
        fill="white"
      />
      <path
        d="M73.5956 18.8244V0.632266H76.3658V7.99181H78.8465V0.632266H81.6167V18.8244H78.8465V10.3692H76.3658V18.8244H73.5956Z"
        fill="white"
      />
      <path d="M85.2965 18.8244V3.03032H83.1672V0.632266H90.1029V3.03032H88.0563V18.8244H85.2965Z" fill="white" />
      <path
        d="M64.0801 35.732V22.0879H66.6616L68.5997 32.0574L70.4215 22.0879H73.0185V35.732H71.1657V30.6465L71.2432 25.9796L69.4059 35.67H67.7547L65.8399 25.9796L65.9174 30.6465V35.732H64.0801Z"
        fill="white"
      />
      <path
        d="M75.378 35.732L77.3239 22.0879H80.0682L82.1381 35.732H80.2L79.8744 33.2978H77.6727L77.3781 35.732H75.378ZM77.8743 31.631H79.6573L78.7425 24.778H78.7115L77.8743 31.631Z"
        fill="white"
      />
      <path
        d="M83.7843 35.732L85.8852 28.4138L83.7921 22.0879H85.5441L86.9008 26.1424L87.9939 22.0879H89.9397L87.8776 29.0727L90.1025 35.732H88.304L86.9163 31.538L85.7922 35.732H83.7843Z"
        fill="white"
      />
      <path
        d="M58.0293 3.68679C58.0293 1.88565 59.4894 0.425537 61.2905 0.425537V35.732H58.0293V3.68679Z"
        fill="#379FFF"
      />
    </svg>
  );
};

export default Logo;
