import React from 'react';

type SuccessIconType = {
  width: string;
  height: string;
};

const SuccessIcon = ({ width, height }: SuccessIconType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.44018 0.984848C7.6814 0.75957 7.6814 0.394277 7.44018 0.168976C7.19897 -0.0563252 6.80796 -0.0563252 6.56675 0.168976L2.8858 3.60717L1.67548 2.4767C1.43428 2.25139 1.0432 2.25139 0.801997 2.4767C0.560793 2.70201 0.560793 3.06724 0.801997 3.29255L2.44906 4.83102C2.6903 5.05633 3.08131 5.05633 3.32252 4.83102L7.44018 0.984848Z"
        fill="#34A853"
      />
    </svg>
  );
};

export default SuccessIcon;
