import clsx from 'clsx';

// import { ROUTES } from 'routes/routes';
import BoxIcon from '../../svgs/BoxIcon';
import DropAreaIcon from '../../svgs/DropAreaIcon';
import Map from '../../svgs/Map';
import NotFoundIcon from '../../svgs/NotFoundIcon';
import NotFoundLine from '../../svgs/NotFoundLine';

import { SUBJECT } from './constants/constants';
import { useNotFound } from './useNotFound';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const { xBox, yBox, xArea, yArea, dragState, boxElement, areaElement, lineElement, handleDragEnd, handleDragStart } =
    useNotFound();

  return (
    <div className={styles.notFoundWrapper}>
      <div
        className={clsx(styles.linWrapper, {
          [styles.hideLine]: dragState,
        })}
        ref={lineElement}
      >
        <NotFoundLine />
      </div>
      <div className={styles.mapImage}>
        <Map />
      </div>
      <div
        className={styles.boxIcom}
        ref={boxElement}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        style={{
          position: 'absolute',
          left: xBox,
          top: yBox,
        }}
      >
        <p className={styles.loseText}>{SUBJECT.I_LOST_MY_ADDRESS}</p>
        <BoxIcon />
      </div>
      <div
        className={styles.dropArea}
        ref={areaElement}
        style={{
          position: 'absolute',
          left: xArea,
          top: yArea,
        }}
      >
        <DropAreaIcon />
      </div>
      <div className={styles.info}>
        <NotFoundIcon />
        <button className={styles.backBtn} onClick={() => window.location.replace(window.global.main)}>
          {SUBJECT.GO_HOME}
        </button>
      </div>
    </div>
  );
};

export default NotFound;
