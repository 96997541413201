import { useEffect, useState } from 'react';
import axios from 'axios';
import { transformStatesData } from 'helpers';

import Broker from './Broker/Broker';
import Dispatcher from './Dispatcher/Dispatcher';
import FactoringCompany from './FactoringCompany/FactoringCompany';
import Shipper from './Shipper/Shipper';

type SignUpContentType = {
  onClose: () => void;
  name: string;
};

const SignUpContentMobile = ({ name, onClose }: SignUpContentType) => {
  const [statesData, setStatesData] = useState<any>([]);

  useEffect(() => {
    axios
      .get('https://api-test.freightmax.com/api/auth/states')
      .then(data => setStatesData(transformStatesData(data.data.result)));
  }, []);

  if (name === 'Broker') {
    return <Broker onClose={onClose} statesData={statesData} />;
  }
  if (name === 'Dispatcher') {
    return <Dispatcher onClose={onClose} statesData={statesData} />;
  }
  if (name === 'Shipper') {
    return <Shipper onClose={onClose} statesData={statesData} />;
  }
  if (name === 'Factoring company') {
    return <FactoringCompany onClose={onClose} statesData={statesData} />;
  }
  return null;
};

export default SignUpContentMobile;
