import React from 'react';
import CloseIcon from 'svgs/CloseIcon';

import styles from './modalMobile.module.scss';

type ModalType = {
  onClose: () => void;
  children: JSX.Element;
  header: string;
};

const ModalMobile = ({ onClose, header, children }: ModalType) => {
  return (
    <>
      <div
        style={{
          display: 'block',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.85)',
          overflow: 'hidden',
        }}
      ></div>
      <div className={styles.largeContainer}>
        <div className={`${styles['modalHeaderWrapper']}`}>
          <div className={`${styles['name']}`}>{header}</div>
          <div onClick={onClose} className={`${styles['closeIcon']}`}>
            <CloseIcon />
          </div>
        </div>
        <div className={styles.childrenWrapper}>{children}</div>
      </div>
    </>
  );
};

export default ModalMobile;
